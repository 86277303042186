import React, { useState } from 'react';
import _merge from 'lodash/merge';
import { getAdvancedThemeContext } from '@nokia-csf-uxr/ccfk/AdvancedTheme';
import classes from '../Style/Merchant.module.css';

import SparkLineChart, {
  SparkLineValue,
  SparkLineTitle,
  SparkLineIcon,
  SparkLineTable,
  SparkLineTableRow,
  SparkLineTableData,
} from '@nokia-csf-uxr/ccht/SparkLine';

import App from "@nokia-csf-uxr/ccht/App";
import "@nokia-csf-uxr/nokia-design-system-tokens/css/_variables.css";
import AdvancedTheme from "@nokia-csf-uxr/ccht/AdvancedTheme";
import { propertyOf } from 'lodash';
import PropTypes from "prop-types";

import { formatNumberWithCommas,formatDate } from '../../marketplace/Utils/commons';


// const DATA = {
//   data: [
//     {
//       year: 2000,
//       value: 305
//     },
//     {
//       year: 2001,
//       value: 200
//     },
//     {
//       year: 2002,
//       value: 800
//     },
//     {
//       year: 2003,
//       value: 940
//     },
//     {
//       year: 2004,
//       value: 360
//     },
//     {
//       year: 2005,
//       value: 600
//     },
//     {
//       year: 2006,
//       value: 105
//     },
//     {
//       year: 2007,
//       value: 1000
//     },
//     {
//       year: 2008,
//       value: 240
//     },
//     {
//       year: 2009,
//       value: 300
//     },
//     {
//       year: 2010,
//       value: 130
//     },
//     {
//       year: 2011,
//       value: 200
//     },
//     {
//       year: 2012,
//       value: 340
//     }, {
//       year: 2013,
//       value: 1005
//     },
//     {
//       year: 2014,
//       value: 1200
//     },
//     {
//       year: 2015,
//       value: 1230
//     },
//     {
//       year: 2016,
//       value: 240
//     },
//     {
//       year: 2017,
//       value: 360
//     },

//   ]
// };


const SparkLine = (props) => {
  const { advancedTheme } = getAdvancedThemeContext((arg) => arg);
  const isFFTheme = advancedTheme.NAME.includes("FreeForm");
  const DATA2 = props.data 

const DATA = { data : DATA2}

const CustomTooltip = ({ active, payload }: any) => {
  if (active && payload && payload.length) {
      return (
          <div style={{ backgroundColor: '#fff', padding: '10px', border: '1px solid #ccc' }}>
              <p><strong>Date:</strong> {formatDate(new Date(payload[0].payload.year), 'dd-MMM-yyyy')}</p>
              <p><strong>{props.graphType != "salesAmount" ? "Count" : "Sales" }:</strong> {props.graphType != "salesAmount" ? payload[0].value : "EUR " + formatNumberWithCommas(payload[0].value)}</p>
          </div>
      );
  }
  return null;
};
  return (
    <>
      <AdvancedTheme advancedTheme="CCHT Legacy - Light">
        <div style={{ height: '0' }}>
          <App>
            <SparkLineTable >
              <SparkLineTableRow>

                <SparkLineTableData isXAxisNested style={{ width: 400 }} >
                  <SparkLineChart tooltipLabelKey='year' lineChartProps={{ data: DATA.data }} lineProps={{ dataKey: 'value', stroke: props.stroke }}  tooltipProps={{
                                content: <CustomTooltip />
                            }}/>
                </SparkLineTableData>

              </SparkLineTableRow>

            </SparkLineTable>

          </App>
        </div>
      </AdvancedTheme>
    </>
  );
};

export default SparkLine;

SparkLine.propTypes = {
  stroke: PropTypes.string,
  data: PropTypes.array,
  graphType: PropTypes.string
};
