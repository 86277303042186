import React, { useEffect, useState } from "react";
import StepForm, { HorizontalStep, StepConfigurationArea } from "@nokia-csf-uxr/ccfk/StepForm";
import HorizontalDivider from "@nokia-csf-uxr/ccfk/HorizontalDivider";
import Button from '@nokia-csf-uxr/ccfk/Button';
import ButtonsRow from '@nokia-csf-uxr/ccfk/ButtonsRow';
import { useDispatch, useSelector } from "react-redux";
import { setProp } from "Store/Actions";
import OOT from "Constants/services/oot";
import _ from "lodash";
import { setDataConfig } from "Store/contentState/configuration_stepperSlice";
import { RootState, store } from "Store/mainStore";
import SelectFields from "./Configurator/DynamicField/selectfield";
import RadioButtonField from "./Configurator/DynamicField/radiobuttonfield";
import CheckboxField from "./Configurator/DynamicField/checkboxfield";
import TextField from "./Configurator/DynamicField/textfield";
import SpinnerField from "./Configurator/DynamicField/spinnerfield";
import RangeField from "./Configurator/DynamicField/rangefield";
import { setElement } from "Store/contentState/configurationArea/dynamicSlice";
import { getCheckField, getStateOfElement } from "Components/Logics/stateMachine/modules/CONFIG/configurator_statelogics";
import MultiSelect from "./Configurator/DynamicField/multiselect";
import { setError } from "Store/auth";
import { useStatesOfElement } from "./Configurator/hooks/useStatesOfElement";
import Loader from "Components/Elements/Loader";
import { useConfigurator } from "./Configurator/hooks/useConfigurator";
import CompositeStepper from "./CompositeStepper";
import DynamicStyle from "./Configurator/DynamicField/Style/Dynamic.module.css"
import TextAreaField from "./Configurator/DynamicField/textareafield";




const SimpleStepper = () => {

    const dispatch = useDispatch();
    const configstepperSelector = useSelector((state: RootState) => state.config_stepper)
    const radioNetworkCIQ = useSelector((stete: RootState) => stete.radioNetworkCIQ)
    const dynamicSelector = useSelector((state: RootState) => state.dynamic);
    const packageData = configstepperSelector.data.solution_form_data['packages'] ? configstepperSelector.data.solution_form_data['packages'][configstepperSelector.data.active_step].field_details : {};
    // const activePackages = JSON.parse(JSON.stringify(packageData));


    const [optionsData, setOptionsData] = useState({});
    const [isDisabled, setisDisabled] = useState(false)
    const [stepData, setStepData] = useState([]);
    const [nextButton, setNextButton] = useState(false)

    const allRefs = React.useRef([]);
    const { getElementsStates } = useStatesOfElement(allRefs);

    const { getListOfFields } = useConfigurator();

    useEffect(() => {
        if(radioNetworkCIQ.scrmValidation){
            getSegmentList()
        }
    }, [])





    const getSegmentList = async () => {
        try {
            dispatch(setProp({ slice: 'radioNetworkCIQ', key: 'scrmLoading', value: true })) 
            const response = await OOT.getSegmentForm("pre_cio_radio");
            const configSteps = prepSteps(response.data);
            const dataConfigRes = [
                { key: "solution_form_data", value: response.data },
                { key: "stepper_data", value: configSteps }
            ];
            setStepData(configSteps)
            dispatch(setDataConfig(dataConfigRes));
            dispatch(setElement(addEelement()))
            addCallBack(true);
            addEelementComposite()

            dispatch(setProp({ slice: 'radioNetworkCIQ', key: 'solutionFormLoading', value: false }))
            dispatch(setProp({ slice: 'radioNetworkCIQ', key: 'scrmLoading', value: false })) 
        } catch (error) {
            console.error('Error fetching data:', error);

        }
    };


    const addEelement = () => {
        let element = {}
        let storeElement = {}
        const configstepperSelectorStep = store.getState();
        let packagesList = configstepperSelectorStep.config_stepper.data.solution_form_data ? configstepperSelectorStep.config_stepper.data.solution_form_data['packages'] : {}
        if (packagesList) {
            Object.keys(packagesList).forEach(function (key) {
                let selectElement = {}
                let storeselectElement = {}
                if (packagesList[key].field_details) {
                    Object.entries(packagesList[key].field_details).map((data, _index) => {
                        selectElement[data[0]] = ""
                        if (data[1]['type'] === 'range' || data[1]['type'] === 'number') {
                            selectElement[data[0]] = { key: data[1]['min'], value: data[1]['min'] }
                            if (data[1]['default'] != "") {
                                selectElement[data[0]] = { key: data[1]['default'], value: data[1]['default'] }
                            }
                        }
                        if (data[1]['type'] === 'checkbox') {
                            selectElement[data[0]] = []
                        }
                        if (data[1]['type'] === 'select' || data[1]['type'] === 'radio' || data[1]['type'] === 'checkbox') {
                            storeselectElement[data[0]] = getSelectOptions(data[1]['options'])
                            if (data[1]['default'] != "") {
                                if (!(_.has(data[1], 'multiple'))) {
                                    selectElement[data[0]] = { key: data[1]['default'], value: data[1]['default'] }
                                } else {
                                    selectElement[data[0]] = getKeyvalueObj(data[1]['default'])
                                }
                            }
                        }
                        allRefs.current[data[1]['id']] = false;
                    });
                }

                element[key] = selectElement;
                storeElement[key] = storeselectElement;

            });
        }
        setOptionsData(storeElement);
        return element;
    }


    const addEelementComposite = () => {
        let compositeFieldDetails = {}
        const configstepperSelectorStepData = store.getState();
        let packagesListData = configstepperSelectorStepData.config_stepper.data.solution_form_data ? configstepperSelectorStepData.config_stepper.data.solution_form_data['packages'] : {}
        Object.keys(packagesListData).forEach(function (key) {
            if (packagesListData[key].field_details) {
                Object.entries(packagesListData[key].field_details).map((data, _index) => {
                    if (data[1]['type'] === "composite") {
                        compositeFieldDetails[key + "+" + data[0]] = data[1]['field_details']
                    }
                })
            }
        })
        let elementData = {}
        let storeElementData = {}
        Object.entries(compositeFieldDetails).map((data, _index) => {
            let element = {}
            let storeElement = {}
            Object.entries(data[1]).map((data1, _index) => {
                let selectElement = {}
                let storeselectElement = {}
                Object.entries(data1[1]).map((data2, _index) => {
                    selectElement[data2[0]] = ""
                    if (data2[1]['type'] === 'range' || data2[1]['type'] === 'number') {
                        selectElement[data2[0]] = { key: data2[1]['min'], value: data2[1]['min'] }
                        if (data2[1]['default'] != "") {
                            selectElement[data2[0]] = { key: data2[1]['default'], value: data2[1]['default'] }
                        }
                    }
                    if (data2[1]['type'] === 'checkbox') {
                        selectElement[data[0]] = []
                    }
                    if (data2[1]['type'] === 'select' || data2[1]['type'] === 'radio' || data2[1]['type'] === 'checkbox') {
                        storeselectElement[data2[0]] = getSelectOptions(data2[1]['options'])
                        if (data2[1]['default'] != "") {
                            if (!(_.has(data2[1], 'multiple'))) {
                                selectElement[data2[0]] = { key: data2[1]['default'], value: data2[1]['default'] }
                            } else {
                                selectElement[data2[0]] = getKeyvalueObj(data2[1]['default'])
                            }
                        }
                    }
                })
                element[data1[0]] = selectElement;
                storeElement[data1[0]] = storeselectElement;
            })
            elementData[data[0]] = element
            storeElementData[data[0]] = storeElement
        })
        Object.entries(elementData).map((data, _index) => {
            let element = {}
            let dataSplit = data[0].split("+")
            let activeStep = dataSplit[0]
            let compositeField = dataSplit[1]
            element = data[1]

            dispatch(setProp({ slice: 'dynamic', key: `element.${activeStep}.${[compositeField]}`, value: element }))
        })
    }


    const addCallBack = (status, value: string | any = '', item: string = '') => {
        const configstepperSelectorAddCallBack = store.getState();
        let packagesList = configstepperSelectorAddCallBack.config_stepper.data.solution_form_data ? configstepperSelectorAddCallBack.config_stepper.data.solution_form_data['packages'] : {}
        let datacall: any = {}
        Object.keys(packagesList).forEach(function (key) {
            let packageListItem = packagesList[key] ? packagesList[key].field_details : {}
            if (packageListItem) {

                Object.entries(packageListItem).map((data, _index) => {
                    if (status) {
                        if (data[1]['data_callback']) {
                            datacall[data[0]] = data[1]['data_callback'].args
                        }
                    }
                    else {
                        checkDataCallback(data, value, item, true)
                    }
                })
            }
        })

        if (status) {
            dispatch(setProp({ slice: "dynamic", key: `callBack`, value: datacall }));
        }
    }

    const checkDataCallback = (data, value, item, direct_call) => {
        // if there is data callback json then do api call and get the data
        if (data[1]['data_callback']) {
            const allfields = getListOfFields(dynamicSelector.data.element);
            //check if all the items are set for given args
            let checkIfValueExists = false
            if (data[1]['data_callback']['args']['country'] === 'country' || data[1]['data_callback']['args']['sid'] === 'sid' || data[0] === 'mxie_server') {
                checkIfValueExists = true
            }
            else {
                checkIfValueExists = _.every(data[1]['data_callback']['args'], (item) => getRef(item) === true);
            }
            if (checkIfValueExists) {
                Object.keys(data[1]['data_callback']['args']).forEach(function (imt) {
                    if (data[1]['data_callback']['args'][imt] === item) {
                        allfields[item] = value
                    }
                })
                getDataCallback(data[1], allfields, direct_call, value);
            }
        }
    }


    const getKeysForArgs = (allfieldData, data, pra) => {
        let keys = JSON.stringify(_.map(allfieldData[data['data_callback']['args'][pra]], 'key'));
        if (configstepperSelector.data.solution_selected === 'ndac_reference_solution') {
            /*  this is for all multiple values got from multiselect / checkbox
                to send in api url as params as below logic from configurator requirement
                Following formats can be sent based on requirement
                1. ?value[0]=a&value[1]=b" => for OR / AND based on configuration
                2. band=B48&service_id=4G,5G&deployment_type=In,Out => for OR
                3. band=B48&service_id=4G+5G&deployment_type=In+Out => for AND
            */
            let condition = 'OR';
            const allKeys = _.map(allfieldData[data['data_callback']['args'][pra]], 'key');
            keys = condition === 'OR' ? _.join(allKeys) : _.join(allKeys, '+');
        }
        return keys;
    }


    const getDataCallback = async (data, allfieldData, direct_call, selectedvalue_parent) => {
        try {
            const method = data['data_callback']['method'];
            const endpoint = data['data_callback']['endpoint'];
            let params = '';
            const urlargs = _.values(data['data_callback']['args'])
            let checkValuesList = [];
            if (Object.entries(data['data_callback']['args']).length !== 0) {
                Object.entries(data['data_callback']['args']).forEach(function (item: any) {
                    if (allfieldData[data['data_callback']['args'][item[1]]] !== "") {
                        checkValuesList.push(true)
                    }
                    else {
                        checkValuesList.push(false)
                    }
                })
            }

            if (!checkValuesList.includes(false)) {
                let i = 0

                Object.keys(data['data_callback']['args']).forEach(function (pra) {
                    let keys = allfieldData[data['data_callback']['args'][pra]].key;
                    if (_.isArray(allfieldData[data['data_callback']['args'][pra]])) {
                        // keys = JSON.stringify(_.map(allfieldData[data['data_callback']['args'][pra]],'key'));
                        keys = getKeysForArgs(allfieldData, data, pra);
                    }
                    if (allfieldData[data['data_callback']['args'][pra]] !== "") {
                        if (i === Object.keys(data['data_callback']['args']).length - 1) {
                            params = params + pra + '=' + keys;
                        }
                        else {
                            params = params + pra + '=' + keys + "&";
                        }
                    }
                    i = i + 1
                })


            }

            if (params !== '') {
                const resp = await OOT.configuratorDataCallback(method, endpoint, params);
                if (resp.status && resp.data) {
                    const ele_id = data.id;
                    let checkValueSetStatus = false
                    console.log('likklo', direct_call)
                    if (direct_call) {
                        const value_to_set = resp.data;
                        let valueSet = [{ key: value_to_set, value: value_to_set }]
                        if (typeof (resp.data) === 'object') {
                            let respData = []
                            Object.keys(resp.data).forEach(function (keyitem) {
                                let respDataObj = { key: keyitem, value: resp.data[keyitem] }
                                respData.push(respDataObj)
                            })
                            const keydata = _.keys(resp.data)[0];
                            const valuedata = _.values(resp.data)[0];
                            valueSet = respData[0]
                            let displayData = {};
                            displayData[keydata] = valuedata
                            let storeElementList = {}
                            const configstepperSelectorStepCallBack = store.getState();
                            let packagesListData = configstepperSelectorStepCallBack.config_stepper.data.solution_form_data ? configstepperSelectorStepCallBack.config_stepper.data.solution_form_data['packages'] : {}
                            console.log("packagesListData", packagesListData)
                            Object.keys(packagesListData).forEach(function (key) {
                                let selectElement = {}
                                let storeselectElement = {}
                                let dataListPackage = packagesListData[key].field_details ? packagesListData[key].field_details : {}
                                // if (configstepperSelector.data.solution_selected === "ndac_reference_solution" && !_.isEmpty(deviceBlock)) {
                                //     dataListPackage = deviceBlock;
                                // }
                                console.log("dataListPackage", packagesListData)
                                Object.entries(dataListPackage).map((data, _index) => {
                                    selectElement[data[0]] = ""
                                    if (data[1]['type'] === 'checkbox') {
                                        selectElement[data[0]] = []
                                    }
                                    if (data[1]['type'] === 'select' || data[1]['type'] === 'radio' || data[1]['type'] === 'checkbox') {
                                        if (ele_id === data[0]) {
                                            storeselectElement[data[0]] = getSelectOptions(resp.data)
                                        }
                                        else {
                                            storeselectElement[data[0]] = getSelectOptions(data[1]['options'])
                                        }
                                    }
                                    if (data[1]['type'] === 'hidden' && data[0] === ele_id) {
                                        storeselectElement[data[0]] = resp.data;
                                    }
                                    //allRefs.current[data[1]['id']] = false;
                                });
                                if (!_.isEmpty(storeselectElement)) {
                                    storeElementList[key] = storeselectElement;
                                }
                            })
                            if (!_.isEmpty(storeElementList)) {
                                setOptionsData(storeElementList);
                            }
                            console.log("setOptionsDatastoreElementList", storeElementList)
                        }
                        // dispatch(setProp({ slice: "dynamic", key: `element.${stepData[getCurrentStepIndex()]?.stepname}.${[ele_id]}`, value: valueSet }));
                    }

                } else {
                    dispatch(
                        setError({
                            key: "error",
                            value: {
                                errorCode: resp.errorCode,
                                errorMsg: resp.errorMessage,
                            },
                        })
                    );
                    setisDisabled(false);
                }
            }
        } catch (e) {
            dispatch(
                setError({
                    key: "error",
                    value: {
                        errorCode: e,
                        errorMsg: "Timeout",
                    },
                })
            );
            setisDisabled(false);
        } finally {
            dispatch(setError("reset"));
        }
    }




    const getSelectOptions = (options) => {
        let data_list = []
        Object.keys(options).map(key => {
            data_list.push({
                id: key,
                value: options[key]
            })
        })
        return data_list
    }

    const getKeyvalueObj = (optionList) => {
        const arrayOfObjects = Object.entries(optionList).map(([key, value]) => ({
            key: key,
            value,
        }))
        return arrayOfObjects;
    }

    const setRef = (item_id, value) => {
        allRefs.current[item_id] = value;
    }

    const getRef = (item_id) => {
        return allRefs.current[item_id];
    }



    const updateDispatchStore = (item, value) => {

        dispatch(setProp({ slice: "dynamic", key: `element.${stepData[getCurrentStepIndex()]?.stepname}.${[item]}`, value: value }));
        const refval = value !== "" ? true : false;
        setRef(item, refval);
        checkStatesFields(item, value)
        let callBackList = dynamicSelector.data.callBack
        if (Object.keys(callBackList).length > 0) {
            Object.keys(dynamicSelector.data.callBack).forEach(function (itm) {
                Object.keys(dynamicSelector.data.callBack[itm]).forEach(function (itemkey) {
                    if (dynamicSelector.data.callBack[itm][itemkey] === item) {
                        addCallBack(false, value, item)
                    }
                })
            })
        }
        isFormValid()

    }

    const checkStatesFields = (item, value) => {
        Object.keys(packageData).forEach(function (itm) {
            if (packageData[itm].default !== "" || packageData[itm].type === 'range') {
                if (_.keys(packageData[itm].states).length !== 0) {
                    for (let stateData in packageData[itm].states) {
                        if (_.find(packageData[itm].states[stateData].state_details, (obj) => obj.field_name === item)) {
                            let checkField = getCheckField(packageData[itm], stateData, value);
                            let checkFieldValue;
                            if (checkField.length > 0) {
                                checkFieldValue = eval(checkField);
                            }
                            if (checkFieldValue) {
                                if (packageData[itm].default === "" && packageData[itm].type === 'range') {
                                    dispatch(setProp({ slice: "dynamic", key: `element.${configstepperSelector.data.active_step}.${[itm]}`, value: packageData[itm].min }));
                                }
                                else {
                                    if (Array.isArray(packageData[itm].default)) {
                                        let valueSetArray = []
                                        Object.keys(packageData[itm].default).forEach(function (dafultset) {
                                            let valueSetArrayDetails = { key: '', value: '' }
                                            valueSetArrayDetails['key'] = dafultset
                                            valueSetArrayDetails['value'] = packageData[itm].default[dafultset]
                                            valueSetArray.push(valueSetArrayDetails)
                                        })
                                        dispatch(setProp({ slice: "dynamic", key: `element.${configstepperSelector.data.active_step}.${[itm]}`, value: valueSetArray }));
                                    }
                                    else if (typeof packageData[itm].default === 'object') {
                                        let valueSetObject = {}
                                        Object.keys(packageData[itm].default).forEach(function (dafult) {
                                            valueSetObject['key'] = dafult
                                            valueSetObject['value'] = packageData[itm].default[dafult]
                                        })
                                        dispatch(setProp({ slice: "dynamic", key: `element.${configstepperSelector.data.active_step}.${[itm]}`, value: valueSetObject }));
                                    }
                                    else {
                                        let valueSetDefaultObj = {}
                                        valueSetDefaultObj['key'] = packageData[itm].default
                                        valueSetDefaultObj['value'] = packageData[itm].default
                                        dispatch(setProp({ slice: "dynamic", key: `element.${configstepperSelector.data.active_step}.${[itm]}`, value: valueSetDefaultObj }));
                                    }
                                }
                            }
                            else {
                                dispatch(setProp({ slice: "dynamic", key: `element.${configstepperSelector.data.active_step}.${[itm]}`, value: "" }));
                            }
                        }
                    }
                }
            }
        })
    }


    const errorMessageText = (text) => {
        return (
            <div className="fztuMa">
                <span role="alert" className="wBUPn" style={{ color: '#C91514', fontSize: '13px' }}>{text.replace(/_/g, " ").toLowerCase() + " cannot be empty"}</span>
            </div>)
    }

    const listStyle = {
        marginBottom: '15px'
    }



    /** Prepare Steps */
    const prepSteps = (responsedata) => {
        // temp remove after api mpw implementation
        const steps = Object.keys(responsedata.packages);
        // let dynamicSteps = [{ name: 'Business', stepNumber: 1,isChildActive:false, status: 'current', isComplete: false, selected: true, isValid: true, id: 'business', stepname: 'business' }];
        let dynamicSteps = [];
        let stepno = 2;

        steps.forEach(eachStep => {
            const sub_steps = getSubSteps(responsedata.packages[eachStep]['field_details']);
            const is_child_active = sub_steps && sub_steps.length > 0;
            let status = 'future'
            if (responsedata.packages[eachStep].id === "general_details") {
                status = 'current'
            }
            let stepobj = { name: responsedata.packages[eachStep].label, isChildActive: is_child_active, stepNumber: stepno, status: status, isComplete: false, selected: false, isValid: true, id: 'config_' + eachStep, stepname: eachStep, subSteps: sub_steps, states: responsedata.packages[eachStep]['states'] ? responsedata.packages[eachStep]['states'] : [] };

            stepno++;
            dynamicSteps.push(stepobj);

        });
        //const lastStep = { name: "Review", stepNumber: dynamicSteps.length + 1, isChildActive: false, status: 'future', isComplete: false, selected: false, isValid: true, id: 'config_review', stepname: 'review' }
        // dynamicSteps.push(lastStep);
        return dynamicSteps;
    }

    /** getSubSteps */
    const getSubSteps = (stepFieldDetails) => {
        let sub_steps = [];

        if (stepFieldDetails) {
            let childSteps = _.filter(stepFieldDetails, (obj) => obj.type === 'details');;
            let child_stepno = 1;
            _.each(childSteps, (child) => {
                const childStep = { name: child.label, stepNumber: child_stepno, status: 'future', isComplete: false, selected: false, isValid: true, id: 'config_' + child.id, stepname: child.id, isChildActive: false, states: child.states ? child.states : [] };
                sub_steps.push(childStep);
                child_stepno++;
            });
            return sub_steps;
        }
    }



    const handleNext = () => {
        const currentIndex = getCurrentStepIndex();
        dispatch(setProp({ slice: "dynamic", key: "actionNext", value: true }))

        let data = {}
        if (configstepperSelector.data.active_step === "general_details") {
            dispatch(setProp({ slice: "dynamic", key: `element.${configstepperSelector.data.active_step}.scrm_id`, value: radioNetworkCIQ.sCRMID }));
            data["opportunity_id"] = radioNetworkCIQ.sCRMID
           
        }
        if (currentIndex < stepData.length - 1) {
            const updatedSteps = stepData.map((step, index) => ({
                ...step,
                status: index === currentIndex + 1 ? "current" : index <= currentIndex ? "completed" : "future",
            }));
            setStepData(updatedSteps);
        }
        if (getCurrentStepIndex() === stepData.length - 1) {
            data["plan_info"] = dynamicSelector.data.element
            handleSubmit(data)
        }
        setNextButton(false)
        isFormValid("next")
    };

    const handleSubmit = async (data) => {
        console.log("save data",data)

        // try {
        //     let data = {
        //         "opportunity_id": radioNetworkCIQ.sCRMID,
        //         "plan_info": dynamicSelector.data.element
        //     }
        //     console.log(data)
        //     const response = await OOT.planUpdateCIQ(data, 'read');

        // } catch (error) {

        // }
    }

    const handleBack = () => {
        const currentIndex = getCurrentStepIndex();
        dispatch(setProp({ slice: "dynamic", key: "actionNext", value: false }))

        if (currentIndex > 0) {
            const updatedSteps = stepData.map((step, index) => ({
                ...step,
                status: index === currentIndex - 1 ? "current" : index < currentIndex ? "completed" : "future",
            }));
            setStepData(updatedSteps);
        }
        isFormValid("back")
    };

    const handleReset = () => setStepData(stepData);

    const getCurrentStepIndex = () => stepData.findIndex((step) => step.status === "current");

    const getStepContent = (stepName) => {
        const activePackages = stepName ? configstepperSelector.data.solution_form_data['packages'][stepName].field_details ? configstepperSelector.data.solution_form_data['packages'][stepName].field_details : {} : {}
        return activePackages
    }

    const isFormValid = (status = "") => {
        let requiredKeys = []
        let nextBackStep = stepData[getCurrentStepIndex()]?.stepname
        if (status === "next") {
            nextBackStep = stepData[getCurrentStepIndex() + 1]?.stepname
        }
        else if (status === "back") {
            nextBackStep = stepData[getCurrentStepIndex() - 1]?.stepname
        }
        let NextButtonDisable = Object.entries(getStepContent(nextBackStep)).filter(item => item[1]['required']).map((itm) => itm[0])
        let NextButtonDisableData = Object.entries(getStepContent(nextBackStep)).filter(item => item[1]['type']==="composite")

        const reduxStore = store.getState();
        NextButtonDisable.forEach(field => {
            let val = reduxStore.dynamic.data.element[nextBackStep][field]
            if (val != "") {
                requiredKeys.push(true);
            }
            else {
                requiredKeys.push(false);
                
            }
            
        });
        NextButtonDisableData.forEach(function (item) {
            Object.entries(item[1]['field_details']).map((data) => {
                Object.entries(data[1]).map((data1) => {
                    if(data1[1]['required']){
                    let valComposite = reduxStore.dynamic.data.element[nextBackStep][item[0]][data[0]][data1[0]]
                    if (valComposite != "") {
                        requiredKeys.push(true);
                    }
                    else {
                        requiredKeys.push(false);
                        
                    }
                    }
                })
                
            })
        })

        setNextButton(requiredKeys.reduce((acc, value) => acc && value, true))

    };


    const handleClose = () => {
        dispatch(setProp({ slice: "radioNetworkCIQ", key: "captureNetworkPlanModal", value: false }))
        dispatch(setProp({ slice: 'radioNetworkCIQ', key: 'sCRMID', value: "" }))
        dispatch(setProp({ slice: 'radioNetworkCIQ', key: 'stepperLoader', value: false }))
    }

    if(radioNetworkCIQ.scrmLoading){
       return (<><Loader/></>)
    }

    return (
        <div>
            <StepForm orientation="horizontal" style={{ height: "9rem", width: "100%", padding: '1rem' }}>
                {stepData.map((step, index) => (
                    <HorizontalStep
                        key={step.name}
                        title={step.name}
                        variant={step.status}
                        stepNumber={index + 1}
                        activeStep={step.status === "current"}
                    />
                ))}
            </StepForm>

            <HorizontalDivider />

            <StepConfigurationArea className="TextBharath" style={{ display: "grid", gridTemplateColumns: "repeat(2, 0.4fr)", gap: "2rem" }}>
                {/* {Object.entries(activePackages).map((data, index) => { */}
                {Object.entries(getStepContent(stepData[getCurrentStepIndex()]?.stepname)).map((data, index) => {
                    let newRenderConfig = {}
                    const dataStore = store.getState().dynamic.data.element[configstepperSelector.data.active_step];
                    
                    newRenderConfig["dataStore"] = dataStore
                    const allStatesForElement = getElementsStates(data[1], newRenderConfig);
                    const visibilitystyle = allStatesForElement['visibility'] as React.CSSProperties;
                    if (data[0] === 'ndac_accessories') {
                        visibilitystyle['display'] = 'none'
                        //this is static for toggle right algin
                    }

                    if (configstepperSelector.data.fieldsInformation[stepData[getCurrentStepIndex()]?.stepname] && configstepperSelector.data.fieldsInformation[stepData[getCurrentStepIndex()]?.stepname].wrapAfter && (configstepperSelector.data.fieldsInformation[stepData[getCurrentStepIndex()]?.stepname].wrapAfter).some(r => (_.includes(data[0], r)))) {
                        listStyle['flexBasis'] = '100%';
                    } else {
                        listStyle['flexBasis'] = '';
                    }

                    let allStyles = { ...listStyle, ...visibilitystyle };

                    // checkDataCallback(data);
                    switch (data[1]['type']) {
                        case 'select':
                            return (
                                <div style={allStyles}>
                                    {data[1]['multiple'] ?
                                        <MultiSelect optionsData={optionsData} active_step={stepData[getCurrentStepIndex()]?.stepname} data={data} updateDispatchStore={updateDispatchStore} isDisabled={isDisabled} errorMessageText={errorMessageText} ref={allRefs} /> : <SelectFields optionsData={optionsData} active_step={stepData[getCurrentStepIndex()]?.stepname} data={data} updateDispatchStore={updateDispatchStore} isDisabled={isDisabled} errorMessageText={errorMessageText} />
                                    }
                                </div>
                            )
                        case 'radio':
                            return (
                                <div style={allStyles}>
                                    <RadioButtonField optionsData={optionsData} active_step={stepData[getCurrentStepIndex()]?.stepname} data={data} updateDispatchStore={updateDispatchStore} isDisabled={isDisabled} errorMessageText={errorMessageText}
                                    />
                                </div>
                            )
                        case 'checkbox':
                            return (
                                <div style={allStyles}>
                                    <CheckboxField optionsData={optionsData} active_step={stepData[getCurrentStepIndex()]?.stepname} data={data} updateDispatchStore={updateDispatchStore} isDisabled={isDisabled} errorMessageText={errorMessageText} />
                                </div>
                            )
                        case 'text':
                            return (
                                <div style={allStyles}>
                                    <TextField data={data} active_step={stepData[getCurrentStepIndex()]?.stepname} updateDispatchStore={updateDispatchStore} isDisabled={isDisabled} errorMessageText={errorMessageText} />
                                </div>
                            )
                        case 'number':
                            return (
                                <div style={allStyles}>
                                    <SpinnerField data={data} active_step={stepData[getCurrentStepIndex()]?.stepname} updateDispatchStore={updateDispatchStore} isDisabled={isDisabled} errorMessageText={errorMessageText}
                                    />
                                </div>
                            )
                        case 'range':
                            return (
                                <div style={allStyles}>
                                    <RangeField data={data} active_step={stepData[getCurrentStepIndex()]?.stepname} updateDispatchStore={updateDispatchStore} isDisabled={isDisabled} errorMessageText={errorMessageText} />
                                </div>
                            )
                        case 'textarea':
                            return (
                                <div style={allStyles}>
                                    <TextAreaField data={data} active_step={stepData[getCurrentStepIndex()]?.stepname} updateDispatchStore={updateDispatchStore} isDisabled={isDisabled} errorMessageText={errorMessageText} />
                                </div>
                            )       
                        case 'composite':
                            return (
                                <>{ index  % 2 !== 0 && (<div>

                                </div>)}<CompositeStepper compositeField={data[1]} active_step={stepData[getCurrentStepIndex()]?.stepname} isFormValid={isFormValid} /></>
                            )
                    }
                })

                }


            </StepConfigurationArea>
            <HorizontalDivider />
            <ButtonsRow>
                <Button onClick={handleClose}>
                    Cancel
                </Button>
                <Button onClick={handleBack} disabled={getCurrentStepIndex() === 0}>
                    Back
                </Button>
                <Button
                    onClick={handleNext}
                    disabled={!nextButton}
                    variant="call-to-action"
                >
                    {getCurrentStepIndex() === stepData.length - 1 ? "Finish" : "Next"}
                </Button>
            </ButtonsRow>
        </div>
    );
};

export default SimpleStepper;



