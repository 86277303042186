import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";

import CONFIG_API from "Constants/services/configurator";
import { setProp } from "Store/Actions";
import { setError } from "Store/auth";
import { RootState } from "Store/mainStore";
import { setTheme } from "Store/theme";
import { roundCount } from "Utils";
import { useLocalStateManagement } from "./useLocalStateManagement";
import { useConfigurator } from "./useConfigurator";

export const useProductDataList = () => {
  const api_data_call = {
    devices: "ndac_accessories",
    connectivity: "catalog_items",
    mxie: "mxie_server_consumption"
  };

  const configstepperSelector = useSelector(
    (state: RootState) => state.config_stepper
  );
  const dispatch = useDispatch();
  const dynamicSelector = useSelector((state: RootState) => state.dynamic);
  const caseDetailsSelector = useSelector((state: RootState) => state.caseDetails);
  const { setState } = useLocalStateManagement();
  const { getPackageFieldList } = useConfigurator();
  const solutionCategory = configstepperSelector.data.solutionCategory;
  const solutionType = configstepperSelector.data.solutionType;

  /** getDataProductCataLog */
  const getDataProductCataLog = (product) => {
    // const fieldDetails = configstepperSelector.data.solution_form_data['packages'][configstepperSelector.data.active_step].field_details;
    const fieldDetails = getPackageFieldList(configstepperSelector.data.active_step);
    const apitocall = api_data_call[configstepperSelector.data.active_step];
    const listField = fieldDetails[apitocall];
    getDataProductCallback(listField, apitocall, product);
  }

  /** Get product data for modal for addProducts - Review Page customOffer */
  const getCustomOfferProducts = () => {
    const apiData = {
      "data_callback" : {
        "id" : "customiseoffer_product",
        "method" : "GET",
        "endpoint" : "/api/configurator/search/ndac_accessories"
      }
    }
    getDataProductCallback(apiData, "customiseoffer", "");
  }

  /** getDeviceCategory */
  const groupProductsIntoCategory = (datalist, filtername, listType?) => {
    let end_end_services_list = [];
    // filter only is_available true products
    if(listType !== 'das_items') {
      datalist = datalist.filter((item) => item.
      isAvailable && item.
      isAvailable === 'true');
    }
    if (configstepperSelector.data.active_step === 'connectivity' || configstepperSelector.data.active_step === 'mxie') {
      datalist = processResponse(datalist);
    }
    datalist.forEach(element => {
      let aa = element.category ? element.category.split(": ") : [];
      let end_end_services_details = {}
      Object.entries(element).forEach(item => {
        end_end_services_details[item[0]] = item[1]
      });
      
      // if (filtername) {
      //   if (configstepperSelector.data.active_step === 'devices') {
      //     end_end_services_details['category_name'] = element.product_id === 'Cable' ? element.product_id : 'Others';
      //   } else {
      //     end_end_services_details['category_name'] = element.product_id;
      //   }
      // }
      if (!filtername) {
        end_end_services_details['category_name'] = aa[1] ? aa[1] : element.product_id
      }
      end_end_services_details['quantity'] = 1
      end_end_services_details['checked'] = false
      end_end_services_list.push(end_end_services_details)
    });
    let end_end_groups = {};
    if(filtername) {
      end_end_groups["All"] = end_end_services_list;
    } else {
      end_end_services_list.forEach((employee) => {
        let { category_name } = employee;
        let productlist = {}
        if (!end_end_groups[category_name]) {
  
          end_end_groups[category_name] = [];
        }
        productlist['product'] = employee
        end_end_groups[category_name].push(employee);
      });
    }
    
    let productList = {}
    Object.entries(end_end_groups).forEach(element => {
      let prdlist = {}
      // prdlist['product'] = end_end_groups[element[0]]
      productList[element[0]] = end_end_groups[element[0]]

      //let qunty = {}
      Object.entries(productList[element[0]]).forEach(item => {
        // let productDetails = dynamicSelector.data.allProducts.filter((t) => t.product_id === item[1]['product_code'])
        // // if product list is empty or if item price is empty then dont show in the list
        // if (productDetails.length !== 0) {
        //   item[1]['cost'] = productDetails[0].cost
        //   item[1]['list_price'] = productDetails[0].list_price
        //   item[1]['default_list_price'] = productDetails[0].list_price
        // }
        // productdataListData['product_name'] = item['productTitle'];
        // productdataListData['product_id'] = item['productCode'];
        // productdataListData['work_package'] = item['workPackage'];
        // productdataListData['work_item'] = item['workItem'];
        item[1]['cost'] = item[1]['IRP'] ? parseFloat(item[1]['IRP']) * parseInt(item[1]['quantity']) : 0;
        item[1]['list_price'] = item[1]['default_list_price'] =  item[1]['cost'];
      });
      productList[element[0]] = productList[element[0]].filter(item => 'list_price' in item && item.list_price !== '');
    });
    return productList
  }

  const processResponse = (respData) => {
    let output = respData.reduce((acc, curr) => {
      let existingItem = acc.find(item => item.id === curr.id && item.product_id === curr.product_id);
      if (existingItem) {
        existingItem.product_kits.push({
          product_kit_code: curr.product_code,
          product_kit_description: curr.product_description,
          product_kit_quantity: curr.quantity
        });
      } else {
        acc.push({
          id: curr.id,
          product_id: curr.product_id,
          quantity: curr.quantity,
          product_description: curr.sales_kit_description ? curr.sales_kit_description : curr.product_description,
          product_code: curr.sales_kit_id ? curr.sales_kit_id : curr.product_code,
          status: curr.status,
          product_kits: [{
            product_kit_code: curr.product_code,
            product_kit_description: curr.product_description,
            product_kit_quantity: curr.quantity
          }],
          memory_capacity: curr.memory_capacity ? curr.memory_capacity : '',
          pod_capacity: curr.pod_capacity ? curr.pod_capacity : '',
          storage_capacity: curr.storage_capacity ? curr.storage_capacity : '',
          vcpu_capacity: curr.vcpu_capacity ? curr.vcpu_capacity : '',
          IRP: curr.IRP
        });
      }
      return acc;
    }, []);
    return output;
  }

  /** getDataProductCallback */
  const getDataProductCallback = async (data, apitocall, product) => {
    try {
      if (_.includes(['NDAC', 'MPW'], solutionCategory) && (configstepperSelector.data.active_step === 'devices' || configstepperSelector.data.active_step === 'connectivity') && solutionType !== 'Standalone') {
        // Handle store data processing here
        const currentTarget = data.id ? data.id : undefined;
        // @ts-ignore
        if (currentTarget && dynamicSelector.data.element[configstepperSelector.data.active_step][currentTarget] && Object.keys(dynamicSelector.data.element[configstepperSelector.data.active_step][currentTarget]).length > 0) {
          //@ts-ignore
          const storedProductDataList = dynamicSelector.data.element[configstepperSelector.data.active_step][currentTarget];
          // setProductDataList(storedProductDataList)
          setState('setProductDataList', storedProductDataList);
          dispatch(setProp({ slice: "dynamic", key: `element.${configstepperSelector.data.active_step}.${[currentTarget]}`, value: storedProductDataList }));
          dispatch(setTheme({ element: "modal", comp: "connectivity_catalog", prop: "isOpen", value: true }))
          return;
        }
      }

      // Get data from api
      const method = data['data_callback']['method'];
      const endpoint = data['data_callback']['endpoint'];
      let params = '';
      if (apitocall === "mxie_server_consumption") {
        params = "sid=" + dynamicSelector.data.sid;
      }
      // set state for loader for button
      setState('setProdCatalogApiLoad', true);
   
      // Add account_id, currency and sales_channel to the params to send to configurator API
      const accountId = caseDetailsSelector.data.account_id;
      const currency = caseDetailsSelector.data.currency;
      const salesChannel = caseDetailsSelector.data.sales_channel;
      params = params + '&account_id=' + accountId + '&currency=' + currency + '&sales_channel=' + salesChannel;
      
      // api call to get productc data for modal/list
      const resp = await CONFIG_API.configuratorDataCallback(method, endpoint, params);
      if (resp.status && resp.data) {
        if (apitocall === "mxie_server_consumption") {
          let data = [{
            id: "storageValue",
            label: "Storage",
            value: 0
          }, {
            id: "memoryValue",
            label: "Memory",
            value: 0
          },
          {
            id: "vCPUValue",
            label: "vCPU",
            value: 0
          },
          {
            id: "podsValue",
            label: "Pods",
            value: 0
          }
          ];
          let storageValue = 30, memoryValue = 20, vCPUValue = 67, podsValue = 78;
          let capacityofSelectedProduct = [product.pod_capacity, product.memory_capacity, product.storage_capacity, product.vcpu_capacity]
          let consumption = [resp.data?.pod, resp.data?.memory, resp.data?.storage, resp.data?.vcpu]
          podsValue = roundCount((consumption[0] / capacityofSelectedProduct[0]) * 100)
          memoryValue = roundCount((consumption[1] / capacityofSelectedProduct[1]) * 100)
          storageValue = roundCount((consumption[2] / capacityofSelectedProduct[2]) * 100)
          vCPUValue = roundCount((consumption[3] / capacityofSelectedProduct[3]) * 100)

          let graphData = data.map((e) => new Object({
            ...e,
            value: (e.id === "podsValue") ? podsValue : (e.id === "memoryValue") ? memoryValue : (e.id === "storageValue") ? storageValue : (e.id === "vCPUValue") ? vCPUValue : -1,
            capacity: (e.id === "podsValue") ? capacityofSelectedProduct[0] : (e.id === "memoryValue") ? capacityofSelectedProduct[1] : (e.id === "storageValue") ? capacityofSelectedProduct[2] : (e.id === "vCPUValue") ? capacityofSelectedProduct[3] : -1,
            consumption: (e.id === "podsValue") ? consumption[0] : (e.id === "memoryValue") ? consumption[1] : (e.id === "storageValue") ? consumption[2] : (e.id === "vCPUValue") ? consumption[3] : -1
          }))
          dispatch(setProp({ slice: "dynamic", key: `element.${configstepperSelector.data.active_step}.${[apitocall]}`, value: graphData }));
        }
        else {
          const productData = groupProductsIntoCategory(resp.data, 'catalog');
          setState('setProductDataList', productData);
          const id = configstepperSelector.data.active_step === 'review' ? 'customise_offer' : [data['id']];
          dispatch(setProp({ slice: "dynamic", key: `element.${configstepperSelector.data.active_step}.${id}`, value: productData }));
          dispatch(setTheme({ element: "modal", comp: "connectivity_catalog", prop: "isOpen", value: true }))
        }
      } else {
        dispatch(
          setError({
            key: "error",
            value: {
              errorCode: resp.errorCode,
              errorMsg: resp.errorMessage,
            },
          })
        );

      }
    } catch (e) {
      dispatch(
        setError({
          key: "error",
          value: {
            errorCode: e,
            errorMsg: "Timeout",
          },
        })
      );

    } finally {
      // setProdCatalogApiLoad(false)
      setState('setProdCatalogApiLoad', false);
      dispatch(setError("reset"));
    }
  }

  return {
    getDataProductCataLog,
    groupProductsIntoCategory,
    getCustomOfferProducts
  }
}