import { createSlice } from "@reduxjs/toolkit";
import { setProp } from "Store/Actions";


const initialData = {
    networkPlans: [],
    sCRMID:"",
    captureNetworkPlanModal: false,
    solutionFormLoading: false,
    stepperLoader: false,
    scrmValidation: false,
    scrmLoading: false

}

const radioNetworkCIQSlice = createSlice({
    name: 'radioNetworkCIQ',
    initialState: initialData,
    reducers:{
        setNetworkPlans(state, action){
            state.networkPlans = action.payload;
        },
        setCaptureNetworkPlanModal(state, action){
            state.captureNetworkPlanModal = action.payload;
        },
        resetCaptureDialogModal(state){
            state.sCRMID = initialData.sCRMID;
        }
    },
    extraReducers(builder) {
        builder.addCase(setProp, (state, action: {
            payload: any;
            type: string;
            slice: string;
        }) => {
            if (action.payload.slice === radioNetworkCIQSlice.name) {
                state[action.payload.key] = action.payload.value;
            }
        });
    }
})

export default radioNetworkCIQSlice.reducer;
export const {setNetworkPlans, setCaptureNetworkPlanModal, resetCaptureDialogModal} = radioNetworkCIQSlice.actions;