import React, { useState, useEffect } from "react";
import Dialog, {
    DialogContent,
    DialogFooter,
    DialogTitle,
} from "@nokia-csf-uxr/ccfk/Dialog";
import CloseCloseIcon from '@nokia-csf-uxr/ccfk-assets/latest/CloseCloseIcon';
import { Button, Label, TextInput } from "@nokia-csf-uxr/ccfk";
import Checkbox, {
    CheckboxLabelContent,
} from '@nokia-csf-uxr/ccfk/Checkbox';
import Chip, {
    ChipAvatar,
    ChipLabel,
    ChipIconButton,
} from '@nokia-csf-uxr/ccfk/Chip';
import { ButtonIcon, ButtonText } from '@nokia-csf-uxr/ccfk/Button';
import userStyles from '../../admin/user-management/Style/UserManagement.module.css';
import CloseIconLatest from '@nokia-csf-uxr/ccfk-assets/latest/CloseCloseIcon';

const DIALOG_HEIGHT = "50%";
const DIALOG_WIDTH = "30%";

const CONFIRM_ALERT_STYLE = {
    top: `calc((100vh - ${DIALOG_HEIGHT}) / 2)`,
    height: DIALOG_HEIGHT,
    minHeight: DIALOG_HEIGHT,
    left: `calc((100vw - ${DIALOG_WIDTH}) / 2)`,
    right: `calc((100vw - ${DIALOG_WIDTH}) / 2)`,
    width: DIALOG_WIDTH,
};

type IDAddEditFinerDetailsModelProp = {
    onClose: () => void;
    data: any;
    onSave: (data: any, roleId: any) => void;

};

const AddEditFinerDetails = ({ onClose, data, onSave }: IDAddEditFinerDetailsModelProp) => {

    // console.log("AddEditFinerDetails", data);

    // console.log("finer-details", data.roleInfo?.data_objects?.finer_details)

    const [actions, setActions] = useState({});
    const [isDisallowChecked, setIsDisallowChecked] = useState(false);
    const [columnTobeDisallowed, setColumnTobeDisallowed] = useState('');
    const [columnsList, setColumnsList] = useState([]);


    useEffect(() => {
        // Initialize actions with existing finer details
        const existingActions = data.roleInfo?.data_objects?.finer_details?.[data.dataElementInfo.id] || {};
        const existingColumns = data.roleInfo?.data_objects?.finer_details?.[data.dataElementInfo.id]?.disallow || [];
        setActions(existingActions);
        setColumnsList(existingColumns);
        if(existingColumns.length > 0){
            setIsDisallowChecked(true);
        }
    }, [data]);

    const handleOnClose = () => {
        onClose();
    }

    const hasCreate = data?.dataElementInfo?.actions?.values?.includes("create") || false;
    const hasRead = data?.dataElementInfo?.actions?.values?.includes("view") || false;
    const hasUpdate = data?.dataElementInfo?.actions?.values?.includes("update") || false;
    const hasDelete = data?.dataElementInfo?.actions?.values?.includes("delete") || false;

    const handleCheckbox = (action) => {
        console.log("action", action);
        setActions((prevActions) => {
            const newActions = { ...prevActions };
            if (newActions[action] === "any") {
                delete newActions[action]; // Remove the action if it's unchecked
            } else {
                newActions[action] = "any"; // Add the action if it's checked
            }
            return newActions;
        });
    };

    const handleSave = () => {
        console.log("actions", actions);
        const filteredActions = Object.entries(actions)
            .filter(([, value]) => value !== undefined)
            .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
        if (Object.keys(filteredActions).length > 0) {
            const result = { [data.dataElementInfo.id]: filteredActions };
            if (columnsList.length > 0) {
                result[data.dataElementInfo.id]['disallow'] = columnsList;
            }else {
                delete result[data.dataElementInfo.id]['disallow'];
            }
            console.log(result);
            onSave(result, data.roleInfo.id);
            onClose();
        } else {
            console.log("No actions to save");
        }

    };

    const handleColumnDisallowed = () => {
        if (columnTobeDisallowed && !columnsList.includes(columnTobeDisallowed)) {
            setColumnsList([...columnsList, columnTobeDisallowed]);
            setColumnTobeDisallowed('');
        } else {
            console.log("Column already exists or is empty");
        }
    };

    const handleRemoveColumn = (columnToRemove) => {
        setColumnsList(columnsList.filter((column) => column !== columnToRemove));
    };

    return (
        <Dialog
            isOpen={true}
            ariaHideApp={false}
            style={{ content: CONFIRM_ALERT_STYLE }}
        >
            <DialogTitle
                title={"Add finer details"}
                buttonContent={<CloseCloseIcon />}
                buttonProps={{
                    onClick: handleOnClose,
                }}
            />
            <DialogContent isTopDividerVisible={false} isBottomDividerVisible={false}>
                <Label style={{ color: '#757575', fontSize: '14px' }}>{data.dataElementInfo.name}</Label>
                <div style={{ display: 'flex', alignItems: 'center', marginTop: '2rem' }}>
                    {hasCreate && (
                        <div style={{ display: 'flex', marginRight: 4 }}>
                            <Checkbox
                                checked={actions['create'] === "any"}
                                onChange={() => handleCheckbox('create')}
                            />
                            <CheckboxLabelContent style={{ color: '#757575', fontSize: '14px' }}>C <span style={{ fontSize: '10px' }}>any</span></CheckboxLabelContent>
                        </div>
                    )}
                    {hasRead && (
                        <div style={{ display: 'flex', marginRight: 4 }}>
                            <Checkbox
                                checked={actions['view'] === "any"}
                                onChange={() => handleCheckbox('view')
                                    //handleDataElementsCheckboxChange(role.id, data.id, "view")
                                }
                            />
                            <CheckboxLabelContent style={{ color: '#757575', fontSize: '14px' }}>R <span style={{ fontSize: '10px' }}>any</span></CheckboxLabelContent>
                        </div>
                    )}
                    {hasUpdate && (
                        <div style={{ display: 'flex', marginRight: 4 }}>
                            <Checkbox
                                checked={actions['update'] === "any"}
                                onChange={() => handleCheckbox('update')
                                    //handleDataElementsCheckboxChange(role.id, data.id, "update")
                                }
                            />
                            <CheckboxLabelContent style={{ color: '#757575', fontSize: '14px' }}>U <span style={{ fontSize: '10px' }}>any</span></CheckboxLabelContent>
                        </div>
                    )}
                    {hasDelete && (
                        <div style={{ display: 'flex' }}>
                            <Checkbox
                                checked={actions['delete'] === "any"}
                                onChange={() => handleCheckbox('delete')
                                }
                            />
                            <CheckboxLabelContent style={{ color: '#757575', fontSize: '14px' }}>D <span style={{ fontSize: '10px' }}>any</span></CheckboxLabelContent>
                        </div>
                    )}
                </div>
                {/* Disallow columns */}
                <div style={{ display: 'flex', gap: '1rem', marginTop: '2rem' }}>
                    <div style={{ display: 'flex' }}>
                        <Checkbox
                            checked={isDisallowChecked}
                            onChange={() =>{
                                setIsDisallowChecked(!isDisallowChecked);
                                setColumnsList([]);
                            }
                            }
                        />
                        <CheckboxLabelContent style={{ color: '#757575', fontSize: '14px' }}>Disallow</CheckboxLabelContent>
                    </div>


                    {isDisallowChecked && <div style={{ display: 'flex' }}>
                        <TextInput
                            id="column"
                            // disabled={mode === "edit"}
                            value={columnTobeDisallowed}
                            variant={"outlined"}
                            placeholder=""
                            onChange={(e) => setColumnTobeDisallowed(e.target.value)}
                        />
                        <div className={`${userStyles.marginBottom}`} >
                            <Button
                                variant="secondary"
                                disabled={false} onClick={handleColumnDisallowed}
                            >
                                <ButtonText>Add</ButtonText>
                            </Button>
                        </div>
                    </div>

                    }

                    
                </div>

                {columnsList?.length > 0 && <div style={{ marginTop:'1rem'}}>
                        {columnsList.map((column, index) => {
                            return (
                                <div style={{marginBottom:'.5rem'}} key={`ip-${index}`}>
                                <Chip
                                    key={`ip-${index}`}
                                    size="medium"
                                >

                                    <ChipLabel label={column} />
                                    <ChipIconButton
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleRemoveColumn(column);
                                        }}
                                    >
                                        <CloseIconLatest />
                                    </ChipIconButton>
                                </Chip>
                                </div>

                            )
                        })}
                    </div>}

                <div>

                </div>

            </DialogContent>
            <DialogFooter>
                <Button onClick={handleOnClose}>Cancel</Button>
                <Button variant="call-to-action" onClick={handleSave}>
                    Save as draft
                </Button>
            </DialogFooter>
        </Dialog>
    )
}

export default AddEditFinerDetails;

