import React , { useState, useRef, useEffect }from "react";
import Dialog, {
    DialogContent,
    DialogFooter,
    DialogTitle,
} from "@nokia-csf-uxr/ccfk/Dialog";
import { RootState } from "Store/mainStore";
import { useSelector } from "react-redux";
import CloseCloseIcon from '@nokia-csf-uxr/ccfk-assets/latest/CloseCloseIcon';
import Card from '@nokia-csf-uxr/ccfk/Card';
import Chip, {
    ChipLabel,
} from '@nokia-csf-uxr/ccfk/Chip';
import { DataGrid, Typography } from "@nokia-csf-uxr/ccfk";
import Button, {
    ButtonText,
    ButtonIcon,
} from '@nokia-csf-uxr/ccfk/Button';
import { PDFDownloadLink } from '@react-pdf/renderer';
import MyDocument from "./MyDocument";
import PriceDisplay from "./UIElements/PriceDisplay";
import { formatNumberWithCommas } from "./Utils/commons";
import classes from './Style/Index.module.css';


const DIALOG_HEIGHT = "90%";
const DIALOG_WIDTH = "85%";

const CONFIRM_ALERT_STYLE = {
    top: `calc((100vh - ${DIALOG_HEIGHT}) / 2)`,
    height: DIALOG_HEIGHT,
    minHeight: DIALOG_HEIGHT,
    left: `calc((100vw - ${DIALOG_WIDTH}) / 2)`,
    right: `calc((100vw - ${DIALOG_WIDTH}) / 2)`,
    width: DIALOG_WIDTH,
};

type IOfferViewDialogCloseProp = {
    onClose: () => void;
};



const OfferViewDialog = ({ onClose, selectedOffer, offerType }: IOfferViewDialogCloseProp & { selectedOffer: any } & {offerType: string}) => {
    const gridApi = useRef(null);
    const pdfDownloadLinkRef = useRef(null);
    const ownerName = useSelector((state: RootState) => state.authSlice.login.displayName);
    const endCustomer = selectedOffer['one_star_number_customer_partner'];
    const customersList = useSelector((state: RootState) => state.marketplace.customerList);
    const accountInfo = useSelector((state: RootState) => state.marketplace.accountDetails);
   


    const billingAddressInfo = accountInfo[0]?.accountBillingAddress?.find(addr => addr.id === selectedOffer.oot_billing_address_id);
    const shippingAddressInfo = accountInfo[0]?.customer_address?.find(addr => addr.id === selectedOffer.oot_shipping_address_id);

    
    const cartResponse = {
        currency: selectedOffer['field_ofr_order_currency'],
        cartSummary:{
            deal_final_price : Number(selectedOffer['field_ofr_order_cart_total']).toFixed(2)
        },
        cartOfferId: selectedOffer['offer_id'],
    }

    // const getEndCustomerName = (endCustomer) => {
    //     const customerName = customersList.filter(item => item.id === endCustomer);
    //     return customerName['value']
    // }

    const accountDetails = {
        name: selectedOffer['name'],
        end_customer_name: accountInfo[0]?.end_customer_name
    }

    
    const updatedProducts = selectedOffer['products'].map((item) => {
        return {
            ...item,
            id: item.product_id,
            list_price: formatNumberWithCommas(item.price),
            market_price: formatNumberWithCommas(item.market_price),
            product_description: item.title,
            customs_and_logistics: item.custom_logistics_price,
            product_type_id: item.product_type,
            productTotal: formatNumberWithCommas(item.total)
        }
    })

    // const filterAddresses = () => {
    //     billingAddress = accountInfo[0]['accountBillingAddress'].find(addr => addr.id === selectedOffer.oot_billing_address_id);
    //     shippingAddress = accountInfo[0]['customer_address'].find(addr => addr.id === selectedOffer.oot_shipping_address_id);
    // }

    const handleDownloadPDF = () => {
        if (pdfDownloadLinkRef.current) {
            // Call the onClick method of PDFDownloadLink
            pdfDownloadLinkRef.current.onClick();
            onClose();
        }
    };
    return (
        <>
        <Dialog
            isOpen={true}
            ariaHideApp={false}
            style={{ content: CONFIRM_ALERT_STYLE }}
        >
            <DialogTitle
                title={selectedOffer.name}
                buttonContent={<CloseCloseIcon />}
                buttonProps={{
                    onClick: onClose,
                }}
            />
            <DialogContent isTopDividerVisible={true} isBottomDividerVisible={true}>
               
                <div style={{}}>
                    <Card style={{ backgroundColor: '#96AAC533', padding: '1rem', height: '150px' }}>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <div style={{ width: '30%' }}>
                                <h3 style={{ fontWeight: 'bold', marginBlockStart: '0' }}>Total cost</h3>
                                <h2>{<PriceDisplay price={selectedOffer.field_ofr_order_cart_total}/>} <span>{selectedOffer.field_ofr_order_currency}</span></h2>
                                <Typography >Items in cart <span style={{ marginLeft: '5px' }}>{selectedOffer['products'].length}</span></Typography>
                            </div>
                            <div style={{ width: '70%' }}>
                                <div style={{ display: 'flex', flexWrap:'wrap', marginBottom: '.5rem' }}>
                                   
                                        <Typography style={{ fontWeight: 'bold', flex:'0 0 auto', width: '25%' }}>{`${offerType} ID`}</Typography>
                                        <Typography style={{flex:'0 0 auto', width: '25%'}}>{selectedOffer.offer_id}</Typography>
                                    
                                    
                                        <Typography style={{ fontWeight: 'bold', flex:'0 0 auto', width: '25%' }}>{`${offerType} name`}</Typography>
                                        <Typography style={{flex:'0 0 auto', width: '25%'}}>{selectedOffer.offer_title}</Typography>
                                  
                                </div>
                                <div style={{ display: 'flex', flexWrap:'wrap', marginBottom: '.5rem' }}>
                                   
                                        <Typography style={{ fontWeight: 'bold', flex:'0 0 auto', width: '25%' }}>Created date</Typography>
                                        <Typography style={{flex:'0 0 auto', width: '25%'}}>{selectedOffer.offer_created_date}</Typography>
                                    
                                
                                        <Typography style={{ fontWeight: 'bold', flex:'0 0 auto', width: '25%' }}>Account</Typography>
                                        <Typography style={{flex:'0 0 auto', width: '25%'}}>{selectedOffer.name}</Typography>
                                    
                                </div>
                                <div style={{ display: 'flex', flexWrap:'wrap', marginBottom: '.5rem' }}>
                                   {offerType === "Quotation" && 
                                       <>
                                        <Typography style={{ fontWeight: 'bold', flex:'0 0 auto', width: '25%' }}>Expired date</Typography>
                                        <Typography style={{flex:'0 0 auto', width: '25%'}}>{selectedOffer.offer_expiry_date ? selectedOffer.offer_expiry_date : ''}</Typography>
                                        </>
                                    } 
                                    <div style={{ display: 'flex', width: '100%' }}>
                                        <Typography style={{ fontWeight: 'bold', flex:'0 0 auto', width: '25%' }}>Owner</Typography>
                                        <Typography style={{flex:'0 0 auto', width: '25%', overflow: 'unset'}}>{selectedOffer.owner_email}</Typography>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </Card>
                </div>

                <div style={{ marginTop: '1rem', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                    <Chip size="small" style={{ border: '1px solid #000', borderRadius: '15px', padding: '.2rem', marginRight: '5px', fontSize: "14px" }}><ChipLabel label={"Cost:"} />{<PriceDisplay price={selectedOffer.field_ofr_order_cart_total}/>}</Chip>
                    <Chip size="small" style={{ border: '1px solid #000', borderRadius: '15px', padding: '.2rem', fontSize: "14px" }}><ChipLabel label={"Count:"} />{selectedOffer['products'].length}</Chip>
                </div>
                <div id='quotations' style={{ width: '100%', height: '100%', marginTop: '10px' }}>
                    <DataGrid
                        // variant={"compact"}
                        suppressMovableColumns={true}
                        suppressColumnMoveAnimation={false}
                        suppressRowClickSelection={true}
                        isRowDrag={false}
                        wrapperProps={{
                            style: {
                                width: "100%"
                                // width: 600,
                            }
                        }}
                        sideBar
                        disableAutoloadStyles

                        onGridReady={(params) => {
                            gridApi.current = params.api;
                        }}
                        rowData={updatedProducts}
                        columnDefs={[{ headerName: 'ID', field: 'product_id', width: 150 ,cellStyle: { 'borderLeft': '3px solid', 'borderLeftColor': 'var(--g-color-app-banner-background)' } },

                        { headerName: 'Product description', field: 'title', minWidth: 300, flex: 2 },
                        {
                            headerName: 'Quantity',
                            field: 'quantity',
                            width: 100,
                            //flex: 1,

                        },
                        {
                            headerName: 'Price',
                            field: 'price',
                            width: 100,
                            //flex: 1,

                        },
                        {
                            headerName: 'Custom & logistics',
                            field: 'custom_logistics_price',
                            minWidth: 115,
                            //flex: 1,

                        },
                        {
                            headerName: 'Total',
                            field: 'productTotal',
                            minWidth: 115,
                            //flex: 1,

                        },
                        ]}
                        key={'order-items'}
                        defaultColDef={{
                            editable: false,
                            sortable: true,
                            flex: 0,
                            minWidth: 50,
                            // filter: true,
                            // resizable: false,
                        }}
                        overlayNoRowsTemplate='No items are available'
                        isInlineRowActions={false}
                        domLayout='autoHeight'
                    // onFirstDataRendered={(params) => {
                    //     params.columnApi.autoSizeAllColumns();
                    //     // onFirstDataRendered(params);
                    // }}
                    />
                </div>
            </DialogContent>
            <DialogFooter>
                <Button onClick={onClose}>Cancel</Button>
                <Button variant="call-to-action" onClick={handleDownloadPDF}>
                    <PDFDownloadLink document={selectedOffer && <MyDocument data={updatedProducts} accountDetails={accountDetails}  cartResponse={cartResponse} owner={ownerName} orderType={`${offerType}_view`} createdDate={selectedOffer['offer_created_date']} billingAddress={billingAddressInfo} shippingAddress={shippingAddressInfo}/>} fileName={`${offerType === "Quotation" ? "Quotation": "Order"}.pdf`} style={{ textDecoration: 'none', color:'#FFFFFF !important' }}>
                        <ButtonText style={{color: '#FFFFFF !important'}}>Download</ButtonText>
                    </PDFDownloadLink>
                </Button>
            </DialogFooter>
        </Dialog>
        </>
    )
}

export default OfferViewDialog;