import React from "react";
import classes from '../marketplace/Style/Marketplace.module.css'
import ProductsCatalogPage from './ProductsCatalogPage';
import { RootState } from "Store/mainStore";
import { useSelector } from "react-redux";
import Loader from "Components/Elements/Loader";
import CommonFilter from "./common/CommonFilter";
import catalogueStyles from './Style/ProductCatalogueBody.module.css';
import CatalogueLandingPage from "./Components/CatalogueLandingPage";
import TopFilters from "./Components/Filters/TopFilters";
import LeftPanelFilters from "./Components/Filters/LeftPanelFilters";


const MarketplaceBody = () => {
  const marketplaceSelector = useSelector((state: RootState) => state.marketplace);

  if (marketplaceSelector.isPartnerLoading) {
    return <Loader />
  }
  return (
    <div className={classes.marketplaceWrapper}>
      {marketplaceSelector.isGetProducts === false && <CommonFilter />}
      <CatalogueLandingPage />
      {marketplaceSelector.isGetProducts && (
        <div className={catalogueStyles.catalogueBody}>
          <div className={catalogueStyles.leftFilterSection}><LeftPanelFilters /></div>
          <div className={catalogueStyles.rightCatalogueSection}>
            <TopFilters />
            <ProductsCatalogPage />
          </div>

        </div>
      )
      }
    </div>
  )
}

export default MarketplaceBody