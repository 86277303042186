import { createSlice } from "@reduxjs/toolkit";
import { setProp } from "Store/Actions";


const initialData = {
  products: [],
  isProductsLoading: false,
  supplier_account_id: "",
  orders: [],
  orderItems: [],
  orderLocation: [],
  dashboard: {
    graph: [],
    analytics: {"id": "weekly","value": "Weekly"},
    todate: "",
    fromDate: "",
    orderList: [],
    orders: {
      ordersData:[],
      orderItemData: [],
      productDataList: []
    },
    quotations:{
      quotationProductList: []
    }
  }
};

const merchanteSlice = createSlice({
  name: 'marketplace',
  initialState: initialData,
  reducers: {
    setProducts(state, action) {
      const data = action.payload
      state.products = data
    },
    setLoader(state, action) {
      const { key, value } = action.payload;
      state[key] = value;
    },
    setSupplierAcccountId(state, action) {
      state.supplier_account_id = action.payload
    },
    setOrders(state, action) {
      const data = action.payload
      state.orders = data
    },
    setOrderItems(state, action) {
      const data = action.payload
      state.orderItems = data
    },
    setDashboard: (state, action) => {
      const { field, value } = action.payload;
      state.dashboard[field] = value;
    },
    setDashboardData(state, action) {
      const {field, filterName, value} = action.payload;
      state.dashboard[field][filterName] = value;
    },

  },
  extraReducers(builder) {
    builder.addCase(setProp, (state, action: {
      payload: any;
      type: string;
      slice: string;
    }) => {
      if (action.payload.slice === merchanteSlice.name) {
        state[action.payload.key] = action.payload.value;
      }
    });
  }


})

export default merchanteSlice.reducer;             //setProductSubTypes
export const { setProducts, setLoader, setSupplierAcccountId, setOrders, setOrderItems, setDashboard,setDashboardData } = merchanteSlice.actions;