
import { useDispatch, useSelector } from 'react-redux';
import { updateCartProduct } from '../../../Store/contentState/marketplaceSlice';
import { setProp } from 'Store/Actions';
import { RootState } from 'Store/mainStore';
import useOrderPropsData from './useOrderPropsData';
import OOT from 'Constants/services/oot';
import { removeProductFromCart, addProductToCart, updateEntireCart } from '../../../Store/contentState/marketplaceSlice';
import { v4 as uuid } from "uuid";


export const useCartActions = () => {
    let save
    const dispatch = useDispatch();
    const marketplaceSelector = useSelector((state: RootState) => state.marketplace);
    const accountData = useSelector((state: RootState) => state.marketplace.accountDetails);
    const cart = marketplaceSelector.cart;
    // const dataProps = useOrderPropsData(cart, accountData);
    const generateDataProps = useOrderPropsData();

    const handleAction = (actionType, payload) => {
        switch (actionType) {
            case 'updateQuantity':
                handleQuantityChange(payload.item, payload.newQuantity);
                break;
            case 'deleteItem':
                handleDeleteItem(payload.itemId);
                break;
            // Add more cases for other actions if needed
            case 'duration':
                handleDurationChangeForSubscription(payload.item, payload.duration);
                break;
            default:
                break;
        }
    };

    const handleQuantityChange = (item, newQuantity) => {
        const itemId = item.id;
        const newTotalCost = newQuantity * parseInt(item?.price_amount);

        // Update the selectedData with new quantity and total cost
        const updatedSelectedData = {
            ...item,
            product_quantity: newQuantity,
            product_total_cost: newTotalCost
        };

        // Dispatch the action to set the updated selected data
        dispatch(updateCartProduct({ itemId, updatedSelectedData }));
        dispatch(setProp({ slice: 'marketplace', key: 'isCartChanged', value: true }));
        // dispatch(setProp({ slice: 'marketplace', key: 'isCartLoading', value: true }));
    };

    const handleDurationChangeForSubscription = (item, duration) => {
        const subsDuration = duration;
        const itemId = item.id
        const updatedSelectedData = {
            ...item,
            duration: subsDuration
        }
        dispatch(updateCartProduct({ itemId, updatedSelectedData }));
        dispatch(setProp({ slice: 'marketplace', key: 'isCartChanged', value: true }));
    }

    const handleDeleteItem = (itemId) => {
        dispatch(removeProductFromCart(itemId))
        dispatch(setProp({ slice: 'marketplace', key: 'isCartChanged', value: true }));
    };




    const modifyCartProducts = (cart, ndac_items) => {
        return cart.map(cartItem => {
            const product = ndac_items[0].find(p => p.id === cartItem.id);
            if (product) {
                return {
                    ...cartItem,
                    "list_price": product.list_price,
                    "market_list_price": product.market_list_price,
                    "price_after_partner_discount": product.price_after_partner_discount,
                    "price_after_dod": product.price_after_dod,
                    "price_after_tv_discount": product.price_after_tv_discount,
                    "after_management_discount": product.after_management_discount,
                    "customs_and_logistics": product.customs_and_logistics,
                    "cad_percentage": product.cad_percentage,
                    "ptd_percentage": product.ptd_percentage,
                    "vd_percentage": product.vd_percentage,
                    "tbd_percentage": product.tbd_percentage,
                    "sbd_percentage": product.sbd_percentage,
                    "dod_percentage": product.dod_percentage,
                    "quantity": product.quantity,
                    "discount": product.discount,
                    "cost": product.cost,
                    "market_price": product.market_price,
                    "duration": product.duration,
                    "status": product.status,
                    "total": product.total
                };
            }

            return cartItem;
        });
    }

    const getDiscountsFromOOT = async () => {
        const dataProps = generateDataProps(cart, accountData);
        dispatch(setProp({ slice: 'marketplace', key: 'isCartLoading', value: true }));
        try {
            const response = await OOT.saveOffer({ data: dataProps, source: 'marketplace' });
            if (response.status) {
                const newCart = modifyCartProducts(cart, response.data.ndac_items);
                dispatch(updateEntireCart(newCart));
                //dispatch(set)
                const cartData = {
                    currency: response.data.currency,
                    cartSummary: response.data.cart_summary,
                    cartOfferId: response.data.offer_id
                }
                dispatch(setProp({ slice: 'marketplace', key: 'cartResponse', value: cartData }))
                // dispatch(setProp({ slice: 'marketplace', key: 'cartOfferId', value: response.data.offer_id }))
                dispatch(setProp({ slice: 'marketplace', key: 'isCartLoading', value: false }));
                dispatch(setProp({ slice: 'marketplace', key: 'isCartChanged', value: false }));
                saveCartDetails(null, newCart, response.data.offer_id);
            } else {
                // Handle the error case
                dispatch(setProp({ slice: 'marketplace', key: 'isCartLoading', value: false }));
                // dispatch(setProp({ slice: 'marketplace', key: 'isCartChanged', value: false }));
            }
        } catch (error) {
            dispatch(setProp({ slice: 'marketplace', key: 'isCartLoading', value: false }));
            console.error(error);
        }
    };

    const syncOfferCall = () => {
        getDiscountsFromOOT();

    }

    const saveCartDetails = async (product = null, updatedCart = null, offerId = '') => {
        
        try {
            const cartData = {
                "cart": {
                    "cart_summary": {
                        "account": marketplaceSelector.accountDetails[0].name,
                        "customer": marketplaceSelector.accountDetails[0]['end_customer_name'],
                        "network": "",
                        "country": marketplaceSelector.shippingCountryText
                    },
                    "cart_items": product !== null ? [...marketplaceSelector.cart, product] : updatedCart
                },
                "source":'marketplace',
                "bookmark_items": [],
                "temp_offer_id": marketplaceSelector.cartResponse.cartOfferId ? marketplaceSelector.cartResponse.cartOfferId : "marketplace_temp"
            }
            const cartResponse = await OOT.saveCartDetailsAPI(cartData, 'save_to_cart=true')
            
            if (cartResponse.status) {
                return true
            } else {
                return false
            }
        } catch (error) {
            console.error(error)
        }
    }

    


    const addToCart = (product, quantity = 1, fromSpecification = false) => {
       const saveToCartResponse =  saveCartDetails(product)
       if(saveToCartResponse){
            dispatch(addProductToCart({
                productToAdd: product,
                quantity: quantity,
                fromSpecification: fromSpecification
            }));
            dispatch(setProp({ slice: 'marketplace', key: 'isCartChanged', value: true }));
            return true
        }
        else{
            return false
        }
       ;
    };

    return { handleAction, syncOfferCall, saveCartDetails, addToCart };
};

export default useCartActions;