import React, {useRef} from "react";
import DataGrid from '@nokia-csf-uxr/ccfk/DataGrid/DataGrid';
import { Typography } from "@nokia-csf-uxr/ccfk";
import Card from '@nokia-csf-uxr/ccfk/Card';

const data = [
    {
        sCRMID: "22.DE.296594",
        caseName: "SML-DEKRA_Lausitzring-NDAC HW replacement after fire",
        networkLocationCountry: "Australia",
        shippingCountry: "Australia",
        market: "Asia Pacific & Japan",
    },
    {
        sCRMID: "20.MX.900483",
        caseName: "AIRBUS D&S - NTN IRIS2 Governmental Services - 5G SA Core & Security",
        networkLocationCountry: "Bahamas",
        shippingCountry: "Bahamas",
        market: "Europe",
    },

]

const columns = [
    {
        headerName: 'sCRM ID',
        field: 'sCRMID',
        minWidth: 200,
        flex: 1,

    },
    {
      headerName: 'Case name',
      field: 'caseName',
      minWidth: 115,
      flex: 1,

  },
  {
    headerName: 'Network location country',
    field: 'networkLocationCountry',
    minWidth: 115,
    flex: 1,

  },
  {
    headerName: 'Shipping country',
    field: 'shippingCountry',
    minWidth: 115,
    flex: 1,

  },
{
    headerName: 'Market',
    field: 'market',
    minWidth: 115,
    flex: 1,

},
]

const RadioNetworkPlans = () => {
    const gridApi = useRef(null);
    return (
        <>
            <Card style={{ padding: '1rem', outline: 'none' }}>
                <Typography style={{ color: '#333333', fontSize: '18px', borderBottom: "1px solid #0000001F", fontWeight: 'bold' }}>Captured data sets and network plans</Typography>
                <div style={{ width: '100%', height: '100%', marginTop: '1rem' }}>
                    <DataGrid
                        rowData={data}
                        //@ts-ignore
                        columnDefs={columns}
                        suppressMovableColumns={false}
                        //variant="compact"
                        suppressColumnMoveAnimation={false}
                        suppressRowClickSelection={false}
                        isRowDrag={false}
                        wrapperProps={{
                            style: {
                                width: "100%",
                            },
                        }}
                        disableAutoloadStyles
                        suppressContextMenu
                        onGridReady={(params) => {
                            gridApi.current = params.api;
                        }}
                        key={"roles-table"}
                        defaultColDef={{
                            editable: false,
                            sortable: false,
                            resizable: false,
                            // filter: true,
                            flex: 0,
                            minWidth: 100,

                        }}
                        overlayNoRowsTemplate="No applications available"
                        domLayout="autoHeight"
                        isInlineRowActions={false}
                        
                    />
                </div>
            </Card>
        </>
    )
}

export default RadioNetworkPlans;