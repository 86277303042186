import React from "react";
import Dialog, {
    DialogContent,
    DialogFooter,
    DialogTitle,
} from "@nokia-csf-uxr/ccfk/Dialog";
import CloseCloseIcon from '@nokia-csf-uxr/ccfk-assets/latest/CloseCloseIcon';
import SCRMValidation from "./SCRMValidation";
import userStyles from '../admin/user-management/Style/UserManagement.module.css';
import DialogActions from "./CaptureRadioNetworkCIQDialogContainer/DialogActions";
import CaptureDialogEmptyPage from "./CaptureRadioNetworkCIQDialogContainer/CaptureDialogEmptyPage";
import { resetCaptureDialogModal } from "Store/contentState/radioNetworkCIQSlice";
import { useDispatch,useSelector} from "react-redux";
import Stepper from "./Stepper";
import { RootState } from "Store/mainStore";
import Loader from "Components/Elements/Loader";




const DIALOG_HEIGHT = "90%";
const DIALOG_WIDTH = "90%";

const CONFIRM_ALERT_STYLE = {
    top: `calc((100vh - ${DIALOG_HEIGHT}) / 2)`,
    height: DIALOG_HEIGHT,
    minHeight: DIALOG_HEIGHT,
    left: `calc((100vw - ${DIALOG_WIDTH}) / 2)`,
    right: `calc((100vw - ${DIALOG_WIDTH}) / 2)`,
    width: DIALOG_WIDTH,
};

type ICaptureRadioNetworkCIQModalProp = {
    onClose: () => void;
    // mode: string;
    // selectedUserData: any;
    // typeOfUser: string;
};


const CaptureRadioNetworkCIQDialog = ({ onClose }: ICaptureRadioNetworkCIQModalProp) => {
  const dispatch = useDispatch();
    const radioNetworkCIQ = useSelector((stete: RootState) => stete.radioNetworkCIQ)
    const handleDialogClose = () => {
        onClose();
        dispatch(resetCaptureDialogModal());
    }
    
    return (
        <>
            <Dialog isOpen={true} ariaHideApp={false} style={{ content: CONFIRM_ALERT_STYLE }}>
                <DialogTitle
                    title="Please fill the below fields for Radio Network Planning"
                    buttonContent={<CloseCloseIcon />}
                    buttonProps={{ onClick: handleDialogClose }}
                />
                <DialogContent isTopDividerVisible={true} isBottomDividerVisible={true} style={{ padding: '0' }}>
                    <div>
                        <div style={{ padding: '1rem' }}>
                            <SCRMValidation />
                        </div>
                        <div className={userStyles.borderBottomLine}></div>
                       
                        {radioNetworkCIQ.scrmValidation && radioNetworkCIQ.stepperLoader && <div>
                        
                        <Stepper/>
                        </div>}
                        {!radioNetworkCIQ.stepperLoader && <div>
                            <CaptureDialogEmptyPage/>
                        </div>}
                    </div>
                </DialogContent>
                {/* <DialogFooter>
                   <DialogActions/>
                </DialogFooter> */}
            </Dialog>

        </>
    )
}

export default CaptureRadioNetworkCIQDialog;