import React from 'react';
import Tokens from '@nokia-csf-uxr/nokia-design-system-tokens/js/tokens.es6';
import {
  Rectangle,
} from 'recharts';

const CHART_MARGIN = {
  top: 0,
  right: 18,
  bottom: 20,
  left: 0,
};

const DEFAULT_CONTENT_STYLE = {
  width: '600px'
};

const DEFAULT_DARK_CONTENT_STYLE = {
  width: '600px',
  backgroundColor: `${Tokens.LEGACY_COLOR_DARK_GREY_500}`
};

const titleCase = str => (str ? str.charAt(0).toUpperCase() + str.slice(1) : str);

const VALUE_AND_NAME_FORMATTER = (value, name) => {
  const mil = value;
  return `${mil === 0 ? 0 : mil}`;
};

const HORIZONTAL_BAR = props => <Rectangle {...props} radius={[4, 0, 0, 4]} />;

const VERTICAL_BAR = props => <Rectangle {...props} radius={[2, 2, 0, 0]} />;

const DEFAULT_Y_TICK_FORMATTER = (value) => {
  const mil = value / 1000000;
  return mil === 0 ? 0 : mil.toFixed(0);
};

const renderLegendText = (value) => {
  return <span style={{ color: `${Tokens.LEGACY_COLOR_NEUTRAL_GREY_300}` }}>{titleCase(value)}</span>;
};

const TOOLTIP_LABLE_STYLE = {
  textAlign: 'center'
};

const xTickFormatter = value => value.toString().toUpperCase();

export {
  CHART_MARGIN,
  DEFAULT_CONTENT_STYLE,
  DEFAULT_Y_TICK_FORMATTER,
  renderLegendText,
  VALUE_AND_NAME_FORMATTER,
  DEFAULT_DARK_CONTENT_STYLE,
  HORIZONTAL_BAR,
  TOOLTIP_LABLE_STYLE,
  titleCase,
  xTickFormatter,
  VERTICAL_BAR
};