import React, { useState, useEffect } from 'react';
import { setProp } from 'Store/Actions';
import { useDispatch, useSelector } from 'react-redux';
import CloseCloseIcon from '@nokia-csf-uxr/ccfk-assets/latest/CloseCloseIcon';
import { ButtonIcon, ButtonText } from '@nokia-csf-uxr/ccfk/Button';
import PropTypes from "prop-types";
import UploadPO from './orders/UploadPO';

import Checkbox, {
  CheckboxLabelContent,
} from '@nokia-csf-uxr/ccfk/Checkbox';

import { Button } from "@nokia-csf-uxr/ccfk";
import { RootState } from "Store/mainStore";
import TermsConditionsDailog from './TermsConditionsDialog';
import OOT from 'Constants/services/oot';


const DIALOG_HEIGHT = "60%";
const DIALOG_WIDTH = "60%";

const CONFIRM_ALERT_STYLE = {
  top: `calc((100vh - ${DIALOG_HEIGHT}) / 2)`,
  height: DIALOG_HEIGHT,
  minHeight: DIALOG_HEIGHT,
  left: `calc((100vw - ${DIALOG_WIDTH}) / 2)`,
  right: `calc((100vw - ${DIALOG_WIDTH}) / 2)`,
  width: DIALOG_WIDTH,
};

const TermsConditions = () => {
  const dispatch = useDispatch();
  const marketplaceSelector = useSelector((state: RootState) => state.marketplace);
  const [nokiaTermsChecked, setNokiaTermsChecked] = useState(undefined);
  const [isAuthorizedChecked, setIsAuthorizedChecked] = useState(undefined);
  const [indeterminate, setIsIndeterminate] = useState(false);
  const [showTCDialog, setShowTCDialog] = useState(false);
  const [termsType, setTermsType] = useState('');
  const [showOrderDialog, setShowOrderDialog] = useState(false);
  const cart = useSelector((state: RootState) => state.marketplace.cart);
  let versionIds = [];

  const checkMandatoryTerms = () => {
    let checked = true
    if (nokiaTermsChecked) {
      if (isAuthorizedChecked) {
        checked = false
      }
    }
    return checked;
  }

  const handleOrderButton = () => {
    setShowOrderDialog(true)
  }

  const hasMerchantProducts = () => {
    return marketplaceSelector.cart.some(item => item.is_agent_model !== 0);
  };

  useEffect(() => {
    if (marketplaceSelector.isCartChanged) {
      setNokiaTermsChecked(false);
      setIsAuthorizedChecked(false);

    }
  }, [marketplaceSelector.isCartChanged]);


  const getSupplierIdsFromCart = (cart) => {
    // Extract unique supplier_ids from cart items where is_agent_model is 1
    const supplierIds = Array.from(new Set(cart.filter(item => item.is_agent_model === 1 || item.is_agent_model === 0).map(item => item.supplier_id)));
    return supplierIds;
  };


// const getTermsAndConditions = async (type: string) => {
//     try {
//       let supplierIds = [];
      
//       if (type === 'merchant' || type === 'dac') {
//         supplierIds = getSupplierIdsFromCart(cart);
//       }
//       let filter = `order by merchant_terms_and_conditions.version_id desc`
//       const response = await OOT.getTermsConditions(type === 'dac' ? ["NFI85"] : supplierIds, filter);
//       if (response.status) {
//         // setTermsConditions(response.data[0]['terms_and_conditions']['info']);
//         versionIds.push(response.data[0]['version_id']);
//         //console.log(versionIds)
//         dispatch(setProp({ slice: 'marketplace', key: 'nokiaTermsConditions', value: versionIds.join(',') }))
//       }
//     } catch (error) {
//       console.error(error);
//     }
//   };



  return (
    <>
      {/* <Label> */}
      {showOrderDialog && (
        <UploadPO onClose={() => setShowOrderDialog(false)} selectedOffer={""} orderMode="directOrder" modalTitle="Order" />
      )}

      {showTCDialog && (
        <TermsConditionsDailog onClose={() => setShowTCDialog(false)}  type={termsType}/>
      )}

      <>

        {/* <div style={{ marginTop: '1rem' }}>
          <div style={{ display: 'flex', marginBottom: '2rem' }}>
            <Checkbox
              variant="default"
              disabled={marketplaceSelector.isCartChanged === true}
              indeterminate={indeterminate}
              checked={nokiaTermsChecked}
              onChange={(event) => {
                event.persist();
                setIsIndeterminate(false);
                setNokiaTermsChecked(event.target.checked);
                getTermsAndConditions('dac')
              }}
              inputProps={{
                'aria-label': 'Check this to agree to terms'
              }}
            />
            <CheckboxLabelContent style={{ color: '#757575', fontSize: '.7rem', marginBottom: '.5rem', maxWidth: '100%', overflow: 'unset', webkitBoxOrient: 'unset' }}>I agree to the terms and conditions of the <span style={{ cursor: 'pointer', color: '#005AFF', marginRight: '3px', textDecoration: 'underline' }} onClick={() => {setShowTCDialog(true); setTermsType('dac');}}>DAC Marketplace Agreement</span> 
              {hasMerchantProducts() && (
              <>
                and
                <span style={{ cursor: 'pointer', color: '#005AFF', marginLeft: '3px', textDecoration: 'underline' }}> 
                  <a href="https://www.dac.nokia.com/terms/marketplace/" target="_blank" rel="noopener noreferrer"> Merchant Terms</a>
                </span>.
                and/or
                <span style={{ cursor: 'pointer', color: '#005AFF', marginLeft: '3px', textDecoration: 'underline' }}>
                <a href="https://www.dac.nokia.com/terms/" target="_blank" rel="noopener noreferrer">Nokia Terms</a>
                </span>.
              </>
            )}
             {!hasMerchantProducts() && (
              <>
                and/or
                <span style={{ cursor: 'pointer', color: '#005AFF', marginLeft: '3px', textDecoration: 'underline' }}>
                <a href="https://www.dac.nokia.com/terms/" target="_blank" rel="noopener noreferrer">Nokia Terms</a>
                </span>.
              </>
            )}
            </CheckboxLabelContent>
          </div>
          

          <div style={{ display: 'flex' }}>
            <Checkbox
              variant="default"
              disabled={marketplaceSelector.isCartChanged === true}
              indeterminate={indeterminate}
              checked={isAuthorizedChecked}
              onChange={(event) => {
                event.persist();
                setIsIndeterminate(false);
                setIsAuthorizedChecked(event.target.checked);
              }}
              inputProps={{
                'aria-label': 'Check this to agree to terms'
              }}
            />
            <CheckboxLabelContent style={{ color: '#757575', fontSize: '.7rem', marginBottom: '.5rem', maxWidth: '100%', overflow: 'unset', webkitBoxOrient: 'unset' }} >I confirm that I am authorized to enter into the above mentioned
              agreements on behalf of the company I represent.</CheckboxLabelContent>
          </div>
        </div> */}
        <div style={{ marginTop: '3rem', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Button variant="call-to-action" fullWidth={false} disabled={marketplaceSelector.isCartChanged}  onClick={() => handleOrderButton()}><ButtonText>Next</ButtonText></Button>
        </div>

      </>

      {/* disabled={checkMandatoryTerms()} */}





      
      
    </>
  );
};

export default TermsConditions;

// TermsConditions.propTypes = {
//   onProceed: PropTypes.func
// }


