import { PublicClientApplication } from '@azure/msal-browser';
import _ from 'lodash';
import { apiTimeout } from "Components/Misc/CaseDetails_System";
import { showModal } from "Store/auth";
import axios from "axios";
//https://corsproxy.io/? if this is the issue lets this will integrate from the OOT.

class CONFIGURATOR_API {
  accessToken;
  email;
  idtoken;
  redX;
  configUrl;
  constructor() {
    //this.interceptRequests();
  }
  init({ accessToken, email, idtoken, redX }) {
    if (accessToken && email) {
      this.accessToken = accessToken;
      this.email = email;
      this.idtoken = idtoken;
      this.redX = redX;
      // this.configUrl = configUrl;
    } else {
      return { msg: "Failed to initiate." };
    }
  }
  interceptRequests() {
    axios.interceptors.response.use((response) => {
      if (response.data.errorCode === 401 &&
        (
          response.data.errorMessage.trim().toUpperCase().includes("TOKEN ERROR") ||
          response.data.errorMessage.trim().toUpperCase().includes("TOKEN EXPIRED")
        )
      ) {
        this.redX.dispatch(showModal({ msg: "Please log in again now...", variant: "ERROR", title: response.data.errorMessage.trim().toUpperCase(), show: true }))
        // Show the message 5 second and redirect the page to login
       
      }
      else if (response.data.errorCode == 401) {
        this.speak(401);
      }
      if (!response.data.status) {
        this.speak(500, { msg: response.data.errorMessage, panel: { title: 'More details', msg: response.data.stacktrace } })
      }
      return response;
    }, (error) => {
      this.speak(500, { msg: error, panel: { title: '', msg: '' } })
      return Promise.reject(error);;
    })
    axios.interceptors.request.use((request) => {
      request.headers["Content-Type"] = "application/json; charset=UTF-8";
      request.headers["Authorization"] = "Bearer " + this.accessToken;
      delete request.headers["idtoken"];
      request.headers["idtoken"] = this.idtoken;
      request.timeout = 1000 * apiTimeout;
      return request;
    })
  }
  speak(code, info = { msg: '', panel: { title: '', msg: '' } }) {
    switch (code) {
      case 401:
        this.redX.dispatch(showModal({ msg: "Please Reload to continue , If you have any unsaved data, close this message and copy the data before logging in again. If not, please log in again now.", variant: "ERROR", title: "Session Timed Out", show: true }))
        break;
      case 200:
        break;
      case 500:
        this.redX.dispatch(showModal({
          msg: info.msg, variant: "ERROR", title: "Something went wrong", show: true, panel: {
            msg: info.panel.msg,
            title: info.panel.title
          }
        }))
        break;
    }
  }

  // get segment solution list on select of segments
  getSegmentList = async () => {
    const params = `?_format=json&username=${this.email}`;
    const res = await axios({
      method: "get",
      url: process.env.REACT_APP_CONFIGURATOR + '/segment/list' + params,
    });
    return res.data;
  };

  // get segment solution form on select of solution
  getSegmentForm = async (solution) => {
    const params = `?_format=json&username=${this.email}`;
    const res = await axios({
      method: "get",
      url: process.env.REACT_APP_CONFIGURATOR + '/segment/' + solution + params,
    });
    return res.data;
  };

  segmentFormSubmission = async (method, solutionName: any, cartDataList: any, sid: string, eot_params: string,undo:boolean) => {
    let params = `?_format=json&username=${this.email}&${eot_params}`;
    if (sid !== "") {
      params = `?_format=json&username=${this.email}&sid=${sid}&${eot_params}`;
    }
    // if undo is true then validate is false
    params = undo ? params + '&validate=false' : params + '&validate=true';
    const res = await axios({
      method: method,
      url: process.env.REACT_APP_CONFIGURATOR + '/segment/' + solutionName + params,
      data: cartDataList
    });
    return res.data
  }

  configuratorDataCallback = async (method, endPoint, args, post_data?) => {
    let params = `?_format=json&username=${this.email}`;
    endPoint = (endPoint).split('/api/configurator')[1];
    let joinargs = args.length > 0 ?  '&' + args : '';
    let requestpayload = {};
    if(post_data) {
      const res = await axios({
        method: method,
        url: process.env.REACT_APP_CONFIGURATOR + endPoint + params + joinargs,
        data: post_data
      });
      return res.data;
    } else {
      requestpayload = {
        method: method,
        url: process.env.REACT_APP_CONFIGURATOR + endPoint + params + joinargs
      };
      const res = await axios(requestpayload);
      return res.data;
    }
    // const res = await axios(requestpayload);
    // return res.data;
  }

  // -------------- NDAC Ref solution APIs --------------------- //
  getPlannerAttachments = async (scrmid) => {
    const params = `?_format=json&username=${this.email}&scrmid=${scrmid}`;
    const res = await axios({
      method: "get",
      url: process.env.REACT_APP_NODE + '/api/odt/odt_ndacplanner' + params,
    });
    return res.data;
  };

  getZippedAttachment = async (attachmentid) => {
    const params = `?_format=json&username=${this.email}&attachmentid=${attachmentid}`;
    const res = await axios({
      method: "get",
      url: process.env.REACT_APP_NODE + '/api/odt/odt_ndacplannerAttachment' + params,
    });
    return res.data;
  };
  trimTrailingSlash(url: string): string {
    return 
  }

  /**
   * Fetches the list of services from the configurator API.
   * 
   * @returns {Promise<any>} A promise that resolves to the list of services.
  */
  fetchAvailableServices = async (searchValue=undefined,signal=undefined) => {
    let params = `?_format=json&username=${this.email}`;
    if(searchValue){
      params += `&keyword=${searchValue}`;
    }    
    const __url = process.env.REACT_APP_CONFIGURATOR.endsWith('/') ? process.env.REACT_APP_CONFIGURATOR.slice(0, -1) : process.env.REACT_APP_CONFIGURATOR;
    // axios request
    const config = {
      method: "get",
      url: `${__url}/search/services${params}`,
    };
    // Conditionally add the signal to the config if AbortController is used
    if (signal) {
      config["signal"] = signal;
    }
    const res = await axios(config);
    return res.data;
  };

  /**
   * Fetches the list of services from the configurator API.
   * 
   * @returns {Promise<any>} A promise that resolves to the list of services.
  */
  getCustomSericePriceList = async (param=undefined,savedServices=undefined,signal=undefined) => {
    let __params = `?_format=json&username=${this.email}`;
    if(param && _.isEmpty(param) === false){
      // add the parameters to the query string
      Object.keys(param).forEach((key) => {
        __params += `&${key}=${param[key]}`;
      });
    }    
    const __url = process.env.REACT_APP_CONFIGURATOR.endsWith('/') ? process.env.REACT_APP_CONFIGURATOR.slice(0, -1) : process.env.REACT_APP_CONFIGURATOR;
    // axios request
    const config = {
      method: "post",
      url: `${__url}/customAddService${__params}`,
    };
    // Conditionally add the signal to the config if AbortController is used
    if (signal) {
      config["signal"] = signal;
    }
    if(savedServices){
      config["data"] = savedServices;
    }
    const res = await axios(config);
    return res.data;
  };

}
const CONFIG_API = new CONFIGURATOR_API();
export default CONFIG_API;