import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import StatusBar, {
  StatusBarSection,
  StatusBarPaginationIcon,
  StatusBarPagination,
  StatusBarText,
  StatusBarTextTitle,
  StatusBarPageGroup,
  StatusBarPaginationInput
} from '@nokia-csf-uxr/ccfk/StatusBar';
import ChevronLeftIcon from '@nokia-csf-uxr/ccfk-assets/latest/ChevronLeftIcon';
import ChevronRightIcon from '@nokia-csf-uxr/ccfk-assets/latest/ChevronRightIcon';
import Tooltip from '@nokia-csf-uxr/ccfk/Tooltip';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from "Store/mainStore";
// import { setCurrentPage, setLimit } from 'Store/contentState/marketplaceSlice';
import { setCurrentPage, setLimit, resetPaginationsProps } from 'Store/contentState/paginationSlice';




const Pagination = ({ disableProp, totalItems = 0, type = "" }) => {
  const dispatch = useDispatch();
  const paginationSelector = useSelector((state: RootState) => state.pagination);
  const { currentPage, limitPerpage } = paginationSelector.paginationProps;
 
  // Calculate total pages if `totalItems` is provided
  const totalPages = totalItems ? Math.ceil(totalItems / limitPerpage) : null;



  const handlePrev = () => {
    if (currentPage > 1) {
      dispatch(setCurrentPage(currentPage - 1));
    }
  };

  const handleNext = () => {
    // if (currentPage < totalPages) {
    dispatch(setCurrentPage(currentPage + 1));
    // }
  };

  const handleLimit = (value) => {
    dispatch(setCurrentPage(1));
    dispatch(setLimit(parseInt(value)))
  }

  // const handleOffset = () => {
  //   const offset = (currentPage - 1) * limitPerpage;
  // }

  const getStatusCheck = () => {
    let statusCheck = true
    if(totalPages != null){
      statusCheck = true
    }
    else if (type != "" && totalPages == null){
      statusCheck = false
    }
    return statusCheck
  }


  return (
    <>
      {getStatusCheck() && <StatusBar>
    <StatusBarSection>
      <StatusBarTextTitle>Items per page:</StatusBarTextTitle>
      <StatusBarText>
        <select value={limitPerpage} onChange={(e) => handleLimit(e.target.value)} style={{ margin: '2px 0 0 0', padding: '.2rem' }}>
          <option value={20}>20</option>
          <option value={30}>30</option>
          {/* <option value={40}>40</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option> */}
        </select>
      </StatusBarText>
    </StatusBarSection>
    <StatusBarPagination>
      <StatusBarPaginationIcon
        iconButtonProps={{
          'aria-label': 'Previous Page',
          disabled: currentPage === 1,
          onClick: () => {
            handlePrev()
          }
        }}
      >
        <ChevronLeftIcon />
      </StatusBarPaginationIcon>
      <StatusBarPageGroup>
        <StatusBarTextTitle>Page</StatusBarTextTitle>

        <StatusBarText>{currentPage} {type === 'product' ? `/ ${totalPages}` : ""}</StatusBarText>
      </StatusBarPageGroup>
      <StatusBarPaginationIcon
        iconButtonProps={{
          'aria-label': 'Next Page',
          disabled: !totalPages ? disableProp.length === 0 : currentPage >= totalPages,  //needs to be handled later
          onClick: () => {
            handleNext();
          }
        }}
      >
        <ChevronRightIcon />
      </StatusBarPaginationIcon>


    </StatusBarPagination>
    {(totalItems > 0 && type === 'product') && <StatusBarSection>
      <StatusBarTextTitle>Count:</StatusBarTextTitle>
      <StatusBarText>
        {totalItems}
      </StatusBarText>
    </StatusBarSection>}



  </StatusBar>
  } 
    
  </>
  )
};


export default Pagination;

Pagination.propTypes = {
  disableProp: PropTypes.array,
  totalItems: PropTypes.any,
  type: PropTypes.string
}


