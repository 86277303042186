import React, { useEffect, useMemo, useState, memo } from 'react';
import App from '@nokia-csf-uxr/ccfk/App';
import { RootState } from "Store/mainStore";
import { useSelector } from "react-redux";
import GlobalCMAPStyles from '@nokia-csf-uxr/cmap-client-components/styles/GlobalCMAPStyles';
import MapContainer from '@nokia-csf-uxr/cmap-client-components/MapContainer';
import Marker from '@nokia-csf-uxr/cmap-client-components/Marker';
import TileLayer from '@nokia-csf-uxr/cmap-client-components/TileLayer';
import Tooltip from '@nokia-csf-uxr/cmap-client-components/Tooltip';


import CircleMarker from '@nokia-csf-uxr/cmap-client-components/CircleMarker';

import countryLatLong from 'Constants/countryLatLong.json';
import { subnetIcon } from '@nokia-csf-uxr/cmap-client-components/Marker/Icons';

import * as L from 'leaflet';


const MapContent = (prop) => {
    const marchenteSelector = useSelector((state: RootState) => state.merchant.dashboard);
    //const { graph } = marchenteSelector.dashboard;
    const [graph, setGraph] = useState(prop.mapCountryCode)


    //@ts-ignore
    const subnet_icon = L.icon(subnetIcon);

    let position: [number, number] = [0, 0];


    useEffect(() => {
        setGraph(prop.mapCountryCode)
    }, [prop.mapCountryCode])

    const getCountryLongLatit = (code) => {
        const country = countryLatLong.filter((item) => item.country_code === code)
        let longLatit = []
        if (country) {
            longLatit = [country[0].latitude, country[0].longitude]
        }
        return longLatit.map(Number)
    }
    const [centreLocation, setCentreLocation] = useState(graph.length != 0 ? getCountryLongLatit(graph[0].country_id) : [0, 0]);
    // Group and count `country_id`
    const countryCounts = useMemo(() => {
        if (graph.length === 0) return {};
        return graph.reduce((acc, item) => {
            const countryId = item.country_id;
            acc[countryId] = item.count
            return acc;
        }, {})
    }, [graph]);

    const getPostion = (longlatit, type) => {
        let position = longlatit;
        if (type === "country") {
            let countryName = countryLatLong.filter((item) => item.country_code === longlatit)
            position = countryName[0]?.country
        }
        return position
    }

    const getMapAvrageCount = (count, type) => {
        let totalCount = 0
        const countryCount = prop.mapCountryCode.map((item) => totalCount += item.count)
        const low = Math.round(totalCount / 1)
        const medium = Math.round(totalCount / 2)
        const high = Math.round(totalCount / 3)
        let colorCode = "#badaff"
        let range = "Low"
        if (count >= high) {
            colorCode = "#0049b4"
            range = "High"
        }
        else if (count >= medium) {
            colorCode = "#0168ff"
            range = "Medium"
        }
        else if (count >= low) {
            colorCode = "#badaff"
            range = "Low"
        }
        return type === "color" ? colorCode : range
    }


    const CustomTooltip = ({ countryId, count }: { countryId: any, count: any }) => {
        return (
            <div style={{ padding: '8px', color: '#fff', borderRadius: '5px' }}>
                <strong>{prop.tabType} Country :</strong> {getPostion(countryId, "country")}<br />
                <strong>{prop.tabType} Count :</strong> {count}<br />
                <strong>{prop.tabType} Range :</strong> {getMapAvrageCount(count, 'range')}
            </div>
        );
    };

    


    return (
        <App rtl={false}>
            <GlobalCMAPStyles />

            <MapContainer
                center={centreLocation}
                zoom={3}
                attributionControl={false}
                zoomControl={false}
                style={{ height: '350px', marginBottom: '10px' }}
            >
                <TileLayer
                    opacity={1.0}
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    
                />
                {Object.entries(countryCounts).map(([countryId, count]) => (
                    <>
                        <CircleMarker
                            pathOptions={{
                                color: getMapAvrageCount(count, 'color'), fillColor: getMapAvrageCount(count, 'color'),
                                fillOpacity: 0.6, weight: 5
                            }}
                            center={getCountryLongLatit(countryId)}
                            radius={15}>
                            <Tooltip> <CustomTooltip countryId={countryId} count={count} /></Tooltip>
                        </CircleMarker>


                    </>))}
            </MapContainer>
        </App>
    )
}

export default memo(MapContent);



