// Regular expressions for IPv4 and IPv6 addresses
//old
// export const IPV4REGEX = /^(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])$/;
// export const IPV6REGEX = /^(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}(([0-9]{1,3}\.){3,3}[0-9]{1,3})|([0-9a-fA-F]{1,4}:){1,4}:(([0-9]{1,3}\.){3,3}[0-9]{1,3}))$/;

//new
export const IPV4REGEX = /^(25[0-5]|2[0-4]\d|1\d{2}|\d{1,2})(\.(25[0-5]|2[0-4]\d|1\d{2}|\d{1,2})){3}$/
export const IPV6REGEX = /^([\da-fA-F]{1,4}:){7}[\da-fA-F]{1,4}$/

export const nokiaUserEmailDomains = ["nokia.com", "nokia-bell-labs.com", "nokia-sbell.com"];

export const userRadioOptions = [
    { label: 'User', value: 'user' },
    { label: 'Machine', value: 'machine' },
    { label: 'Whitelisted application', value: 'whitelisted_client' }
];

export const userTypeOptions = [
    { label: 'Nokia', value: 'nokia' },
    { label: 'External', value: 'external' }
];

export const machineIPTypeOptions = [
    { label: 'IP address', value: 'ipAddress' },
    { label: 'IP range', value: 'ipRange' }
]

//  old OOT roles
//export const ootRoles = [{ "id": "approver", "value": "Approver" },{ "id": "budgetary_approver", "value": "Budgetary approver" },{ "id": "commercial_master", "value": "Commercial master" },{ "id": "delivery", "value": "Delivery" },{ "id": "delivery_admin", "value": "Delivery Admin" },{ "id": "external_user", "value": "External user" },{ "id": "lawyer", "value": "Lawyer" },{ "id": "legal_master", "value": "Legal Master" },{ "id": "product_master", "value": "Product master" },{ "id": "sales_owner", "value": "Sales Owner" },{ "id": "owner", "value": "Technical Owner" },{ "id": "user_management_", "value": "User management admin" }]
//export const distributorOotRoles =  [{ "id": "approver", "value": "Approver" }, { "id": "budgetary_approver", "value": "Budgetary approver" }, { "id": "external_user", "value": "External user" }, { "id": "owner", "value": "Technical Owner" }, { "id": "sales_owner", "value": "Sales Owner" }]


//new OOT roles
export const ootRoles = [
    {
        "id": "administrator",
        "value": "Administrator"
    },
    {
        "id": "authenticated",
        "value": "Authenticated user"
    },
    {
        "id": "super_user",
        "value": "Super user"
    },
    {
        "id": "api_consumer",
        "value": "API consumer"
    },
    {
        "id": "infocenter_content_manager",
        "value": "Infocenter Content Manager"
    },
    {
        "id": "developer",
        "value": "Developer"
    },
    {
        "id": "helpdesk_agent",
        "value": "Helpdesk agent"
    },
    {
        "id": "api_export_data_consumer",
        "value": "API Export Data Consumer"
    },
    {
        "id": "offer_master",
        "value": "Offer master"
    },
    {
        "id": "approver",
        "value": "Approver"
    },
    {
        "id": "budgetary_approver",
        "value": "Budgetary approver"
    },
    {
        "id": "commercial_master",
        "value": "Commercial master"
    },
    {
        "id": "delivery",
        "value": "Delivery"
    },
    {
        "id": "delivery_admin",
        "value": "Delivery Admin"
    },
    {
        "id": "external_user",
        "value": "External user"
    },
    {
        "id": "lawyer",
        "value": "Lawyer"
    },
    {
        "id": "legal_master",
        "value": "Legal Master"
    },
    {
        "id": "product_master",
        "value": "Product master"
    },
    {
        "id": "sales_owner",
        "value": "Sales Owner"
    },
    {
        "id": "owner",
        "value": "Technical Owner"
    },
    {
        "id": "user_management_",
        "value": "User management admin"
    }
]
export const distributorOotRoles = [{ "id": "external_user", "value": "External user" }, { "id": "owner", "value": "Technical Owner" }, { "id": "sales_owner", "value": "Sales Owner" }]
export const approverRoles = [{ "id": "bd", "value": "Business Development" }, { "id": "lc", "value": "Legal counsel" }, { "id": "fc", "value": "Commerical Operation" }, { "id": "pm", "value": "Product Management" }, { "id": "dm", "value": "Delivery Management" }, { "id": "om", "value": "Order Management" }];

export const modifyData = (dataList, idKey, valueKey, selected = false) => {
    return dataList.map(item => ({
        id: item[idKey],
        value: Array.isArray(valueKey)
            ? valueKey.map(key => item[key]).join(' | ') // Combine values with a space or other separator
            : item[valueKey],
        select: selected
    }));
};

export const getRequestedInfo = (persona) => {
    // Set requested_info based on persona
    let requested_info = "";
    switch (persona) {
        case "nokia":
        case "partner":
        case "direct_customer":
        case "direct_reseller":
        case "distributor":
            requested_info = "sell_to_party";
            break;
        case "indirect_end_customer":
            requested_info = "end_customer";
            break;
        case "merchant":
            requested_info = "merchant";
            break;
        case "supplier":
            requested_info = "supplier";
            break;
        case "indirect_reseller":
            requested_info = "indirect_reseller";
            break;
        default:
            console.warn("Unknown persona type");
            return;
    }
    return requested_info;
}

export const getAccountDropdownLabel = (selectedOrgType, orgList) => {
    let label = "";
    // let placeholder = "";

    if (selectedOrgType && orgList) {
        let selectedOrg = orgList.find(org => org.id === selectedOrgType);
        label = selectedOrg ? selectedOrg.value.charAt(0).toUpperCase() + selectedOrg.value.slice(1) : selectedOrgType.charAt(0).toUpperCase() + selectedOrgType.slice(1);
        // placeholder = `Select ${selectedOrg.value.charAt(0).toLowerCase() + selectedOrg.value.slice(1)}`;
    } else {
        label = "Account";

    }

    return label;
};

export function replaceUnderscoreWithSpace(text) {
    return text && text.includes("_") ? text.replace(/_/g, " ") : text;
}


