import React from "react";
import classes from '../../Style/MarketplaceFilter.module.css';
import { RootState } from "Store/mainStore";
import { useSelector, useDispatch } from "react-redux";
import { setLoader, setProducts, setFilters, setProductTypes, setProductSubTypes } from "Store/contentState/marketplaceSlice";
import { resetPaginationsProps } from "Store/contentState/paginationSlice";
import SelectBox from "Pages/subscription/Custom/SelectBox";
import _ from "lodash";
import SearchBox from "../../common/SearchBox";
import { Label } from '@nokia-csf-uxr/ccfk'
import Toggle from "../../common/Toggle";
import { SORT_BY_DROP_VALUES } from "../../Utils/commons";
import MoreButtonDemo from "Pages/marketplace/common/MoreButtonVertical";

const TopFilters: React.FC = () => {
    const marketplaceSelector = useSelector((state: RootState) => state.marketplace);
    const showDeliverableFlag = marketplaceSelector.filters?.selection?.onlyDeliverable;
    const dispatch = useDispatch();


    const handleSortbySelection = (_productSortyby) => {
        dispatch(setFilters({ filterName: 'productSortyby', value: _productSortyby }));
        dispatch(resetPaginationsProps());
    }

    const handleToggleSwitch = (check) => {
        dispatch(setFilters({ filterName: 'onlyDeliverable', value: check }));
        dispatch(resetPaginationsProps());
    }


    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'space-between', padding: '.9rem' }}>
                <div style={{ display: 'inline-flex', flex: '0 1 60%' }}>
                    <div style={{ width: '100%' }}>
                        <SearchBox placeholder="Search an item" />
                    </div>
                    <div className={classes.verticalDivider} style={{ margin: '0rem 1.5rem' }}></div>
                    <div style={{ width: '100%', paddingTop: '5px' }}>
                        <Toggle name={"Show only deliverable"} togglecheck={showDeliverableFlag} onUpdateSelection={handleToggleSwitch} />
                    </div>

                </div>

                <div className={classes.sortbyBlock}>
                    <div className={classes.verticalDivider} style={{ margin: '0rem 1.5rem' }}></div>
                    <Label className={classes.labelText}>Sort by:</Label>
                    <div className={classes.filterSelectionSortby}>
                        <SelectBox
                            // disabled={marketplaceSelector.selectedDeviceID !== '' ? true : false }
                            placeholder="Sort By"
                            maxWidth={true}
                            caseInsentiveMatching={true}
                            selectedItem={marketplaceSelector?.filters?.selection?.productSortyby}
                            data={SORT_BY_DROP_VALUES}
                            onUpdateSelection={handleSortbySelection}
                            isLoading={marketplaceSelector.isProductTypeLoading} />
                    </div>
                </div>
            </div>
            <div style={{ borderBottom: '1px solid #0000001F' }}>
            </div>
        </>

    )
}

export default TopFilters;