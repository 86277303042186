import React from "react";
import { Typography } from "@nokia-csf-uxr/ccfk";
import noProductsImage from "../../../Assets/Icons/product_not_found.svg";


const EmptyProductList = () => {
    return (
        <div style={{ marginTop: '4rem', minHeight: '500px' }}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <img src={noProductsImage} alt="no-items-image" style={{ height: '200px', maxWidth: "100%" }} />
            </div>
            <div style={{ textAlign: 'center', marginTop: '5px' }}>
                <h5 style={{ fontSize: '16px', fontWeight: 'bold', marginBlockStart: '0', marginBlockEnd: '0', marginBottom: '.5rem', textAlign: 'center' }}>Sorry, no products found</h5>
                <Typography>There are no matching products for the selected criteria</Typography>
            </div>
        </div>
    );
};

export default EmptyProductList;