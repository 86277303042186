import { useEffect, useState } from 'react';

const useImageRender = (productImage, imageUrl, defaultImage) => {
  const [productImageSrc, setProductImageSrc] = useState(defaultImage);

  useEffect(() => {
    
    // Set initial image source based on priority
    if (productImage) {
      setProductImageSrc(productImage);
    } else if (imageUrl) {
      setProductImageSrc(imageUrl);
    } else {
      setProductImageSrc(defaultImage);
    }
  }, [productImage, imageUrl, defaultImage]);

  const handleImageError = () => {
    if (productImageSrc === productImage && imageUrl) {
      setProductImageSrc(imageUrl);
    } else {
      setProductImageSrc(defaultImage);
    }
  };

  return [productImageSrc, handleImageError];
};

export default useImageRender;


