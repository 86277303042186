import React, {useState,useEffect}  from "react";
import { RootState } from "Store/mainStore";
import { useSelector } from "react-redux";
import { Spinner } from '@nokia-csf-uxr/ccfk';
import {TextInputLabelContent} from "@nokia-csf-uxr/ccfk/TextInput";

interface DataList {
    data: Array<any>,
    active_step: string,
    updateDispatchStore?: Function
    isDisabled:boolean,
    errorMessageText?: Function,
    stepKey?: string,
    compositeField? : string
}


const SpinnerField = (props:DataList) => {
    const dynamicSelector = useSelector((state: RootState) => state.dynamic);
    const label = props.data[1].required ?  props.data[1].label + ' *' : props.data[1].label
    const [errorDisabled, setErrorDisabled] = useState(props.data[1].required);


    useEffect(() => {
        if(props.data[1].required){
            if(props.compositeField && props.stepKey){
                if(dynamicSelector.data.element[props.active_step][props.compositeField]){
                    if(dynamicSelector.data.element[props.active_step][props.compositeField][props.stepKey][props.data[0]] !== ""){
                        setErrorDisabled(false)
                    }
                    else{
                        setErrorDisabled(true)
                    }
                }
            }
            else{
            if(dynamicSelector.data.element[props.active_step]){
                if(dynamicSelector.data.element[props.active_step][props.data[0]] !== ""){
                    setErrorDisabled(false)
                }
                else{
                    setErrorDisabled(true)
                }
            }
            }
        }
    },[dynamicSelector.data.element[props.active_step]])

    const getReturnValue = (item) => {
        let value = 0
        if(props.compositeField && props.stepKey){}
        if(dynamicSelector.data.element[props.active_step][props.compositeField]){
            if(dynamicSelector.data.element[props.active_step][props.compositeField][props.stepKey][item]){
                value = dynamicSelector.data.element[props.active_step][props.compositeField][props.stepKey][item].key ? dynamicSelector.data.element[props.active_step][props.compositeField][props.stepKey][item].key : props.data[1].min
            }
        }
        else{
        if(dynamicSelector.data.element[props.active_step]){
            if(dynamicSelector.data.element[props.active_step][item]){
                value = dynamicSelector.data.element[props.active_step][item].key ? dynamicSelector.data.element[props.active_step][item].key : props.data[1].min
            }
        }
        }
        return value
    }

    const getKeyvalueData = (dataSet,selectkey) => {
        let selectKeyValue = {}
        selectKeyValue['key'] = selectkey
        selectKeyValue['value'] = selectkey
        if(props.compositeField && props.stepKey){
            props.updateDispatchStore(props.compositeField,props.stepKey,dataSet,selectKeyValue);
        }
        else{
            props.updateDispatchStore(dataSet,selectKeyValue);
        }
    }

    const checkOnInput = (event) => {
        if(isNaN(event.target.value)){
            event.target.value = ""
            return true
        }
        else{
        if(props.data[1].required){
            if(event.target.value === ""){
                if(props.compositeField && props.stepKey){
                    props.updateDispatchStore(props.compositeField,props.stepKey,props.data[0],"");
                }
                else{
                    props.updateDispatchStore(props.data[0],"");
                }
            }
            else{
                getKeyvalueData(props.data[0],event.target.value)
            }
        }
        }
    }


    return(
        <>
        <div style={{marginTop:'10px'}}>
         <TextInputLabelContent>
                                {label}
            </TextInputLabelContent>
            {props.compositeField && props.stepKey &&  (<Spinner
                variant='outlined'
                onInput={(e) => {
                    checkOnInput(e)
                }}
                id={props.data[1].id}
                value={getReturnValue(props.data[0])}
                disabled={props.isDisabled}
                min={props.data[1].min}
                max={props.data[1].max}
                error={errorDisabled}
                inputMode='numeric'
                incrementButtonProps={{ 'aria-label': `Value ${dynamicSelector.data.element[props.active_step][props.compositeField] ? dynamicSelector.data.element[props.active_step][props.compositeField][props.stepKey][props.data[0]] : 1}. Increment` }}
                decrementButtonProps={{ 'aria-label': `Value ${dynamicSelector.data.element[props.active_step][props.compositeField] ? dynamicSelector.data.element[props.active_step][props.compositeField][props.stepKey][props.data[0]] : 1}. Decrement` }}
                onChange={(e) => {
                    getKeyvalueData(props.data[0],e.value);
                }}
            />)}
              {!props.compositeField && !props.stepKey &&  (<Spinner
                variant='outlined'
                onInput={(e) => {
                    checkOnInput(e)
                }}
                id={props.data[1].id}
                value={getReturnValue(props.data[0])}
                disabled={props.isDisabled}
                min={props.data[1].min}
                max={props.data[1].max}
                error={errorDisabled}
                inputMode='numeric'
                incrementButtonProps={{ 'aria-label': `Value ${dynamicSelector.data.element[props.active_step] ? dynamicSelector.data.element[props.active_step][props.data[0]] : 1}. Increment` }}
                decrementButtonProps={{ 'aria-label': `Value ${dynamicSelector.data.element[props.active_step] ? dynamicSelector.data.element[props.active_step][props.data[0]] : 1}. Decrement` }}
                onChange={(e) => {
                    getKeyvalueData(props.data[0],e.value);
                }}
            />)}
            {(errorDisabled ? <>{props.errorMessageText(props.data[1].label)}</> : '')}
            </div>
        </>
    )
}

export default SpinnerField;

