import { IconButton, Label, TextInput } from '@nokia-csf-uxr/ccfk';
import AddIcon from "@nokia-csf-uxr/ccfk-assets/legacy/AddIcon";
import RemoveIcon from "@nokia-csf-uxr/ccfk-assets/legacy/RemoveIcon";
import Slider, { SliderLabel } from "@nokia-csf-uxr/ccfk/Slider";
import { roundCount } from 'Utils';
import PropTypes from "prop-types";
import React from "react";

const SliderComponent = ({
  sliderId = "",
  sliderStateValue = 1,
  minDuration = "1",
  maxDuration = "10",
  stepBy = 1,
  errorValidation = {},
  setProperty,
  handleChange = (e) => { }
}) => {
  // const selectItemRef = useRef(setProperty.sliceProperty);
  const getAriaLabel = () => {
    if (sliderStateValue <= +roundCount(minDuration)) {
      return `Reach minimum value`;
    } else if (sliderStateValue >= +roundCount(maxDuration)) {
      return `Reach maximum value`;
    }
    console.log('test', sliderStateValue);
    return sliderStateValue;
  };

  const frontIcon = (
    <>
      <IconButton
        disabled={(sliderStateValue) >= roundCount(maxDuration)}
        aria-label="Increase value"
        onClick={() => handleLabelClick(roundCount(sliderStateValue) + roundCount(stepBy))}
      >
        <AddIcon />
      </IconButton>
      <span style={{ lineHeight: "2.5em" }}>{maxDuration}</span>
    </>
  );

  const rearIcon = (
    <>
      <span style={{ lineHeight: "2.5em" }}>{minDuration}</span>
      <IconButton
        disabled={roundCount(sliderStateValue) <= roundCount(minDuration)}
        aria-label="Decrease value"
        onClick={() => {
          return handleLabelClick(roundCount(sliderStateValue) - roundCount(stepBy))
        }}
      >
        <RemoveIcon />
      </IconButton>
    </>
  );

  const renderMinLabel = (labelProps) => (
    <SliderLabel label={rearIcon} {...labelProps} />
  );
  const renderMaxLabel = (labelProps) => (
    <SliderLabel label={frontIcon} {...labelProps} />
  );
  const handleLabelClick = (value) => {
    let normalizedValue = value;
    if (normalizedValue !== "" && normalizedValue < roundCount(minDuration))
      normalizedValue = minDuration;
    if (normalizedValue !== "" && normalizedValue > roundCount(maxDuration))
      normalizedValue = roundCount(maxDuration);

    handleChange({ value: normalizedValue });
  };
  return (
    <Label htmlFor="selectitem-component-input" maxWidth>
      <Slider
        stepBy={roundCount(stepBy)}
        isTicker={true}
        value={sliderStateValue}
        inputMode='decimal'
        inputProps={{ value: sliderStateValue }}
        onChange={(e: { value: number }) => {

          if (e.value > roundCount(maxDuration)) {
            handleChange({ value: roundCount(maxDuration) })
            return;
          }
          handleChange(e)
        }}
        min={roundCount(minDuration)}
        max={roundCount(maxDuration)}
        thumbProps={{
          minThumbProps: {
            role: "slider",
            "aria-valuemin": minDuration,
            "aria-valuenow": sliderStateValue,
            "aria-valuemax": parseInt(maxDuration),
            "aria-valuetext": getAriaLabel(),
            "aria-labelledby": "rangeselection-label-id",
            "aria-orientation": "horizontal",
          },
        }}
        style={{
          width: "70%",
          marginTop: "2%",
        }}
        renderMinLabel={renderMinLabel}
        renderMaxLabel={renderMaxLabel}
      />

      <TextInput
        value={sliderStateValue.toString()}
        error={sliderStateValue.toString() === ''}
        id={sliderId}
        errorMessage={sliderStateValue.toString() === '' && "Invalid Area"}
        style={{
          margin: "1%",
          width: "20%",
          padding: "5px",
        }}
        onChange={(event) => {  
          if ((!event.target.value) || (isNaN(roundCount(event.target.value)))) {
            //if the value is not set || if the value is other than number
            handleLabelClick(minDuration);
            return;
          }
          //   let calcVal = ((roundCount(stepBy) % 1 !== 0)) ? roundCount(event.target.value) : roundCount(event.target.value);
          let calcVal = roundCount(event.target.value);
          let sliderValue = calcVal;
          
          console.log('chang', sliderValue, typeof sliderValue)
          handleLabelClick(sliderValue);
        }}
      ></TextInput>
    </Label>
  );
}
export default SliderComponent;
SliderComponent.propTypes = {
  sliderId: PropTypes.string,
  handleChange: PropTypes.func,
  stepBy: PropTypes.number,
  sliderStateValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  minDuration: PropTypes.string,
  maxDuration: PropTypes.string,
  errorValidation: PropTypes.object,
  setProperty: PropTypes.object
}
