import React, { useState, useEffect, useCallback, useRef } from 'react';
import MultiSelectSmartList, { MultiSelectSmartListHeader, MultiSelectSmartCheckboxList, MultiSelectSmartListCheckbox, MultiSelectSmartListDivider } from '@nokia-csf-uxr/ccfk/MultiSelectSmartList';
import _cloneDeep from 'lodash/cloneDeep';
import OOT from 'Constants/services/oot';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'Store/mainStore';
import Label from '@nokia-csf-uxr/ccfk/Label';
import userStyles from '../Style/UserManagement.module.css'
import { setUserInputFields } from 'Store/contentState/userManagementSlice';
import { getRequestedInfo } from '../utils/constants';
import FilterField, { FilterFieldIcon } from "@nokia-csf-uxr/ccfk/FilterField";
import { setProp } from 'Store/Actions';


const ENTER_KEY = 'Enter';
const SPACE_KEY = ' ';



const isSelectionKeyPressed = key => key && (key === ENTER_KEY || key === SPACE_KEY);


const AccountListSmartSelect = (prop) => {
  const dispatch = useDispatch();
  const filter = useSelector((state: RootState) => state.userManagement.hierachyFilterQuery);
  const selectedOrgType = useSelector((state: RootState) => state.userManagement.userData.orgType);
  const selectedAccountNumbers = useSelector((state: RootState) => state.userManagement.userData.oneStarNumber);

  const markets = useSelector((state: RootState) => state.userManagement.userData.markets)

  const [sortedData, setSortedData] = useState([]);
  const [accountSearchText, setAccountSearchText] = useState('');
  const [selectAllSelected, setSelectAllSelected] = useState(false);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [isFetching, setIsFetching] = useState(false);
  const [offset, setOffset] = useState(0);
  // const [accountsIdsToSearch, setAccountsIdsToSearch] = useState(accountOneStarNumbers);
  const limit = 10;

  const listRef = useRef(null);
  const inputRef = useRef(null);
  const selectAllSelectedRef = useRef(selectAllSelected);



  const handleSearch = (e) => {
    if (e.key === 'Enter') {
      let updatedFilter = filter;

      // Append only if `accountSearchText` is not empty
      if (accountSearchText.trim()) {
        updatedFilter += ` AND (name like '%${accountSearchText}%')`;
      }

      dispatch(setProp({ slice: 'userManagement', key: 'hierachyFilterQuery', value: updatedFilter }));
    }
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setAccountSearchText(value);
    // Update filter only if `value` is not empty
    if (value === "") {
      const nameLikeRegex = /AND \(name like '%.*?%'\)/;
      let updatedFilter = filter.replace(nameLikeRegex, '').trim();
      dispatch(setProp({ slice: 'userManagement', key: 'hierachyFilterQuery', value: updatedFilter }));
    }
  };

  const handleClear = () => {
    setAccountSearchText(''); // Clear the search text
    const nameLikeRegex = /AND \(name like '%.*?%'\)/;
    let  updatedFilter = filter.replace(nameLikeRegex, '').trim();
    dispatch(setProp({ slice: 'userManagement', key: 'hierachyFilterQuery', value: updatedFilter }));
    resetScroll()
  };



  const isFilterEmpty = (filter) => !filter || Object.keys(filter).length === 0;

  const sortDataBySelection = (data) => {
    const selectedData = [];
    const unSelectedData = [];
    data.forEach((item) => (item.selected ? selectedData.push(item) : unSelectedData.push(item)));
    return selectedData.concat(unSelectedData);
  };

  const updateSelectedAccountDetails = (data, selectAllSelected = false) => {

    if (selectAllSelected) {
      // If selectAllSelected is true, clear the fields
      dispatch(setUserInputFields({ field: "threeStarNumber", value: [] }));
      dispatch(setUserInputFields({ field: "business_name", value: [] }));
      dispatch(setUserInputFields({ field: "oneStarNumber", value: [] }));
    } else {
      // Otherwise, process the selected accounts
      const selectedAccounts = data.filter((item) => item.selected);


      const accountThreeStarNumbers = selectedAccounts.map((item) => item.parentAccountNumber);

      const accountBusinessNames = selectedAccounts.map((item) => item.businessName);
      const accountOneStarNumbers = selectedAccounts.map((item) => item.id);

      dispatch(setUserInputFields({ field: "threeStarNumber", value: accountThreeStarNumbers }));
      dispatch(setUserInputFields({ field: "business_name", value: accountBusinessNames }));
      dispatch(setUserInputFields({ field: "oneStarNumber", value: accountOneStarNumbers }));
    }
  };

  const fetchAccountList = async (currentOffset = offset) => {
    if (isFetching) return;
    setIsFetching(true);
    try {
      const requested_info = getRequestedInfo(selectedOrgType[0]);
      const response = await OOT.getAccountsForInternalExternal(filter, limit, currentOffset, requested_info, []);
      if (response.status) {
        const data = response.data;
        if (data && data.length > 0) {
          const newAccountList = data.map((item) => ({
            id: item.customer_account_number,
            label: item.name,
            businessName: item.business_name,
            parentAccountNumber: item.parent_account_number,
            selected: selectAllSelectedRef.current
              ? true
              : prop.mode === 'edit'
                ? selectedAccountNumbers.includes(item.customer_account_number)
                : false,
            disabled: false,
          }));
          setSortedData((prevData) => [...prevData, ...newAccountList]);
          //setSelectAllSelected(true); // Set "Select All" to true if all items are selected by default
          // console.log(currentOffset)
          setOffset((prevOffset) => prevOffset + limit);

          if (newAccountList.length < limit) setHasMoreData(false); // No more data
        } else {
          setHasMoreData(false); // Stop fetching if no data returned
        }
      }
    } catch (error) {
      console.error("Error fetching account list:", error);
    } finally {
      setIsFetching(false);
    }
  };

  const handleScroll = () => { 
    if (!listRef.current || isFetching || !hasMoreData) return;

    const { scrollTop, clientHeight, scrollHeight } = listRef.current;


    if (scrollTop + clientHeight >= scrollHeight - 100) {
      fetchAccountList(); // Fetch more records when close to bottom
    }
  };

  const handleOnChange = (id) => {
    const updatedData = sortedData.map((item) =>
      item.id === id ? { ...item, selected: !item.selected } : item
    );
    setSortedData(updatedData);
    setSelectAllSelected(updatedData.every((item) => item.selected));
    updateSelectedAccountDetails(updatedData);
  };


  const resetScroll = () => {
    // Reset pagination or offset to the initial state
    setOffset(0);
  
    // Optionally reset the 'hasMoreData' flag if necessary
    setHasMoreData(true);
  
    // Optionally scroll the list back to the top
    if (listRef.current) {
      listRef.current.scrollTop = 0;
    }
  };

  const handleSelectAllOnChange = (e) => {
    const updatedData = sortedData.map((item) => ({
      ...item,
      selected: e.target.checked,
    }));

    setSortedData(updatedData);
    setSelectAllSelected(e.target.checked);
    updateSelectedAccountDetails(updatedData, e.target.checked);
  };

  useEffect(() => {
    selectAllSelectedRef.current = selectAllSelected;
  }, [selectAllSelected]);



  useEffect(() => {
    if (!isFilterEmpty(filter)) {
      // Reset pagination and data state
      setOffset(0); // Ensure this updates first
      // setHasMoreData(true);
      setSortedData([]);
      // Trigger API call after resetting state
      fetchAccountList(0);
    } else if (sortedData.length > 0 || offset > 0) {
      setSortedData([]);
      setOffset(0);
      dispatch(setUserInputFields({ field: "threeStarNumber", value: [] }));
      dispatch(setUserInputFields({ field: "business_name", value: [] }));
      dispatch(setUserInputFields({ field: "oneStarNumber", value: [] }));
    }
  }, [filter, markets]);

  useEffect(() => {
    const currentRef = listRef.current;
    if (currentRef) currentRef.addEventListener("scroll", handleScroll);
    return () => currentRef?.removeEventListener("scroll", handleScroll);
  }, [listRef, isFetching, hasMoreData]);

  return (
    <>
      {sortedData.length > 0 && (
        <div>
          <Label className={userStyles.userLabel}>
            Account
            {/* <span style={{ color: "#C91514" }}>*</span> */}
          </Label>
          <FilterField
            value={accountSearchText}
            onChange={handleChange}
            variant="outlined"
            textInputProps={{
              placeholder: "Search",
              showClearButton: true,
              clearButtonProps: {
                onClick: handleClear,
              },
              inputProps: {
                'aria-label': 'Search',
                onKeyDown: handleSearch,
                ref: inputRef, // Ref applied directly to the input element

              },
            }}
            renderIcon={<FilterFieldIcon title="click enter to search" />}

          />
          <MultiSelectSmartList ref={listRef} style={{ height: "400px", overflowY: "auto" }}>
            <MultiSelectSmartListHeader
              title="Select all"
              selectAllCheckbox={{
                isEnabled: true,
                checked: selectAllSelected,
                indeterminate: sortedData.some((item) => item.selected),
                onChange: (e) => handleSelectAllOnChange(e),
              }}
            />
            <MultiSelectSmartCheckboxList>
              {sortedData.map((data, index) => (
                <React.Fragment key={`listItem-${index}`}>
                  <MultiSelectSmartListCheckbox
                    label={`${data.label} | ${data.id} | ${data.parentAccountNumber}`}
                    selected={data.selected}
                    disabled={data.disabled}
                    onChange={() => handleOnChange(data.id)}
                  />
                  {index + 1 < sortedData.length &&
                    !sortedData[index + 1].selected &&
                    data.selected && <MultiSelectSmartListDivider />}
                </React.Fragment>
              ))}
            </MultiSelectSmartCheckboxList>
          </MultiSelectSmartList>
        </div>
      )}
    </>
  );
};

// const AccountListSmartSelect = (prop) => {
//   const dispatch = useDispatch();

//   const filter = useSelector((state: RootState) => state.userManagement.hierachyFilterQuery);
//   const selectedOrgType = useSelector((state: RootState) => state.userManagement.userData.orgType);
//   const selectedAccountNumbers = useSelector((state: RootState) => state.userManagement.userData.oneStarNumber);
//   const markets = useSelector((state: RootState) => state.userManagement.userData.markets);

//   const [sortedData, setSortedData] = useState([]);
//   const [selectAllSelected, setSelectAllSelected] = useState(false);
//   const [isFetching, setIsFetching] = useState(false);
//   const [offset, setOffset] = useState(0);
//   const [hasMoreData, setHasMoreData] = useState(true);
//   const limit = 10;

//   const listRef = useRef(null);

//   const isFilterEmpty = (filter) => !filter || Object.keys(filter).length === 0;

//   // Helper to sort selected and unselected data
//   const sortDataBySelection = (data) => {
//     const selectedData = [];
//     const unselectedData = [];
//     data.forEach((item) => (item.selected ? selectedData.push(item) : unselectedData.push(item)));
//     return selectedData.concat(unselectedData);
//   };

//   const updateSelectedAccountDetails = (data) => {
//     const selectedAccounts = data.filter((item) => item.selected);
//     const accountThreeStarNumbers = selectedAccounts.map((item) => item.parentAccountNumber);
//     const accountBusinessNames = selectedAccounts.map((item) => item.businessName);
//     const accountOneStarNumbers = selectedAccounts.map((item) => item.id);

//     dispatch(setUserInputFields({ field: "threeStarNumber", value: accountThreeStarNumbers }));
//     dispatch(setUserInputFields({ field: "business_name", value: accountBusinessNames }));
//     dispatch(setUserInputFields({ field: "oneStarNumber", value: accountOneStarNumbers }));
//   };

//   const fetchAccountList = async (currentOffset = offset) => {
//     if (isFetching || !hasMoreData) return;

//     setIsFetching(true);

//     try {
//       const requested_info = getRequestedInfo(selectedOrgType[0]);
//       let response;

//       if (selectedAccountNumbers && selectedAccountNumbers.length > 0) {
//         // Fetch selected accounts
//         response = await OOT.getAccountsForInternalExternal(filter, limit, currentOffset, requested_info, selectedAccountNumbers);
//       } else {
//         // Fetch all accounts
//         response = await OOT.getAccountsForInternalExternal(filter, limit, currentOffset, requested_info, []);
//       }

//       if (response.status) {
//         const data = response.data;

//         if (data && data.length > 0) {
//           const newAccountList = data.map((item) => ({
//             id: item.customer_account_number,
//             label: item.name,
//             businessName: item.business_name,
//             parentAccountNumber: item.parent_account_number,
//             selected: prop.mode === 'edit' ? selectedAccountNumbers.includes(item.customer_account_number) : false,
//             disabled: false,
//           }));

//           setSortedData((prevData) => sortDataBySelection([...prevData, ...newAccountList]));
//           setOffset((prevOffset) => prevOffset + limit);

//           if (newAccountList.length < limit) setHasMoreData(false);
//         } else {
//           setHasMoreData(false);
//         }
//       }
//     } catch (error) {
//       console.error("Error fetching account list:", error);
//     } finally {
//       setIsFetching(false);
//     }
//   };

//   const handleSelectAllOnChange = () => {
//     const updatedData = sortedData.map((item) => ({
//       ...item,
//       selected: !selectAllSelected,
//     }));
//     setSortedData(sortDataBySelection(updatedData));
//     setSelectAllSelected(!selectAllSelected);
//     updateSelectedAccountDetails(updatedData);
//   };

//   const handleOnChange = (id) => {
//     const updatedData = sortedData.map((item) =>
//       item.id === id ? { ...item, selected: !item.selected } : item
//     );
//     setSortedData(sortDataBySelection(updatedData));
//     setSelectAllSelected(updatedData.every((item) => item.selected));
//     updateSelectedAccountDetails(updatedData);
//   };

//   const handleScroll = () => {
//     if (!listRef.current || isFetching || !hasMoreData) return;

//     const { scrollTop, clientHeight, scrollHeight } = listRef.current;

//     if (scrollTop + clientHeight >= scrollHeight - 100) {
//       fetchAccountList(); // Fetch more records when close to bottom
//     }
//   };

//   useEffect(() => {
//     if (!isFilterEmpty(filter)) {
//       console.log("Filter applied, fetching data...");
//       setOffset(0); // Reset pagination
//       setHasMoreData(true); // Assume more data available initially
//       fetchAccountList(0); // Fetch new data
//     } else if (sortedData.length > 0) {
//       console.log("Filter cleared, resetting data...");
//       // Reset state only if there was previously loaded data
//       setSortedData([]);
//       setOffset(0);
//       setHasMoreData(false);
//       dispatch(setUserInputFields({ field: "threeStarNumber", value: [] }));
//       dispatch(setUserInputFields({ field: "business_name", value: [] }));
//       dispatch(setUserInputFields({ field: "oneStarNumber", value: [] }));
//     }
//   }, [filter, markets]);

//   useEffect(() => {
//     const currentRef = listRef.current;
//     if (currentRef) currentRef.addEventListener("scroll", handleScroll);
//     return () => currentRef?.removeEventListener("scroll", handleScroll);
//   }, [listRef, isFetching, hasMoreData]);

//   useEffect(() => {
//     if (prop.mode === 'edit' && selectedAccountNumbers && selectedAccountNumbers.length > 0) {
//       console.log("Edit mode: fetching selected accounts...");
//       fetchAccountList(0);
//     } else if (markets && markets['level4'].length > 0) {
//       console.log("Edit mode: no accounts but markets are defined...");
//       setSelectAllSelected(true);
//     }
//   }, [prop.mode, selectedAccountNumbers, markets]);

//   return (
//     <div style={{ width: "50%" }}>
//       {sortedData.length > 0 && (
//         <div>
//           <MultiSelectSmartList ref={listRef} style={{ height: "400px", overflowY: "auto" }}>
//             <MultiSelectSmartListHeader
//               title="Select all"
//               selectAllCheckbox={{
//                 isEnabled: true,
//                 checked: selectAllSelected,
//                 indeterminate: sortedData.some((item) => item.selected),
//                 onChange: handleSelectAllOnChange,
//               }}
//             />
//             <MultiSelectSmartCheckboxList>
//               {sortedData.map((data, index) => (
//                 <MultiSelectSmartListCheckbox
//                   key={`listItem-${index}`}
//                   label={data.label}
//                   selected={data.selected}
//                   disabled={data.disabled}
//                   onChange={() => handleOnChange(data.id)}
//                 />
//               ))}
//             </MultiSelectSmartCheckboxList>
//           </MultiSelectSmartList>
//         </div>
//       )}
//     </div>
//   );
// };





export default AccountListSmartSelect;
