import React from "react";
import { Typography } from "@nokia-csf-uxr/ccfk";
import capturePlanEmptyImage from "../../../Assets/Icons/NoData.svg";

const CaptureDialogEmptyPage = () => {
    return (
        <>
            <div style={{ marginTop: '15rem', minHeight: '500px' }}>

                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <img src={capturePlanEmptyImage} alt="no-items-image" style={{ height: '200px', maxWidth: "100%" }} />
                </div>
                <div style={{ textAlign: 'center', marginTop: '5px' }}>
                    <h5 style={{ fontSize: '16px', fontWeight: 'bold', marginBlockStart: '0', marginBlockEnd: '0', marginBottom: '.5rem', textAlign: 'center' }}>Nothing to show</h5>
                    <Typography>Enter valid sCRM ID to proceed capturing the network plan data</Typography>

                </div>


            </div>
        </>
    )
}

export default CaptureDialogEmptyPage;