import React, {useState,useEffect}  from "react";
import { RootState } from "Store/mainStore";
import { useSelector } from "react-redux";
import ConfiguratorSelectBar from "Components/Elements/ConfiguratorSelectBar";
import {TextInputLabelContent} from "@nokia-csf-uxr/ccfk/TextInput";
import { ToggleButtonGroup,Typography } from "@nokia-csf-uxr/ccfk";
import { ButtonText } from "@nokia-csf-uxr/ccfk/Button";
import { ToggleGroupButton } from "@nokia-csf-uxr/ccfk/ToggleButtonGroup";
import { error } from "console";
import Vector from '../../../../Assets/Icons/Vector.png'

interface DataList {
    data: Array<any>,
    optionsData: object,
    active_step: string,
    updateDispatchStore?: Function
    isDisabled: boolean,
    errorMessageText?: Function,
    stepKey: string,
    compositeField:string
}

const  SelectFieldsStep = (props: DataList) => {
    const themeSelector = useSelector((state: RootState) => state.theme);
    const dynamicSelector = useSelector((state: RootState) => state.dynamic);
    const label = props.data[1].required ?  props.data[1].label + ' *' : props.data[1].label
    const sliceProperty = 'element.'+props.active_step+"."+props.compositeField+"."+props.stepKey+"."+props.data[0]
    const [errorDisabled, setErrorDisabled] = useState(props.data[1].required);
    const emptyOptions:any = []



    useEffect(() => {
        if(props.data[1].required){
            if(dynamicSelector.data.element[props.active_step][props.compositeField]){
                if(dynamicSelector.data.element[props.active_step][props.compositeField][props.stepKey][props.data[0]] !== ""){
                    setErrorDisabled(false)
                }
                else{
                    setErrorDisabled(true)
                }
            }
        }
    },[dynamicSelector.data.element[props.active_step][props.compositeField]])


    const getReturnSelectValue = (optionsList, item) => {
        let selectedValue = undefined
        if(dynamicSelector.data.element[props.active_step][props.compositeField]){
           let optionSelect =  optionsList.find( e => e.id === dynamicSelector.data.element[props.active_step][props.compositeField][props.stepKey][item])
           if(optionSelect){
            selectedValue = optionSelect.value
           }
        }
        return selectedValue
    }

    const getSelectValue = (item,value) => {
        let status  = false
         if(dynamicSelector.data.element && Object.entries(dynamicSelector.data.element).length === 0){
            status = false
         }
         else{

            if(dynamicSelector.data.element[props.active_step][props.compositeField]){
                if(dynamicSelector.data.element[props.active_step][props.compositeField][props.stepKey][item] === value){
                    status = true
                }
            }

         }

        return status
    }

    const updateDispatchStoreSelectItems =(value) => {
        props.updateDispatchStore(props.compositeField,props.stepKey,props.data[0], value);

    }

    return (
        <>
            <TextInputLabelContent>
                                {label}
            </TextInputLabelContent>
          
            {Object.entries(props.optionsData).length === 0 ?  emptyOptions : props.optionsData[props.active_step][props.compositeField][props.stepKey][props.data[0]].length  >= 1 ? (
                 <><div> <ConfiguratorSelectBar
                    key={props.data[0].id}
                    isDisabled={props.isDisabled}
                    handOnSelect={(e) => {
                        updateDispatchStoreSelectItems(e);

                        //  dispatch(setProp({ slice: "dynamic", key: `element.${configstepperSelector.data.active_step}.${[data[0]]}`, value: e }));
                    } }
                    isOpen={themeSelector.data.select.isOpen[props.data[0]]}
                    options={Object.entries(props.optionsData).length === 0 ? emptyOptions : props.optionsData[props.active_step][props.compositeField][props.stepKey][props.data[0]]}
                    slice="dynamic"
                    onlyHandOnSelect={true}
                    placeholder={"select " + label}
                    renderValue={getReturnSelectValue(Object.entries(props.optionsData).length === 0 ? emptyOptions : props.optionsData[props.active_step][props.compositeField][props.stepKey][props.data[0]], props.data[0])}
                    optionPath="id"
                    displayKey='value'
                    error={errorDisabled}
                    updateDispatchStoreClear={updateDispatchStoreSelectItems}
                    setProperty={{
                        sliceProperty: sliceProperty,
                        themeProperty: props.data[0],
                    }} />
                </div>{label === "Installation Country" && <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <p>Find the coordinates on the map <img src={Vector} style={{marginBottom: '-5px'}}/></p>
                    </div>}</>
            ) : (
                (<><ToggleButtonGroup>
                    {Object.entries(props.optionsData).length === 0 ?  emptyOptions : props.optionsData[props.active_step][props.compositeField][props.stepKey][props.data[0]]?.map((bTValue, i) => {
                      return (
                        <>
                        <ToggleGroupButton
                          key={i}
                          disabled={props.isDisabled}
                          className={"bharathTest"}
                          isSelected={getSelectValue(props.data[0],bTValue.value)}
                          onChange={(event) => {
                              props.updateDispatchStore(props.data[0],bTValue.value)
                          }}
                        >
                          <ButtonText>{bTValue.value}</ButtonText>
                        </ToggleGroupButton>
                        </>
                      );
                    })}

                  </ToggleButtonGroup>
                  {(errorDisabled ? <>{props.errorMessageText(props.data[1].label)}</> : '')}
                  </>
                )
            )}
        </>
    )
}

export default SelectFieldsStep;