import React,  {useEffect,useState, Fragment }  from "react";
import { RootState } from "Store/mainStore";
import { useSelector } from "react-redux";
import {TextInputLabelContent} from "@nokia-csf-uxr/ccfk/TextInput";
import { RadioButton } from "@nokia-csf-uxr/ccfk";
import { RadioButtonLabelContent } from "@nokia-csf-uxr/ccfk/RadioButton";

interface DataList {
    data: Array<any>,
    optionsData: object,
    active_step: string,
    updateDispatchStore?: Function,
    isDisabled: boolean,
    checkErrorStatus?: Function,
    errorMessageText?: Function,
    stepKey?: string,
    compositeField? : string
}

const RadioButtonField = (props: DataList) => {
    const dynamicSelector = useSelector((state: RootState) => state.dynamic);
    const label = props.data[1].required ?  props.data[1].label + ' *' : props.data[1].label
    const emptyOptions:any = []
    const [errorDisabled, setErrorDisabled] = useState(props.data[1].required);

    useEffect(() => {
        if(props.data[1].required){
            if(props.compositeField && props.stepKey){
                if(dynamicSelector.data.element[props.active_step][props.compositeField]){
                    if(dynamicSelector.data.element[props.active_step][props.compositeField][props.stepKey][props.data[0]] !== ""){
                        setErrorDisabled(false)
                    }
                    else{
                        setErrorDisabled(true)
                    }
                }
            }
            else{
            if(dynamicSelector.data.element[props.active_step]){
                if(dynamicSelector.data.element[props.active_step][props.data[0]] !== ""){
                    setErrorDisabled(false)
                }
                else{
                    setErrorDisabled(true)
                }
            }
            }
        }
    },[dynamicSelector.data.element[props.active_step]])


    const getRadioButtonValue = () => {
        let radioButtonList = []
        if(props.compositeField && props.stepKey){
            radioButtonList = props.optionsData[props.active_step][props.compositeField][props.stepKey][props.data[0]]
        }
        else{
            radioButtonList = props.optionsData[props.active_step][props.data[0]]
        }
        return radioButtonList
    }

    const updateDispatchStoreRadioButtonValue = (value) => {
        if(props.compositeField && props.stepKey){
            props.updateDispatchStore(props.compositeField,props.stepKey,props.data[0],value)
        }
        else{
        props.updateDispatchStore(props.data[0],value)
        }

    }

    return(
        <>
         <TextInputLabelContent>
                                {label}
            </TextInputLabelContent>
             <div
                                className='ml'
                                style={{
                                display: "flex",
                                width: "50%"
                                }}>
                                { Object.entries(props.optionsData).length === 0 ?  emptyOptions : getRadioButtonValue()?.map((radio, i) => {
                                return (
                                    <Fragment key={i}>
                                        <RadioButton
                                        key={i}
                                        disabled={props.isDisabled}
                                        onChange={(e) => {
                                            updateDispatchStoreRadioButtonValue(radio.value)
                                        }}
                                        checked={props.compositeField && props.stepKey ? dynamicSelector.data.element[props.active_step][props.compositeField][props.stepKey][props.data[0]] === radio.value : dynamicSelector.data.element[props.active_step][props.data[0]] === radio.value}
                                        />
                                        <RadioButtonLabelContent>
                                            {radio.value}
                                        </RadioButtonLabelContent>

                                    </Fragment>
                                )
                                })
                            }

                            </div>
                            {(errorDisabled ? <>{props.errorMessageText(props.data[1].label)}</> : '')}
        </>
    )
}

export default RadioButtonField;