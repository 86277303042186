import React, { useState, useRef, useEffect } from 'react';
import _findIndex from 'lodash/findIndex';
// FF icon
import ChevronDownSmallIcon from '@nokia-csf-uxr/ccfk-assets/latest/ChevronDownSmallIcon';
import CloseSmallIcon from '@nokia-csf-uxr/ccfk-assets/latest/CloseSmallIcon';

import Label from '@nokia-csf-uxr/ccfk/Label';
import Avatar from '@nokia-csf-uxr/ccfk/Avatar';
import { useSelector } from "react-redux";
import { RootState, store } from "Store/mainStore";
import _ from 'lodash';
import SelectItem, {
  SelectItemLabelContent,
  SelectListItem,
  SelectItemText,
  SelectItemButton,
  SelectItemClearButton,
} from '@nokia-csf-uxr/ccfk/SelectItem';

import Chip, {
  ChipLabel,
  ChipIconButton
} from '@nokia-csf-uxr/ccfk/Chip';
import { ToggleButtonGroup } from '@nokia-csf-uxr/ccfk';
import { ToggleGroupButton } from '@nokia-csf-uxr/ccfk/ToggleButtonGroup';
import { ButtonText } from '@nokia-csf-uxr/ccfk/Button';

const isSelectionKeyPressed = key => key && (key === ENTER_KEY || key === SPACE_KEY);
const DELETE = 'Delete';
const BACKSPACE = 'Backspace';
const ENTER_KEY = 'Enter';
const SPACE_KEY = ' ';

const CHIP_STYLE = {
  style: {
    margin: '0.1875rem 0.125rem 0.125rem 0.125rem'
  }
};
const PLACEHOLDER = 'Select';
interface DataList {
  data: Array<any>,
  optionsData: object,
  active_step: string,
  updateDispatchStore?: Function
  isDisabled: boolean,
  errorMessageText?: Function,
  ref: object
}
/** Example of non-searchable SelectItem with mutiple selections allowed with a dropdown list with avatars. */
const MultiSelect = (props: DataList) => {
  const getOptionsProcessed = () => {
    let optionsdata = [];
    const result = {};
    if(props.optionsData && props.optionsData[props.active_step] && props.optionsData[props.active_step][props.data[0]]) {
      optionsdata = props.optionsData[props.active_step][props.data[0]];
      optionsdata.forEach(item => {
        result[item.id] = item.value;
      });
    }
    return result;
  }
  const selectItemRef = useRef(null);
  const selectItemButtonRef = useRef(null);
  const [values, setValues] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const label = props.data[1].required ?  props.data[1].label + ' *' : props.data[1].label;
  const dynamicSelector = useSelector((state: RootState) => state.dynamic);
  const optionsObj = getOptionsProcessed();
  const keysArray: string[] = Object.keys(optionsObj);
  const valuesArray: string[] = Object.values(optionsObj);
  const ariaString = () => values.map((item) => {return item}).toString();
  const haveValues = () => values.length > 0;
  const disableState = props.data[1].readonly ? props.data[1].readonly : props.isDisabled;
  const [errorDisabled, setErrorDisabled] = useState(props.data[1].required);

  // TODO: Manage the Form field states only through Store values.
  useEffect(() => {
    if(props.data[1].default !== "") {
      const defaultVal = Object.values(props.data[1].default);
      setValues(defaultVal);
    }
    if(props.data[1].multiple !== "" && props.data[1].multiple && dynamicSelector.data.element[props.active_step][props.data[0]] !== undefined){
      let newValues =[]
        let keyValueObj = []
        keyValueObj = dynamicSelector.data.element[props.active_step][props.data[0]];
        if(keyValueObj){  
          Object.values(keyValueObj).forEach(function (item) {
            if(newValues[item.value] != "")
              newValues.push(item.value)
          })
          setValues(newValues);
         
          
        }
      // const storeValues = Object.values(dynamicSelector.data.element[props.active_step][props.data[0]])
      // setValues(storeValues);
    }
  },[])
  // TODO: Remove the useEffect & states and replace it through redux.
  useEffect(() => {
    const dynamicSelector = store.getState();
    if(props.data[1].default !== "") {
      if (dynamicSelector.dynamic.data.element.hasOwnProperty(props.active_step)){
        let newValues =[]
        let keyValueObj = []
        keyValueObj = dynamicSelector.dynamic.data.element[props.active_step][props.data[0]];
        if(keyValueObj){  
          Object.values(keyValueObj).forEach(function (item) {
            if(newValues[item.value] != "")
              newValues.push(item.value)
          })
          setValues(newValues);
          props.updateDispatchStore(props.data[0],keyValueObj);
          
        }
      }   
    }
   
    if(props.data[1].required){
      if(dynamicSelector.dynamic.data.element[props.active_step]){
          if(dynamicSelector.dynamic.data.element[props.active_step][props.data[0]].length !== 0){
              setErrorDisabled(false)
          }
          else{
              setErrorDisabled(true)
          }
      }
  }
  },[dynamicSelector.data.element[props.active_step]])

  // handle list item selection and close dropdown list after item is selected
  const handleEvent = (index) => (event) => {
    const { type } = event;
    const isValueAlreadySelected = value => _findIndex(values, item => item === value) > -1;
    let theValue = valuesArray[index];
    let newValues = [];
    let considerKey = false;
    if (type === 'keydown') {
      if (isSelectionKeyPressed(event.key)) {
        if (!isValueAlreadySelected(theValue)) {
          newValues = [...values, theValue ];
          setValues(newValues);
        }
        setIsOpen(false);
      }
    } else if (type === 'click') {
      if (!isValueAlreadySelected(theValue)) {
        newValues = [...values, theValue ];
        setValues(newValues);
      }
      setIsOpen(false);
    } 
    getKeyvalueData(props.data[0],index,'add',considerKey);
  };

  // remove closed chip from selected values
  const handleChipClose = (value) => () => {
    const valueIndex = _findIndex(values, item => item === value);
    if (valueIndex > -1) {
      const clonedValues = [...values];
      clonedValues.splice(valueIndex, 1);
      setValues(clonedValues);
      selectItemButtonRef.current && selectItemButtonRef.current.focus();
      getKeyvalueData(props.data[0],value,'delete', false);
    }
  };
  const getKeyvalueData = (dataSet,selectkey,ops,considerKey) => {

    const optionsList = props.optionsData[props.active_step][props.data[0]]
    let selectedValues = []

    if (ops === 'add') {
      const selectedOption = optionsList.filter( e => e.id === keysArray[selectkey]);
      const keyData = considerKey ? selectedOption[0].id : selectedOption[0].value;
      selectedValues = Array.isArray(dynamicSelector.data.element[props.active_step][dataSet]) ? [...dynamicSelector.data.element[props.active_step][dataSet]] : [];
      selectedValues = [...selectedValues, {key: keyData, value: selectedOption[0].value}]
      
    } else if (ops === 'delete') {
      selectedValues = dynamicSelector.data.element[props.active_step][dataSet].filter((item) => item.value !== selectkey)
    }
    props.updateDispatchStore(dataSet,selectedValues);
        
}

  const buildKeyvalueData = (optionList) => {
    const arrayOfObjects = Object.entries(optionList).map(([key, value]) => ({
      key: key,
      value,
    }))
    return arrayOfObjects;
  }

  const renderClearButton = (props) => {
    return (
      <SelectItemClearButton
        aria-label="clear input"
        onClick={() => {setValues([]); selectItemButtonRef.current && selectItemButtonRef.current.focus();}}
        {...props}
        >
        <CloseSmallIcon />
      </SelectItemClearButton>
    );
  };

  const renderSelectItemBase = (props)  => {
    // only show ClearButton when there is at least 1 item selected and the menu is opened
    const showClearButton = haveValues() && isOpen;
    return (
      <SelectItemButton
        ref={selectItemButtonRef}
        placeholder={PLACEHOLDER}
        dropdownIcon={<ChevronDownSmallIcon />}
        inputProps={{ value: haveValues() ? ariaString() : PLACEHOLDER }}
        renderClearButton={showClearButton ?  renderClearButton : undefined}
        role="combobox"
        {...props}
      >
        {haveValues() && values.map((item, x) => {
          return (
            <Chip
              key={`chip-${item}-${x}`}
              disabled={disableState}
              tabIndex={0}
              role="comment"
              size="small"
              aria-label={item}
              {...CHIP_STYLE}
              onClick={(event) => {event.stopPropagation();}}
              onKeyDown={(event) => {
                if (event.key === BACKSPACE || event.key === DELETE) {
                  handleChipClose(item)();
                }}}
            >
              <ChipLabel label={item} />
              <ChipIconButton aria-label={`remove ${item}`} onClick={handleChipClose(item)}><CloseSmallIcon /></ChipIconButton>
            </Chip>
        )})}
      </SelectItemButton>
    )
  };

  return (
    <>
      <Label
        id="selectitem-component-label"
        variant="vertical-layout"
      >
      <SelectItemLabelContent variant='default'>
          {label}
        </SelectItemLabelContent>
      </Label>
       <SelectItem
          ref={selectItemRef}
          aria-labelledby="selectitem-component-label"
          aria-label={haveValues() ? ariaString() : PLACEHOLDER}
          disabled={props.isDisabled}
          multiSelect
          isOpen={isOpen}
          onOpen={() => { setIsOpen(true); }}
          onClose={() => { setIsOpen(false); }}
          truncateListText
          listProps={{ ulProps: { role: 'listbox' } }}
          renderSelectItemBase={renderSelectItemBase}
        >
          {valuesArray.map((item, x) => {
            return (
              <SelectListItem
                role="option"
                key={`${keysArray[x]}`}
                selected={_findIndex(values, value => value === item) > -1}
                onClick={handleEvent(x)}
                onKeyDown={handleEvent(x)}
                aria-label={item}
                style={{ height: '3rem', minHeight: '3rem',  maxHeight: '3rem'}}
              >
                <Avatar size="medium">{item.substring(0,2).toUpperCase()}</Avatar>
                <SelectItemText style={{ paddingLeft: '1rem',}} >{item}</SelectItemText>
              </SelectListItem>
            );
          })}
        </SelectItem>
        {(errorDisabled ? <>{props.errorMessageText(props.data[1].label)}</> : '')}
    </>
  )
};

export default MultiSelect;
