import { v4 as uuid } from "uuid";

export const getPartnerList = (apiResponse: []) => {
  const partnerList = [];
  if (apiResponse) {
    apiResponse.map((row) => {
      const { id, value } = row;
      return partnerList.push({
        id,
        value,
      });
    });
  }

  return partnerList;
};

export const getCustometList = (partner, apiResponse) => {
  
  const partnerSelection = apiResponse.filter((row) => row.id === partner);
  return partnerSelection.length > 0 && partnerSelection[0].end_customer
    ? partnerSelection[0].end_customer
    : [];
};
export const getNetworktList = (customer, customerList) => {
  const filteredCustomerList = customerList.filter(
    (row) => row.id === customer
  );
  const network_list =
    filteredCustomerList.length > 0 && filteredCustomerList[0].network_list
      ? filteredCustomerList[0].network_list
      : [];
  return network_list;
};

export const getSubscriptiontList = (network, networkBlanket) => {
  const filteredNetworks = networkBlanket.filter((row) => row.id === network);
  let subscriptions = [];
  if (filteredNetworks[0]?.subscriptions && typeof filteredNetworks[0].subscriptions === "object") {
    Object.keys(filteredNetworks[0].subscriptions).map((key) => {
      const updatedSubscriptionList = filteredNetworks[0].subscriptions[
        key
      ].map((sRow) => {
        const __newList = { ...sRow };
        return __newList;
      });
      return subscriptions.push({
        [key]: updatedSubscriptionList,
      });
    });
  }
  return subscriptions;
};

export const getUniqueKeyId = (varient = "unique") => {
  const unique_id = uuid();
  return varient === "unique" ? unique_id : unique_id.slice(0, 8);
};

export const getDataGridRowStatusColour = (status) => {
  let statusColor = "";
  if(status && status.trim().length > 0){
    status = status.trim().toLowerCase();
    const statusStyles = {
      'active': "var(--legacy-color-lime-green-500)",
      'active - not renewed': "var(--legacy-color-pumpkin-orange-300)",
      'ended': "var(--legacy-color-poppy-red-300)",
    }
    /*
    const statusStyles = {
      'active': {
        borderLeft: "3px solid",
        borderLeftColor: "var(--legacy-color-lime-green-500)",
      },
      'active - not renewed': {
        borderLeft: "3px solid",
        borderLeftColor: "var(--legacy-color-pumpkin-orange-300)"
      },
      'ended': {
        borderLeft: "3px solid",
        borderLeftColor: "var(--legacy-color-poppy-red-300)",
      }
    }
    */
    statusColor = (statusStyles[status])? statusStyles[status] : "";    
  }
  return statusColor;
}