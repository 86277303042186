//@ts-ignore
import React, { useState, useRef } from 'react';

import MoreVerticalIcon_latest from '@nokia-csf-uxr/ccfk-assets/latest/MoreVerticalIcon';

import { MoreButton } from '@nokia-csf-uxr/ccfk';
import { MoreButtonItem, MoreButtonItemDivider, MoreButtonItemText, MoreButtonItemIcon } from '@nokia-csf-uxr/ccfk/MoreButton';
import PropTypes from "prop-types";


const MoreButtonVertical = ({ items, isDisabled }) => {
    const [visible, setVisibility] = useState(false);
    const moreButtonRef = useRef(null);

    return (
        <MoreButton
            ref={moreButtonRef}
            isOpen={visible}
            aria-label="More actions"
            onOpen={() => setVisibility(true)}
            onClose={() => setVisibility(false)}
            buttonContent={<MoreVerticalIcon_latest />}
            disabled={isDisabled}
            listProps={{
                elevationProps: {
                    elevationIndex: 2,
                    variant: 'default',
                },
            }}
            menuProps={{ keepMounted: true }}
        >
            {items.map((item, index) => (
                <MoreButtonItem
                    key={index}
                    onClick={item.onClick}
                    aria-label={item.label}
                >
                    {item.icon && (
                        <MoreButtonItemIcon>{item.icon}</MoreButtonItemIcon>
                    )}
                    <MoreButtonItemText>{item.label}</MoreButtonItemText>
                </MoreButtonItem>
            ))}
        </MoreButton>
    )
}

export default MoreButtonVertical;

MoreButtonVertical.propTypes = {
    items: PropTypes.any,
    isDisabled: PropTypes.bool
}