import React from "react";
import { App } from "@nokia-csf-uxr/ccfk";
import { Body } from "Components/Body";
import Header from "Components/Header";
import LoadingOverlay from "react-loading-overlay-ts";
import { useSelector } from "react-redux";
import { RootState } from "Store/mainStore";
import RadioNetworkCIQBody from "./RadioNetworkCIQBody";

let crumbs = ["Radio_network"];
let items = [{ title: 'Radio network', path: '/merchant' }];


const RadioNetworkCIQ = () => {
    const authSelector = useSelector((state: RootState) => state.authSlice);

    return (
        <LoadingOverlay
            active={authSelector.system.isLoading}
            spinner
            classNamePrefix="MyLoader_"
            text="Please wait "
        >
            <App>
                <Header />
                <Body
                    crumbs={crumbs}
                    bodyContent={<RadioNetworkCIQBody />}
                    items={items}
                />
            </App>
            {/* <AppFooter /> */}
        </LoadingOverlay>
    )
}

export default RadioNetworkCIQ;