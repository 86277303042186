import { App } from "@nokia-csf-uxr/ccfk";
import { Body } from "Components/Body";
import Header from "Components/Header";
// import { useAuthState } from "Components/Logics/ensoCommonFunctions";
import React, { memo } from "react";
import LoadingOverlay from "react-loading-overlay-ts";
import { useQuery } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "Store/mainStore";
import SubscriptionBody from "./SubscriptionBody";
import OOT from "Constants/services/oot";
import {
  setLoader,
  setPartnerList,
  setQuestionnaire,
  setInvoicingSubscriptionFrequency,
} from "Store/contentState/subscriptionSlice";
import { useSubscriptionFilter } from "./hooks/useSubscriptionFilter";


const Subscription = () => {
  const dispatch = useDispatch();
  const { fetchSubscriptionNetworkData } = useSubscriptionFilter();
  const authSelector = useSelector((state: RootState) => state.authSlice);
  const {questionnaire, partnerList} = useSelector((state: RootState) => state.subscription);
  // useAuthState();

   /**
   * Load data
   */
   useQuery({
    queryKey: ["quesionneries"],
    enabled: questionnaire.length === 0,
    queryFn: async ({ queryKey }) => {
      //@ts-ignore
      //onst [_key] = queryKey;
      const response = await OOT.getSubscriptionQuesionneries();
      return response;
    },
    onSuccess(result) {
      if (
        result?.data["Reason for canceling"] &&
        typeof result.data["Reason for canceling"]?.Options === "object"
      ) {
        const __questionnaire = Object.keys(
          result.data["Reason for canceling"].Options
        );
        // Frequency of invoicing subscription
        const frequencyObj =
          result?.data["Frequency of invoicing subscription"]?.Options ?? {};
        const __invoicingSubscriptionFrequency = Object.keys(frequencyObj).map(
          (fId) => ({ id: fId, value: frequencyObj[fId] })
        );
        dispatch(
          setQuestionnaire({
            questionnaire: __questionnaire,
          })
        );

        dispatch(
          setInvoicingSubscriptionFrequency({
            invoicingSubscriptionFrequency: __invoicingSubscriptionFrequency,
          })
        );
      }
    },
  });
  /**
   * Load partner list
   */
  useQuery({
    queryKey: ["partner_data"],
    // refetchInterval: 10 * 60 * 1000,
    enabled: partnerList.length === 0,
    queryFn: async () => {
      dispatch(
        setLoader({
          key: "isPartnerLoading",
          value: true,
        })
      );
      const response = await OOT.getSubscriptionPartnerData();
      return response;
    },
    onSuccess(rsPartnerData) {
      const __partnerList = rsPartnerData.data.partnerList
        ? rsPartnerData.data.partnerList
        : [];
      dispatch(
        setLoader({
          key: "isPartnerLoading",
          value: false,
        })
      );
      dispatch(
        setPartnerList({
          partnerList: __partnerList,
        })
      );
      // Make the partner selection by default if __partnerList.length === 1
      if (__partnerList.length === 1) {
        fetchSubscriptionNetworkData("fetch", __partnerList[0].id);
      }
    },
  });
  return (
    <>
      <LoadingOverlay
        active={authSelector.system.isLoading}
        spinner
        classNamePrefix="MyLoader_"
        text="Please wait "
      >
        <App>
          <Header />
          <Body crumbs={["Subscription"]} bodyContent={<SubscriptionBody />} />
        </App>
        {/* <AppFooter /> */}
      </LoadingOverlay>
    </>
  );
};
export default memo(Subscription);
