import React from "react";
import { Label, Typography } from "@nokia-csf-uxr/ccfk";
import userStyles from "../Style/UserManagement.module.css";
import { useSelector } from "react-redux";
import { RootState } from "Store/mainStore";

const AccountReference = () => {
    const { oneStarNumber, threeStarNumber } = useSelector((state: RootState) => state.userManagement.userData);
    return (
        <>
            <Label className={userStyles.userLabel}>Account Reference</Label>
            <div style={{ display: 'flex', flex: 'row', gap: '2rem' }}>
                <div>
                    <Label style={{fontWeight:'700'}}>1* numbers </Label>
                    {
                        oneStarNumber.map((number, index) => (
                            <Typography key={index}>{number}</Typography>
                        ))
                    }
                </div>
                <div>
                    <Label style={{fontWeight:'700'}}>3* numbers </Label>
                    {
                        threeStarNumber.map((number, index) => (
                            <Typography key={index}>{number}</Typography>
                        ))
                    }
                </div>


            </div>
        </>
    )
}

export default AccountReference;