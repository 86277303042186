import askeyLogo from '../../../Assets/Icons/askey-logo.png';
import handheldLogo from '../../../Assets/Icons/handheld-logo.png';
import sellerLogo from '../../../Assets/Icons/seller-logo.svg';
import etraTelecomLogo from '../../../Assets/Icons/Etra-Telecom-logo.png';

//CONTANTS ALSO INCLUDED IN THIS FILE
export const SORT_BY_DROP_VALUES = [{ "id": "lowhigh", "value": "Price (low to high)" }, { "id": "highlow", "value": "Price (high to low)" }, { "id": "AZ", "value": "Product A to Z " }, { "id": "ZA", "value": "Product Z to A" }]


export const formatNumberWithCommas = (number) => {
  return new Intl.NumberFormat('en-US', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(number);
}



// export const formatDate = (date, format = 'dd.mm.yyyy', time = false) => {
//   if (!date) return '';

//   const day = date.getDate().toString().padStart(2, '0');
//   const month = date.getMonth(); // We'll use this to get the month name
//   const year = date.getFullYear().toString();

//   const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

//   if (format === 'yyyy-mm-dd') {
//     return `${year}-${(month + 1).toString().padStart(2, '0')}-${day}`;
//   }

//   if (format === 'dd-MMM-yyyy') {
//     return `${day}-${monthNames[month]}-${year}`;
//   }

//   // Default to 'dd.mm.yyyy'
//   return `${day}.${(month + 1).toString().padStart(2, '0')}.${year}`;
// };



export const formatDate = (date, format = 'dd.mm.yyyy', time = false) => {
  if (!date) return '';

  // Ensure the date is a valid Date object
  if (!(date instanceof Date)) {
      date = new Date(date);
      if (isNaN(date)) return 'Invalid Date';
  }

  const day = date.getDate().toString().padStart(2, '0');
  const month = date.getMonth(); // 0-11 index
  const year = date.getFullYear().toString();
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');


  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 
                      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  let formattedDate = '';

  switch (format) {
      case 'yyyy-mm-dd':
          formattedDate = `${year}-${(month + 1).toString().padStart(2, '0')}-${day}`;
          break;
      case 'dd-MMM-yyyy':
          formattedDate = `${day}-${monthNames[month]}-${year}`;
          break;
      case 'yyyy':
            formattedDate = `${year}`;
            break;    
      default:
          formattedDate = `${day}.${(month + 1).toString().padStart(2, '0')}.${year}`;
          break;
  }

  // Include time if specified
  if (time) {
      formattedDate += ` ${hours}:${minutes}`;
  }

  return formattedDate;
};
// Utility function to calculate expiry date
export const calculateExpiryDate = (createdDate, expiryDate) => {
  // if (expiryDate) {
  //     return new Date(expiryDate);
  // }
  const newExpiryDate = new Date(createdDate);
  newExpiryDate.setMonth(newExpiryDate.getMonth() + 3);
  return newExpiryDate;
};


//update few paramaters of offers before displaying in the below
export const updatedOffersList = (data) => {
  const updateData = data.map((item) => {
    const createdDate = item.offer_created_date ? new Date(item.offer_created_date) : new Date(item.createdAt);
    const expiryDate = item.field_offer_expiry_date !== null ? new Date(item.field_offer_expiry_date) : null;

    const formattedDate = formatDate(createdDate, 'dd-MMM-yyyy');
    const formattedExpDate = formatDate(calculateExpiryDate(createdDate, expiryDate), 'dd-MMM-yyyy');
    const formattedCost = `${formatNumberWithCommas(item.field_ofr_order_cart_total)} ${item.field_ofr_order_currency}`
    return {
      ...item,
      offer_created_date: formattedDate,
      offer_expiry_date: formattedExpDate,
      cost: formattedCost
    };
  });

  return updateData;
};


//get supplier icon based on agent_model 
export const getSupplierImage = (is_agent_model, supplier_text) => {
  let imageSource = '';
  if (is_agent_model === 0) {
    imageSource = sellerLogo;
  } else if (is_agent_model === 1) {
    if (supplier_text.toLowerCase().includes('askey')) {
      imageSource = askeyLogo;
    } else if (supplier_text.toLowerCase().includes('handheld') || supplier_text.toLowerCase().includes('mildef')) {
      imageSource = handheldLogo;
    } else if(supplier_text.toLowerCase().includes('etra')){
      imageSource = etraTelecomLogo;
    }
  }

  return imageSource;
}


//get fulfillMent type
export const getFulFillmentType = (product) => {
  let shipmentType = '';
  if (product.is_agent_model === 1) {
    if (product.fulfillment_mode === 'Merchant') {
      shipmentType = product.supplier_text;
    }else if(product.fulfillment_mode === 'DAC'){
      shipmentType = "Nokia";
    } else {
      shipmentType = "";
    }
  } else {
    shipmentType = "Nokia";
  }
  return shipmentType;
}

export function getAccountCurrency(currency, currencyIsoCode) {
  if (currency !== null) {
    return currency;
  } else if (currencyIsoCode === 'USD' || currencyIsoCode === 'JPY') {
    return currencyIsoCode;
  } else if (currencyIsoCode === 'AUD' || currencyIsoCode === 'MXN' || currencyIsoCode === 'CAD') {
    return 'USD'
  }
  else {
    return 'EUR';
  }
}

export const shortenText = (text, size = 25, suffix="...") => {
  if(text.length > size){
    return text.substring(0, (size-suffix.length)) + suffix;
  }else{
    return text;
  }
}

export const getSoldByInfo = (product) => {
  let soldBy = "";
  if(product.is_agent_model === 1){
    soldBy = product.supplier_text
  }else if(product.is_agent_model === 0){
    soldBy = "Nokia"
  }else{
    soldBy = ""
  }
  return soldBy;
}

export const ootOffers = () => {
  const data = [
    {
      "offer_id": "00fc36",
      "field_ofr_order_link_to_salesfor": "19.XY.123456",
      "name": "Combitech AB",
      "nid": "43366",
      "one_star_number_account": "1000089344",
      "one_star_number_customer_partner": "1000065078",
      "offer_link": "https://stg-on-offer-dac.nokia.com/offer/edit/00fc36",
      "owner_email": "kavya.1.hegde@nokia.com",
      "offer_title": "Quote 11",
      "offer_created_date": "2024-07-18 00:00:00",
      "field_offer_expiry_date": null,
      "archived": "No",
      "field_ofr_order_cart_total": "1508.00000",
      "field_ofr_order_cat_prod_discoun": "0.00000",
      "field_ofr_order_commercial_model": "opex",
      "field_ofr_order_co_owners": null,
      "field_ofr_order_currency": "EUR",
      "field_ofr_order_source": "marketplace",
      "field_ofr_order_original_price": "1508.00000",
      "status": null,
      "po_number": null,
      "po_link_pdf_attachment_link": null,
      "createdAt": "2024-07-18 20:00:12",
      "oot_billing_address_id": null,
      "oot_shipping_address_id": null,
      "field_ofr_order_status": "approved",
      "field_ofr_order_network_location": "SE",
      "products": [
        {
          "price": null,
          "title": "IS540.2 SMARTPHONE EU (ATEX Zone 2/22)",
          "total": 1490,
          "status": "Deliver",
          "discount": null,
          "quantity": 1,
          "group_name": null,
          "product_id": "4251005259184",
          "dod_absolute": null,
          "product_type": "Hardware",
          "duration_type": null,
          "is_agent_model": 0,
          "is_marketplace": 1,
          "product_family": "Devices",
          "after_dod_price": null,
          "dod_discount_per": null,
          "product_sub_type": "Material",
          "discount_percentage": null,
          "custom_logistics_price": 0,
          "additional_discount_percent": null,
          "after_management_discount_price": null
        },
        {
          "price": null,
          "title": "Savox RWE-102",
          "total": 18,
          "status": "Deliver",
          "discount": null,
          "quantity": 1,
          "group_name": null,
          "product_id": "HWNDK50700",
          "dod_absolute": null,
          "product_type": "Hardware",
          "duration_type": null,
          "is_agent_model": 0,
          "is_marketplace": 1,
          "product_family": "Devices",
          "after_dod_price": null,
          "dod_discount_per": null,
          "product_sub_type": "Material",
          "discount_percentage": null,
          "custom_logistics_price": 0,
          "additional_discount_percent": null,
          "after_management_discount_price": null
        }
      ]
    },
    {
      "offer_id": "018276",
      "field_ofr_order_link_to_salesfor": "19.XY.123456",
      "name": "Combitech AB",
      "nid": "43366",
      "one_star_number_account": "1000089344",
      "one_star_number_customer_partner": "Tst123",
      "offer_link": "https://stg-on-offer-dac.nokia.com/offer/edit/018276",
      "owner_email": "smitha.vijayan@nokia.com",
      "offer_title": "Auto Generated",
      "offer_created_date": "2024-07-18 00:00:00",
      "field_offer_expiry_date": null,
      "archived": "No",
      "field_ofr_order_cart_total": "1938.00000",
      "field_ofr_order_cat_prod_discoun": "0.00000",
      "field_ofr_order_commercial_model": "opex",
      "field_ofr_order_co_owners": null,
      "field_ofr_order_currency": "EUR",
      "field_ofr_order_source": "marketplace",
      "field_ofr_order_original_price": "1900.00000",
      "status": "requesteddelivery",
      "po_number": null,
      "po_link_pdf_attachment_link": "86601",
      "createdAt": "2024-07-15 14:17:16",
      "oot_billing_address_id": null,
      "oot_shipping_address_id": null,
      "field_ofr_order_status": "requesteddelivery",
      "field_ofr_order_network_location": "AT",
      "products": [
        {
          "price": 810,
          "title": "Nokia Industrial 4G fieldrouter FRRx402d",
          "total": 826.2,
          "status": "Deliver",
          "discount": 0,
          "quantity": 1,
          "group_name": "Devices Portfolio Hardware: Router",
          "product_id": "HWNDUSES1002",
          "dod_absolute": null,
          "product_type": "Hardware",
          "duration_type": null,
          "is_agent_model": 0,
          "is_marketplace": 1,
          "product_family": "Devices",
          "after_dod_price": null,
          "dod_discount_per": null,
          "product_sub_type": "Material",
          "discount_percentage": null,
          "custom_logistics_price": 16.2,
          "additional_discount_percent": null,
          "after_management_discount_price": null
        },
        {
          "price": 1090,
          "title": "Nokia Industrial 5G dongle DGRx501e",
          "total": 1111.8,
          "status": "Deliver",
          "discount": 0,
          "quantity": 1,
          "group_name": "Devices Portfolio Hardware: Dongle",
          "product_id": "HWNDUSES1064",
          "dod_absolute": null,
          "product_type": "Hardware",
          "duration_type": null,
          "is_agent_model": 0,
          "is_marketplace": 1,
          "product_family": "Devices",
          "after_dod_price": null,
          "dod_discount_per": null,
          "product_sub_type": "Material",
          "discount_percentage": null,
          "custom_logistics_price": 21.8,
          "additional_discount_percent": null,
          "after_management_discount_price": null
        }
      ]
    },
    {
      "offer_id": "020baa",
      "field_ofr_order_link_to_salesfor": "19.XY.123456",
      "name": "Combitech AB",
      "nid": "43366",
      "one_star_number_account": "1000089344",
      "one_star_number_customer_partner": "1000000066",
      "offer_link": "https://stg-on-offer-dac.nokia.com/offer/edit/020baa",
      "owner_email": "anusha.hublikar@nokia.com",
      "offer_title": "Auto Generated",
      "offer_created_date": "2024-07-18 00:00:00",
      "field_offer_expiry_date": null,
      "archived": "No",
      "field_ofr_order_cart_total": "35439.80000",
      "field_ofr_order_cat_prod_discoun": "0.00000",
      "field_ofr_order_commercial_model": "opex",
      "field_ofr_order_co_owners": null,
      "field_ofr_order_currency": "EUR",
      "field_ofr_order_source": "marketplace",
      "field_ofr_order_original_price": "34960.00000",
      "status": null,
      "po_number": null,
      "po_link_pdf_attachment_link": null,
      "createdAt": "2024-07-01 20:01:01",
      "oot_billing_address_id": null,
      "oot_shipping_address_id": null,
      "field_ofr_order_status": "requesteddelivery",
      "field_ofr_order_network_location": "AT",
      "products": [
        {
          "price": null,
          "title": "Perimeter Net Radio Pico 5G SW Upgr",
          "total": 1420,
          "status": "Deliver",
          "discount": 0,
          "quantity": 1,
          "group_name": null,
          "product_id": "NPNSRVSWUPG1012",
          "dod_absolute": null,
          "product_type": "Services",
          "duration_type": null,
          "is_agent_model": 0,
          "is_marketplace": 0,
          "product_family": "NPN",
          "after_dod_price": null,
          "dod_discount_per": null,
          "product_sub_type": "SW_Update",
          "discount_percentage": null,
          "custom_logistics_price": 0,
          "additional_discount_percent": null,
          "after_management_discount_price": null
        },
        {
          "price": null,
          "title": "Perimeter Net Core Medium 4G GR SW Upgr",
          "total": 8800,
          "status": "Deliver",
          "discount": 0,
          "quantity": 1,
          "group_name": null,
          "product_id": "NPNSRVSWUPG1015",
          "dod_absolute": null,
          "product_type": "Services",
          "duration_type": null,
          "is_agent_model": 0,
          "is_marketplace": 0,
          "product_family": "NPN",
          "after_dod_price": null,
          "dod_discount_per": null,
          "product_sub_type": "SW_Update",
          "discount_percentage": null,
          "custom_logistics_price": 0,
          "additional_discount_percent": null,
          "after_management_discount_price": null
        },
        {
          "price": null,
          "title": "NDN Electric Drone (5.2)",
          "total": 24469.8,
          "status": "Deliver",
          "discount": 0,
          "quantity": 1,
          "group_name": null,
          "product_id": "P591013",
          "dod_absolute": null,
          "product_type": "Hardware",
          "duration_type": null,
          "is_agent_model": 0,
          "is_marketplace": 0,
          "product_family": "Devices",
          "after_dod_price": null,
          "dod_discount_per": null,
          "product_sub_type": "Material",
          "discount_percentage": null,
          "custom_logistics_price": 479.8,
          "additional_discount_percent": null,
          "after_management_discount_price": null
        },
        {
          "price": null,
          "title": "NDN App subscription (per user)",
          "total": 750,
          "status": "Deliver",
          "discount": 0,
          "quantity": 1,
          "group_name": null,
          "product_id": "SSNDBACN1144",
          "dod_absolute": null,
          "product_type": "Services",
          "duration_type": null,
          "is_agent_model": 0,
          "is_marketplace": 0,
          "product_family": "Devices",
          "after_dod_price": null,
          "dod_discount_per": null,
          "product_sub_type": "Subscription",
          "discount_percentage": null,
          "custom_logistics_price": 0,
          "additional_discount_percent": null,
          "after_management_discount_price": null
        }
      ]
    },
    {
      "offer_id": "06666d",
      "field_ofr_order_link_to_salesfor": "19.XY.123456",
      "name": "Computer Sciences Corporation dba DXC Technology Services LLC",
      "nid": "676311",
      "one_star_number_account": "1000070612",
      "one_star_number_customer_partner": "1000096173",
      "offer_link": "https://stg-on-offer-dac.nokia.com/offer/edit/06666d",
      "owner_email": "testxaa55@gmail.com",
      "offer_title": "MKT:1000070612:Computer Sciences Corp...",
      "offer_created_date": "2024-07-28 00:00:00",
      "field_offer_expiry_date": null,
      "archived": "No",
      "field_ofr_order_cart_total": "1807.00000",
      "field_ofr_order_cat_prod_discoun": "3614.00000",
      "field_ofr_order_commercial_model": "opex",
      "field_ofr_order_co_owners": null,
      "field_ofr_order_currency": "USD",
      "field_ofr_order_source": "marketplace",
      "field_ofr_order_original_price": "1807.00000",
      "status": "pendingapproval",
      "po_number": "1",
      "po_link_pdf_attachment_link": "162796",
      "createdAt": "2024-10-14 05:33:03",
      "oot_billing_address_id": "1751621",
      "oot_shipping_address_id": "3172266",
      "field_ofr_order_status": "order",
      "field_ofr_order_network_location": "US",
      "products": [
        {
          "price": null,
          "title": "IS540.2 SMARTPHONE NA (NEC500 C1D2)",
          "total": 1790,
          "status": "Deliver",
          "discount": null,
          "quantity": 1,
          "group_name": null,
          "product_id": "4251005221648",
          "dod_absolute": null,
          "product_type": "Hardware",
          "duration_type": null,
          "is_agent_model": 0,
          "is_marketplace": 1,
          "product_family": "Devices",
          "after_dod_price": null,
          "dod_discount_per": null,
          "product_sub_type": "Material",
          "discount_percentage": null,
          "custom_logistics_price": 0,
          "additional_discount_percent": null,
          "after_management_discount_price": null
        },
        {
          "price": null,
          "title": "Power cable AU",
          "total": 17,
          "status": "Offer",
          "discount": null,
          "quantity": 1,
          "group_name": null,
          "product_id": "C13AU",
          "dod_absolute": null,
          "product_type": "Hardware",
          "duration_type": null,
          "is_agent_model": 1,
          "is_marketplace": 1,
          "product_family": "Marketplace",
          "after_dod_price": null,
          "dod_discount_per": null,
          "product_sub_type": "Material",
          "discount_percentage": null,
          "custom_logistics_price": 0,
          "additional_discount_percent": null,
          "after_management_discount_price": null
        }
      ]
    },
    {
      "offer_id": "06b05d",
      "field_ofr_order_link_to_salesfor": "19.XY.123456",
      "name": "TD Synnex Corporation",
      "nid": "75221",
      "one_star_number_account": "1000063412",
      "one_star_number_customer_partner": "1000087226",
      "offer_link": "https://stg-on-offer-dac.nokia.com/offer/edit/06b05d",
      "owner_email": "eottestuser@gmail.com",
      "offer_title": "Auto Generated",
      "offer_created_date": "2024-07-28 00:00:00",
      "field_offer_expiry_date": null,
      "archived": "No",
      "field_ofr_order_cart_total": "1635.00000",
      "field_ofr_order_cat_prod_discoun": "3270.00000",
      "field_ofr_order_commercial_model": "opex",
      "field_ofr_order_co_owners": null,
      "field_ofr_order_currency": "USD",
      "field_ofr_order_source": "marketplace",
      "field_ofr_order_original_price": "1635.00000",
      "status": "order",
      "po_number": null,
      "po_link_pdf_attachment_link": "162146",
      "createdAt": "2024-07-29 08:01:07",
      "oot_billing_address_id": "2101531",
      "oot_shipping_address_id": "2455976",
      "field_ofr_order_status": "order",
      "field_ofr_order_network_location": "US",
      "products": [
        {
          "price": null,
          "title": "IS-DC540.1 Desktop Charger Set",
          "total": 170,
          "status": "Deliver",
          "discount": null,
          "quantity": 1,
          "group_name": null,
          "product_id": "4251005215463",
          "dod_absolute": null,
          "product_type": "Hardware",
          "duration_type": null,
          "is_agent_model": 0,
          "is_marketplace": 1,
          "product_family": "Devices",
          "after_dod_price": null,
          "dod_discount_per": null,
          "product_sub_type": "Material",
          "discount_percentage": null,
          "custom_logistics_price": 0,
          "additional_discount_percent": null,
          "after_management_discount_price": null
        },
        {
          "price": null,
          "title": "Nokia Industrial 4G CPE 4G08-12W-A",
          "total": 470,
          "status": "Deliver",
          "discount": null,
          "quantity": 1,
          "group_name": null,
          "product_id": "HWND3TG00389AA",
          "dod_absolute": null,
          "product_type": "Hardware",
          "duration_type": null,
          "is_agent_model": 0,
          "is_marketplace": 1,
          "product_family": "Devices",
          "after_dod_price": null,
          "dod_discount_per": null,
          "product_sub_type": "Material",
          "discount_percentage": null,
          "custom_logistics_price": 0,
          "additional_discount_percent": null,
          "after_management_discount_price": null
        },
        {
          "price": null,
          "title": "Nokia Industrial desk charger HHDCx01x",
          "total": 30,
          "status": "Deliver",
          "discount": null,
          "quantity": 1,
          "group_name": null,
          "product_id": "HWNDUSES1079",
          "dod_absolute": null,
          "product_type": "Hardware",
          "duration_type": null,
          "is_agent_model": 0,
          "is_marketplace": 1,
          "product_family": "Devices",
          "after_dod_price": null,
          "dod_discount_per": null,
          "product_sub_type": "Material",
          "discount_percentage": null,
          "custom_logistics_price": 0,
          "additional_discount_percent": null,
          "after_management_discount_price": null
        },
        {
          "price": null,
          "title": "Algiz RT10 Pole mount 1 and 1.25\" poles",
          "total": 616,
          "status": "Offer",
          "discount": null,
          "quantity": 2,
          "group_name": null,
          "product_id": "MPHHRT10ACC16",
          "dod_absolute": null,
          "product_type": "Hardware",
          "duration_type": null,
          "is_agent_model": 1,
          "is_marketplace": 1,
          "product_family": "Marketplace",
          "after_dod_price": null,
          "dod_discount_per": null,
          "product_sub_type": "Material",
          "discount_percentage": null,
          "custom_logistics_price": 0,
          "additional_discount_percent": null,
          "after_management_discount_price": null
        },
        {
          "price": null,
          "title": "3 year standard warranty",
          "total": 349,
          "status": "Offer",
          "discount": null,
          "quantity": 1,
          "group_name": null,
          "product_id": "MPHHRT10W3YS",
          "dod_absolute": null,
          "product_type": "Services",
          "duration_type": null,
          "is_agent_model": 1,
          "is_marketplace": 1,
          "product_family": "Marketplace",
          "after_dod_price": null,
          "dod_discount_per": null,
          "product_sub_type": "Warranty",
          "discount_percentage": null,
          "custom_logistics_price": 0,
          "additional_discount_percent": null,
          "after_management_discount_price": null
        }
      ]
    },
    {
      "offer_id": "06c8cf",
      "field_ofr_order_link_to_salesfor": "19.XY.123456",
      "name": "TD Synnex Corporation",
      "nid": "75221",
      "one_star_number_account": "1000063412",
      "one_star_number_customer_partner": "1000087226",
      "offer_link": "https://stg-on-offer-dac.nokia.com/offer/edit/06c8cf",
      "owner_email": "eottestuser@gmail.com",
      "offer_title": "MKT:1000063412:TD Synnex Corporation",
      "offer_created_date": "2024-08-29 00:00:00",
      "field_offer_expiry_date": null,
      "archived": "No",
      "field_ofr_order_cart_total": "2390.00000",
      "field_ofr_order_cat_prod_discoun": "4780.00000",
      "field_ofr_order_commercial_model": "opex",
      "field_ofr_order_co_owners": null,
      "field_ofr_order_currency": "USD",
      "field_ofr_order_source": "marketplace",
      "field_ofr_order_original_price": "2390.00000",
      "status": "order",
      "po_number": null,
      "po_link_pdf_attachment_link": "162321",
      "createdAt": "2024-08-02 09:13:55",
      "oot_billing_address_id": "2101531",
      "oot_shipping_address_id": "2455976",
      "field_ofr_order_status": "order",
      "field_ofr_order_network_location": "US",
      "products": [
        {
          "price": null,
          "title": "IS540.1 SMARTPHONE EU (ATEX Zone 1/21)",
          "total": 2390,
          "status": "Deliver",
          "discount": null,
          "quantity": 1,
          "group_name": null,
          "product_id": "4251005215357",
          "dod_absolute": null,
          "product_type": "Hardware",
          "duration_type": null,
          "is_agent_model": 0,
          "is_marketplace": 1,
          "product_family": "Devices",
          "after_dod_price": null,
          "dod_discount_per": null,
          "product_sub_type": "Material",
          "discount_percentage": null,
          "custom_logistics_price": 0,
          "additional_discount_percent": null,
          "after_management_discount_price": null
        }
      ]
    },
    {
      "offer_id": "087f40",
      "field_ofr_order_link_to_salesfor": "19.XY.123456",
      "name": "Combitech AB",
      "nid": "43366",
      "one_star_number_account": "1000089344",
      "one_star_number_customer_partner": null,
      "offer_link": "https://stg-on-offer-dac.nokia.com/offer/edit/087f40",
      "owner_email": "anusha.hublikar@nokia.com",
      "offer_title": "Auto Generated",
      "offer_created_date": "2024-08-29 00:00:00",
      "field_offer_expiry_date": null,
      "archived": "No",
      "field_ofr_order_cart_total": "990.00000",
      "field_ofr_order_cat_prod_discoun": "0.00000",
      "field_ofr_order_commercial_model": "opex",
      "field_ofr_order_co_owners": null,
      "field_ofr_order_currency": "EUR",
      "field_ofr_order_source": "marketplace",
      "field_ofr_order_original_price": "990.00000",
      "status": null,
      "po_number": null,
      "po_link_pdf_attachment_link": null,
      "createdAt": "2024-06-13 20:00:53",
      "oot_billing_address_id": null,
      "oot_shipping_address_id": null,
      "field_ofr_order_status": "requesteddelivery",
      "field_ofr_order_network_location": "SE",
      "products": [
        {
          "price": null,
          "title": "NDN propeller pack",
          "total": 990,
          "status": "Deliver",
          "discount": 0,
          "quantity": 1,
          "group_name": null,
          "product_id": "P486205",
          "dod_absolute": null,
          "product_type": "Hardware",
          "duration_type": null,
          "is_agent_model": 0,
          "is_marketplace": 0,
          "product_family": "Devices",
          "after_dod_price": null,
          "dod_discount_per": null,
          "product_sub_type": "Material",
          "discount_percentage": null,
          "custom_logistics_price": 0,
          "additional_discount_percent": null,
          "after_management_discount_price": null
        }
      ]
    },
    {
      "offer_id": "0b5669",
      "field_ofr_order_link_to_salesfor": "19.XY.123456",
      "name": "TD Synnex Corporation",
      "nid": "75221",
      "one_star_number_account": "1000063412",
      "one_star_number_customer_partner": "1000102549",
      "offer_link": "https://stg-on-offer-dac.nokia.com/offer/edit/0b5669",
      "owner_email": "eottestuser@gmail.com",
      "offer_title": "MKT:1000063412:TD Synnex Corporation",
      "offer_created_date": "2024-01-30 00:00:00",
      "field_offer_expiry_date": null,
      "archived": "No",
      "field_ofr_order_cart_total": "170.00000",
      "field_ofr_order_cat_prod_discoun": "340.00000",
      "field_ofr_order_commercial_model": "opex",
      "field_ofr_order_co_owners": null,
      "field_ofr_order_currency": "USD",
      "field_ofr_order_source": "marketplace",
      "field_ofr_order_original_price": "170.00000",
      "status": "order",
      "po_number": null,
      "po_link_pdf_attachment_link": "162251",
      "createdAt": "2024-07-30 10:44:42",
      "oot_billing_address_id": "3093946",
      "oot_shipping_address_id": "3135246",
      "field_ofr_order_status": "order",
      "field_ofr_order_network_location": "US",
      "products": [
        {
          "price": null,
          "title": "IS-DC540.1 Desktop Charger Set JP",
          "total": 170,
          "status": "Deliver",
          "discount": null,
          "quantity": 1,
          "group_name": null,
          "product_id": "4251005222799",
          "dod_absolute": null,
          "product_type": "Hardware",
          "duration_type": null,
          "is_agent_model": 0,
          "is_marketplace": 1,
          "product_family": "Devices",
          "after_dod_price": null,
          "dod_discount_per": null,
          "product_sub_type": "Material",
          "discount_percentage": null,
          "custom_logistics_price": 0,
          "additional_discount_percent": null,
          "after_management_discount_price": null
        }
      ]
    },
    {
      "offer_id": "0ecc20",
      "field_ofr_order_link_to_salesfor": "19.XY.123456",
      "name": "TD Synnex Corporation",
      "nid": "75221",
      "one_star_number_account": "1000063412",
      "one_star_number_customer_partner": "1000087226",
      "offer_link": "https://stg-on-offer-dac.nokia.com/offer/edit/0ecc20",
      "owner_email": "eottestuser@gmail.com",
      "offer_title": "3656546",
      "offer_created_date": "2023-08-20 00:00:00",
      "field_offer_expiry_date": null,
      "archived": "No",
      "field_ofr_order_cart_total": "460.00000",
      "field_ofr_order_cat_prod_discoun": "920.00000",
      "field_ofr_order_commercial_model": "opex",
      "field_ofr_order_co_owners": null,
      "field_ofr_order_currency": "USD",
      "field_ofr_order_source": "marketplace",
      "field_ofr_order_original_price": "460.00000",
      "status": "pendingapproval",
      "po_number": null,
      "po_link_pdf_attachment_link": null,
      "createdAt": "2024-09-20 03:35:00",
      "oot_billing_address_id": "2101531",
      "oot_shipping_address_id": "2455976",
      "field_ofr_order_status": "pendingapproval",
      "field_ofr_order_network_location": "US",
      "products": [
        {
          "price": null,
          "title": "BPIS540.1A Battery (ATEX Zone 1/21)",
          "total": 460,
          "status": "Deliver",
          "discount": null,
          "quantity": 1,
          "group_name": null,
          "product_id": "4251005215364",
          "dod_absolute": null,
          "product_type": "Hardware",
          "duration_type": null,
          "is_agent_model": 0,
          "is_marketplace": 1,
          "product_family": "Devices",
          "after_dod_price": null,
          "dod_discount_per": null,
          "product_sub_type": "Material",
          "discount_percentage": null,
          "custom_logistics_price": 0,
          "additional_discount_percent": null,
          "after_management_discount_price": null
        }
      ]
    },
    {
      "offer_id": "114f42",
      "field_ofr_order_link_to_salesfor": "19.XY.123456",
      "name": "TD Synnex Corporation",
      "nid": "75221",
      "one_star_number_account": "1000063412",
      "one_star_number_customer_partner": "1000087226",
      "offer_link": "https://stg-on-offer-dac.nokia.com/offer/edit/114f42",
      "owner_email": "testxaa55@gmail.com",
      "offer_title": "MKT:1000063412:TD Synnex Corporation",
      "offer_created_date": "2023-09-06 00:00:00",
      "field_offer_expiry_date": null,
      "archived": "No",
      "field_ofr_order_cart_total": "170.00000",
      "field_ofr_order_cat_prod_discoun": "340.00000",
      "field_ofr_order_commercial_model": "opex",
      "field_ofr_order_co_owners": null,
      "field_ofr_order_currency": "USD",
      "field_ofr_order_source": "marketplace",
      "field_ofr_order_original_price": "170.00000",
      "status": "order",
      "po_number": null,
      "po_link_pdf_attachment_link": "162586",
      "createdAt": "2024-09-06 08:59:43",
      "oot_billing_address_id": "2101531",
      "oot_shipping_address_id": "2455976",
      "field_ofr_order_status": "order",
      "field_ofr_order_network_location": "US",
      "products": [
        {
          "price": null,
          "title": "IS-DC540.1 Desktop Charger Set JP",
          "total": 170,
          "status": "Deliver",
          "discount": null,
          "quantity": 1,
          "group_name": null,
          "product_id": "4251005222799",
          "dod_absolute": null,
          "product_type": "Hardware",
          "duration_type": null,
          "is_agent_model": 0,
          "is_marketplace": 1,
          "product_family": "Devices",
          "after_dod_price": null,
          "dod_discount_per": null,
          "product_sub_type": "Material",
          "discount_percentage": null,
          "custom_logistics_price": 0,
          "additional_discount_percent": null,
          "after_management_discount_price": null
        }
      ]
    }
  ]
  return data
}
