import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "Store/mainStore";
import { TextInputLabelContent } from "@nokia-csf-uxr/ccfk/TextInput";
import { Label, Checkbox } from "@nokia-csf-uxr/ccfk";

interface DataList {
  data: Array<any>;
  optionsData: object;
  active_step: string;
  updateDispatchStore?: Function;
  isDisabled: boolean;
  errorMessageText: Function;
  stepKey?: string,
  compositeField? : string
}

const CheckboxField = (props: DataList) => {
  const dynamicSelector = useSelector((state: RootState) => state.dynamic);
  const label = props.data[1].required
    ? props.data[1].label + " *"
    : props.data[1].label;

  const [errorDisabled, setErrorDisabled] = useState(!props.data[1].required);

  useEffect(() => {
    if (props.data[1].required) {
      let currentValue = ""
      if(props.compositeField && props.stepKey){
        currentValue = dynamicSelector.data.element[props.active_step]?.[props.compositeField][props.stepKey][props.data[0]];
      }
      else{
       currentValue = dynamicSelector.data.element[props.active_step]?.[props.data[0]];
      }
      setErrorDisabled(!currentValue?.length);
    }
  }, [dynamicSelector.data.element, props.active_step, props.data]);

  const getCheckedItem = (checkbox) => {
    let currentValue = []
    if(props.compositeField && props.stepKey){
       currentValue = dynamicSelector.data.element[props.active_step]?.[props.compositeField][props.stepKey][props.data[0]] || [];
    }
    else{
     currentValue = dynamicSelector.data.element[props.active_step]?.[props.data[0]] || [];
    }
    return currentValue.some((item) => item.key === checkbox.id);
  };

  const setArrayElement = (method, values) => {
    let currentValue = []
    if(props.compositeField && props.stepKey){
      currentValue = dynamicSelector.data.element[props.active_step]?.[props.compositeField][props.stepKey][props.data[0]] || [];
    }
    else{
      currentValue = dynamicSelector.data.element[props.active_step]?.[props.data[0]] || [];
    }
   

    let updatedValues;
    if (method === "add") {
      updatedValues = [...currentValue, values];
    } else if (method === "remove") {
      updatedValues = currentValue.filter((e) => e.key !== values.key);
    }
    if(props.compositeField && props.stepKey){
      props.updateDispatchStore(props.compositeField,props.stepKey,props.data[0], updatedValues);
    }
    else{
    props.updateDispatchStore(props.data[0], updatedValues);
    }
  };

  const handleCheckboxChange = (e, checkbox) => {
    const checkKeyValue = { key: checkbox.id, value: checkbox.value };
    if (e.target.checked) {
      setArrayElement("add", checkKeyValue);
    } else {
      setArrayElement("remove", checkKeyValue);
    }
  };

  return (
    <>
      <TextInputLabelContent>{label}</TextInputLabelContent>
      <div style={{display: 'flex'}}> 
      {!props.compositeField && !props.stepKey && props.optionsData[props.active_step]?.[props.data[0]]?.map(
        (checkbox, i) => (
          <div
            key={checkbox.id}
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
              width: "20%",
            }}
          >
            <Checkbox
              checked={getCheckedItem(checkbox)}
              disabled={props.isDisabled}
              onChange={(e) => handleCheckboxChange(e, checkbox)}
            />
            <Label>{checkbox.value}</Label>
          </div>
        )
      )}
       {props.compositeField && props.stepKey && props.optionsData[props.active_step]?.[props.compositeField][props.stepKey][props.data[0]]?.map(
        (checkbox, i) => (
          <div
            key={checkbox.id}
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
              width: "20%",
            }}
          >
            <Checkbox
              checked={getCheckedItem(checkbox)}
              disabled={props.isDisabled}
              onChange={(e) => handleCheckboxChange(e, checkbox)}
            />
            <Label>{checkbox.value}</Label>
          </div>
        )
      )}
      </div>
      {errorDisabled && props.errorMessageText(props.data[1].label)}
    </>
  );
};

export default CheckboxField;
