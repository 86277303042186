import React, {useEffect,useState}  from "react";
import { RootState } from "Store/mainStore";
import { useSelector } from "react-redux";
import TextInput, {TextInputLabelContent} from "@nokia-csf-uxr/ccfk/TextInput";
import SliderComponent from 'Components/Elements/Slider'


interface DataList {
    data: Array<any>,
    active_step: string,
    updateDispatchStore?: Function
    isDisabled: boolean,
    errorMessageText?: Function,
    stepKey?: string,
    compositeField? : string
}

const RangeField = (props:DataList) => {
    const dynamicSelector = useSelector((state: RootState) => state.dynamic);
    const label = props.data[1].required ?  props.data[1].label + ' *' : props.data[1].label
    const [errorDisabled, setErrorDisabled] = useState(props.data[1].required);
    
    

    useEffect(() => {
        if(props.data[1].required){
            if(props.compositeField && props.stepKey){
                if(dynamicSelector.data.element[props.active_step][props.compositeField]){
                    if(dynamicSelector.data.element[props.active_step][props.compositeField][props.stepKey][props.data[0]] !== ""){
                        setErrorDisabled(false)
                    }
                    else{
                        setErrorDisabled(true)
                    }
                }
            }
            else{
            if(dynamicSelector.data.element[props.active_step]){
                if(dynamicSelector.data.element[props.active_step][props.data[0]] !== ""){
                    setErrorDisabled(false)
                }
                else{
                    setErrorDisabled(true)
                }
            }
            }
        }
    },[dynamicSelector.data.element[props.active_step]])

    const getReturnRangeValue = () => {
        let selectedValue = 0
        if(props.compositeField && props.stepKey){
            if(dynamicSelector.data.element[props.active_step][props.compositeField]){
                if(dynamicSelector.data.element[props.active_step][props.compositeField][props.stepKey][props.data[0]]){
                    selectedValue = dynamicSelector.data.element[props.active_step][props.compositeField] ? dynamicSelector.data.element[props.active_step][props.compositeField][props.stepKey][props.data[0]].key : 0
                }
            }
        }
        else{
        if(dynamicSelector.data.element[props.active_step]){
            if(dynamicSelector.data.element[props.active_step][props.data[0]]){
                selectedValue = dynamicSelector.data.element[props.active_step] ? dynamicSelector.data.element[props.active_step][props.data[0]].key : 0
            }
        }
        }
        return selectedValue;
    }

    const getKeyvalueData = (dataSet, selectkey) => {
        let selectKeyValue = {};
        selectKeyValue["key"] = selectkey;
        selectKeyValue["value"] = selectkey;
        if(props.compositeField && props.stepKey){
        props.updateDispatchStore(props.compositeField,props.stepKey,dataSet, selectKeyValue);
        }
        else{
            props.updateDispatchStore(dataSet, selectKeyValue);
        }
      };

    return(
        <>
        <div style={{marginTop:'10px'}}>
         <TextInputLabelContent>
                                {label}
            </TextInputLabelContent>
            <SliderComponent
            minDuration={props.data[1].min}
            maxDuration={props.data[1].max}
            stepBy={props.data[1].step}
            // disabled={props.isDisabled}
            sliderStateValue={getReturnRangeValue()}
            handleChange={(e) => {
                getKeyvalueData(props.data[0], e.value);
              }} />

            </div>
            {(errorDisabled ? <>{props.errorMessageText(props.data[1].label)}</> : '')}
        </>
    )
}

export default RangeField;