import React, { useRef, useState, useEffect, memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from "Store/mainStore";
import OfferQuotationDetails from './OfferQuotationDetails';
import _ from 'lodash';


const ReportList = (prop) => {
    const dispatch = useDispatch();
    const merchantSelector = useSelector((state: RootState) => state.merchant.dashboard);
    const [orderItemData, setOrderItemData] = useState({});
    const [productData, setProductData] = useState({})
    const [orderBarChart, setOrderBarChart] = useState([])
    const [orderMap, setOrderMap] = useState([])
    
    useEffect(() => {
        
            setOrderItemData(groupedOfferSalesOrdereItem())
            setProductData(groupedOfferProductSubType())
            setOrderBarChart(BarDatanew())
            setOrderMap(Object.entries(groupByCountry(prop.type)).map((item) => item[1]))
        
    },[prop.type,merchantSelector])


    const quotationCurreyList = new Map([
      ["EUR", "EUROPE"],
      ["USD", "NORTH AMERICA"],
      ["JPY", "ASIA-PACIFIC"]
    ]);

  

    const groupedOfferSalesOrdereItem = () => {
        const result = {};
        if(prop.type === "Orders") { 
            merchantSelector.orders.orderItemData .forEach((item) => {
                const { product_id, net_value_transactional_currency, invoiced_quantity, sales_order_id } = item;
                if (!result[product_id]) {
                    result[product_id] = {
                    product_id,
                    totalNetValue: 0,
                    totalInvoicedQuantity: 0,
                    salesOrders: [],
                    };
                }
                result[product_id].totalNetValue += net_value_transactional_currency;
                result[product_id].totalInvoicedQuantity += invoiced_quantity;
                result[product_id].salesOrders.push(sales_order_id);
            });
        }
        else if(prop.type === "Quotations"){
            merchantSelector.quotations.quotationProductList.forEach((offer) => {
                const { offer_id, products } = offer;
                products.forEach(({ product_id, quantity, price }) => {
                    if (!result[product_id]) {
                        result[product_id] = {
                            product_id,
                            totalNetValue: 0,
                            totalInvoicedQuantity: 0,
                            salesOrders: [],
                        };
                    }
                    result[product_id].salesOrders.push(offer_id);
                    result[product_id].totalInvoicedQuantity += quantity;
                    result[product_id].totalNetValue += price;
                });
            });
        }
        return result;
    };
    const groupedOfferProductSubType = () => {
        const resultProductSubType = {};
        if(prop.type === "Orders") { 
            merchantSelector.orders.productDataList.forEach((item) => {
              if(item.product_sub_type_id){
                const { product_sub_type_id, net_value_transactional_currency } = item;
                if (!resultProductSubType[product_sub_type_id]) {
                    resultProductSubType[product_sub_type_id] = {
                    salesAmount: 0,
                    orderCount: 0,
                    };
                }
                resultProductSubType[product_sub_type_id].salesAmount += net_value_transactional_currency;
                resultProductSubType[product_sub_type_id].orderCount += 1;
              }
            });
        }
        else if(prop.type === "Quotations"){
            merchantSelector.quotations.quotationProductList.forEach((offer) => {
                const { products } = offer;
                products.forEach(({ product_sub_type, price }) => {
                  if(product_sub_type){
                  if (!resultProductSubType[product_sub_type]) {
                    resultProductSubType[product_sub_type] = {
                        salesAmount: 0,
                        orderCount: 0,
                    };
                  }
                  resultProductSubType[product_sub_type].salesAmount += price;
                  resultProductSubType[product_sub_type].orderCount += 1;
                }
                });
            });
        }

        return resultProductSubType;
      }


    const salesOrderCount = _.countBy(merchantSelector.orders.orderItemData, 'sales_order_id');
    

      const groupByMarket = (type) => {
        const resultMarket = {};

        let ordersDataList = merchantSelector.orders.ordersData.map(order => ({
          ...order,
          product_count: salesOrderCount[order.sales_order_id] || 0
        }));
        let quotationDataList = merchantSelector.quotations.quotationProductList.map(order => ({
          ...order,
          product_count: order.products.length || 0
        }));
       
        

        const orderMarketList = type === "Orders" ? ordersDataList : quotationDataList
         orderMarketList.forEach((offer) => {
          if(type === "Orders"){
            const { market, net_value_transactional_currency, product_count } = offer;
            if (!resultMarket[market]) {
              resultMarket[market] = {
                market,
                totalNetValue: 0,
                count: 0,
                productCount: 0
              };
            }
            resultMarket[market].totalNetValue += net_value_transactional_currency;
            resultMarket[market].count += 1;
            resultMarket[market].productCount += product_count;
          }
          else{
            const { field_ofr_order_currency, field_ofr_order_original_price, product_count } = offer;
            if (!resultMarket[field_ofr_order_currency]) {
              resultMarket[field_ofr_order_currency] = {
                field_ofr_order_currency,
                totalNetValue: 0,
                count: 0,
                productCount: 0
              };
            }
            resultMarket[field_ofr_order_currency].totalNetValue += parseFloat(field_ofr_order_original_price) || 0;
            resultMarket[field_ofr_order_currency].count += 1;
            resultMarket[field_ofr_order_currency].productCount += product_count;
          }
          
        });
        return resultMarket;
      };

      const BarDatanew = () => {
        const groupMarketDat = groupByMarket(prop.type)
        const barChart =  Object.entries(groupMarketDat).filter(([key]) => key !== "null").map(([key, value]) => ({
          year:prop.type === "Orders" ?  key.split("REGION") ? key.split("REGION")[1] : " " : quotationCurreyList.get(key),
          Market: Math.round(value['totalNetValue']),
          Product:  Math.round(value['productCount'])
        }))
        return barChart
      }

     
    const groupByCountry = (type) => {
        const resultCountry = {};
        const OrderLists = type === "Orders" ? merchantSelector.orders.ordersData : merchantSelector.quotations.quotationProductList
        OrderLists.forEach((item) => {
        const countryId = type === "Orders" ? item.country_id : item.field_ofr_order_network_location
        if (!resultCountry[countryId]) {
            resultCountry[countryId] = {
            country_id: countryId,
            count: 0
            };
        }
        resultCountry[countryId].count += 1
        });
        return resultCountry
    };
      

    return (
        <>
            <OfferQuotationDetails type={prop.type} orderItemData={orderItemData} productData={productData} barChart={orderBarChart} mapCountryCode={orderMap}/>
        </>
    )
}

export default memo(ReportList);