import React, { useEffect, useState } from "react";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import OOT from "Constants/services/oot";
import { useSubscriptionFilter } from "./subscription/hooks/useSubscriptionFilter";

import {
  setLoader,
  setPartnerList,
  setQuestionnaire,
  setInvoicingSubscriptionFrequency,
} from "Store/contentState/subscriptionSlice";

import Subscription from "Pages/subscription/Subscription";
import Marketplace from "./marketplace/Marketplace";
import CartPage from "./marketplace/CartPage";
import Account from "Pages/subscription/Account/index";
import { Login } from "Pages/Login";
import { useSubscriptionAuth } from "./subscription/hooks/useSubscriptionAuth";
import Unauthorized from "./Unauthorized";
import SelectedProductPage from "./marketplace/SelectedProductPage";
import Quotations from "./marketplace/quotations/Quotations";
import { RootState } from "Store/mainStore";
import {
  setUserAccessInfo,
  setCountryList,
  setUserPersona
} from "Store/contentState/marketplaceSlice";
import Loader from "Components/Elements/Loader";
import Admin from "./admin/Admin";
import { setUserMetaData } from "Store/contentState/userManagementSlice";
import { useMarketplaceFilter } from "./marketplace/hooks/useMarketplaceFilter";
import Orders from "./marketplace/orders/Orders";
import MarketplaceBody from "./marketplace/MarketplaceBody";
import CartBody from "./marketplace/CartBody";
import { setProp } from "Store/Actions";
import ProtectedRoute from "./marketplace/HOC/ProtectedRoute";
import UserRoles from "./admin/roles-management/UserRoles";
import Roles from "./admin/roles-management/Roles";
import MerchantBody from "./merchant/MerchantBody";
import Dashboard from "./merchant/Dashboard";
import MerchantProducts from "./merchant/MerchantProducts";
import MerchantOrders from "./merchant/MerchantOrders";
import { setSupplierAcccountId } from "Store/contentState/merchantSlice";
import FallbackPage from "./FallbackPage";
import RadioNetworkCIQ from "./radio-network-ciq/RadioNetworkCIQ";
import { Suspense } from 'react';


// const Orders = React.lazy(() => import('./marketplace/orders/Orders'));
// const Quotations = React.lazy(() => import('./marketplace/quotations/Quotations'));
// const Admin = React.lazy(() => import('./admin/Admin'));


export const Landing = () => {
  const { useAuthState, useIsAuthorized } = useSubscriptionAuth();
  const { isAuthenticated, email, inProgress } = useAuthState();
  const isAuthorized = useIsAuthorized(email);
  const authSelector = useSelector((state: RootState) => state.authSlice);
  const loginUserId = useSelector(
    (state: RootState) => state.authSlice.login.id
  );

  const marketplaceSelector = useSelector(
    (state: RootState) => state.marketplace
  );

  const userPersonaInfo = useSelector(
    (state: RootState) => state.userManagement?.metaData?.persona
  );

  const dispatch = useDispatch();
  const { fetchSubscriptionNetworkData } = useSubscriptionFilter();
  const location = useLocation();

  const [loading,setLoading] = useState(false)


  const getUserInfo = async (loginUserId) => {
    // Handle default user role view
    let userRoleView = [];
    setLoading(true)
    try {
      // <Get configured user access
      
      const response = await OOT.getUserAccessControlInfo(loginUserId);
      if (response.status && response.data.length > 0) {
        dispatch(setUserPersona(response.data[0]['role']['persona'][0]))
        if (response.data[0]['role']['persona'].includes("merchant")) {
         
          dispatch(setSupplierAcccountId((response.data[0]['role']['supplier_account_id'][0])));
        }
        // else {
        // Handle user role view
        userRoleView = [...response.data[0]["metadata"]["applicationList"][0]['applications']];
        if (userRoleView.includes("marketplace")) {
          getCountriesList();
          let cartInfo =
            response.data[0]["cartInfo"].length > 0
              ? response.data[0]["cartInfo"][0]["cart"]
              : [];
          dispatch(
            setProp({
              slice: "marketplace",
              key: "savedCartInfo",
              value: cartInfo,
            })
          );
          // dispatch(setProp({slice:'marketplace', key: 'cart', value: cartInfo.cart_items}))
        }
        if (response.data[0]["metadata"]) {
          dispatch(setUserMetaData(response.data[0]["metadata"]));
        }

      }
      //}
    } catch (error) {
      console.error(
        "Error has occured on get user access control info :",
        error
      );
    } finally {
      setLoading(false)
      // Handle data format
      dispatch(setUserAccessInfo(userRoleView));
    }
  };

  useEffect(() => {
    if (loginUserId.length > 0) {
      getUserInfo(loginUserId);
      console.log(authSelector.login.mail);
      findUserType(authSelector.login.mail);
    }
  }, [loginUserId]);

  const findUserType = (mail) => {
    // Extract the domain from the email address
    const domain = mail.split("@")[1];
    // Check if the domain is 'nokia.com'
    if (
      domain === "nokia.com" ||
      domain === "nokia-bell-labs.com" ||
      domain === "nokia-sbell.com"
    ) {
      dispatch(
        setProp({ slice: "marketplace", key: "isNokiaUser", value: true })
      );
    } else {
      dispatch(
        setProp({ slice: "marketplace", key: "isNokiaUser", value: false })
      );
    }
  };

  const getCountriesList = async () => {
    dispatch(
      setLoader({
        key: "isCountryLoading",
        value: true,
      })
    );
    try {
      let filterCondition = "is_allowed_for_marketplace = 1";
      const response = await OOT.getAllCountries(filterCondition);
      console.log(response.data.length, "res");
      if (response.status) {
        const updatedData = response.data.map((item) => {
          return {
            id: item.iso_2,
            value: item.name,
            is_merchant_products_allowed: item.is_merchant_products_allowed,
            short_region_code: item.short_region_code,
          };
        });
        dispatch(setCountryList(updatedData));
        dispatch(
          setLoader({
            key: "isCountryLoading",
            value: false,
          })
        );
      } else {
        dispatch(
          setLoader({
            key: "isCountryLoading",
            value: false,
          })
        );
      }
    } catch (error) {
      dispatch(
        setLoader({
          key: "isCountryLoading",
          value: false,
        })
      );
      console.error(error);
    }
  };

  

  if((loading)){
        return (
      <div style={{ position: "absolute", inset: "50% 0" }}>
        <Loader />
      </div>
    );
  }

  if (isAuthenticated && marketplaceSelector.userAccessInfo.length === 0 && !loading ){
      return <Unauthorized />;
  }




  return (
    <>
      
      <Routes>
        {isAuthenticated && marketplaceSelector.userAccessInfo.length > 0 && (
          <>
            <Route
              path="/"
              element={
                <Navigate
                  to={`/${marketplaceSelector.userAccessInfo[0]}`}
                  replace={true}
                />
              }
            />
            {/* <Route
              index
              path="/subscription"
              element={
                !isAuthorized ? (
                  <Unauthorized />
                ) : (
                  <Subscription />
                )
              }
            /> */}
             <Route element={<ProtectedRoute requiredAccess="subscription" />}>
              <Route path="/subscription" element={<Subscription />} />
            </Route>

            <Route element={<ProtectedRoute requiredAccess="marketplace" />}>
              <Route path="/marketplace" element={<Marketplace />}>
                <Route index path="" element={<MarketplaceBody />} />

                <Route path="cart" element={<CartBody />} />
                <Route path="product/:id" element={<SelectedProductPage />} />
              </Route>
            </Route>
            <Route element={<ProtectedRoute requiredAccess="dashboard" />}>
              <Route path="/dashboard" element={<Dashboard />}>
                <Route index path="" element={<MerchantBody />} />
              </Route>
            </Route>

            <Route element={<ProtectedRoute requiredAccess="products" />}>
              <Route path="/products" element={<MerchantProducts />} />
            </Route>

            <Route element={<ProtectedRoute requiredAccess="quotations" />}>
              <Route index path="/quotations" element={<Quotations />} />
            </Route>

            {/* <Route
              path="/account"
              element={(!isAuthorized) ? <Unauthorized /> : <Account />}
            /> */}

            <Route element={<ProtectedRoute requiredAccess="account" />}>
              <Route path="/account" element={<Account />} />
            </Route>

            <Route element={<ProtectedRoute requiredAccess="users" />}>
              <Route path="/users" element={<Admin />} />
              <Route path="/roles" element={<Roles />} />
            </Route>
            <Route element={<ProtectedRoute requiredAccess="radio_network_ciq" />}>
              <Route path="/radio_network_ciq" element={<RadioNetworkCIQ />} />
            </Route>
   
              <Route element={<ProtectedRoute requiredAccess="orders" />}>
                <Route path="/orders" element={<Orders />} />
              </Route>
            

            <Route path="/unauthorized" element={<Unauthorized />} />
            {/* Fallback for unconfigured routes */}
            <Route path="*" element={<FallbackPage/>} />
          </>
        )}

        <Route
          path="/login"
          element={
            isAuthenticated ? (
              <Navigate
                to={`/${marketplaceSelector.userAccessInfo[0]}`}
                replace={true}
              />
            ) : (
              <Login />
            )
          }
        />
      </Routes>
      
    </>
  );
};
