import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import Pagination from "../marketplace/common/Pagination";
import { RootState } from "Store/mainStore";
import OOT from "Constants/services/oot";
import { setLoader, setOrders, setDashboard } from "Store/contentState/merchantSlice";
import { useDispatch, useSelector } from 'react-redux';
import classes from '../merchant/Style/Merchant.module.css';
import Card from '@nokia-csf-uxr/ccfk/Card';
import MapLegend from '../../Assets/Icons/MapLegend.png';
import MapContent from "./Graph/MapContent";
import BarCharts from './Graph/BarCharts';
import { Chip, DataGrid, Skeleton } from '@nokia-csf-uxr/ccfk';
import userStyles from './Style/Merchant.module.css';
import { formatNumberWithCommas } from 'Pages/marketplace/Utils/commons';
import OrderList from './OrderList';
import { ChipLabel } from '@nokia-csf-uxr/ccfk/Chip';
import _ from 'lodash';

const SKELETON_TEXT_WIDTH = {
    text: 300,
    subtext: 150
};

const OfferQuotationDetails = (prop) => {
    const merchantSelector = useSelector((state: RootState) => state.merchant);
    const paginationSelector = useSelector((state: RootState) => state.pagination);
    const { currentPage, limitPerpage } = paginationSelector.paginationProps;
    const gridApi = useRef(null);
    const orderItemData = prop.orderItemData
    const productData = prop.productData
    const barChart = prop.barChart
    const mapCountryCode = prop.mapCountryCode
    const tabName = prop.type
    const [loading, setLoading] = useState(true);
    const pageSize = limitPerpage;
    const currentPageData = currentPage - 1

   

    const productList = Object.entries(orderItemData).map((item) => item[1])

    const paginatedItemsList = _.chunk(productList, pageSize);
    const paginatedItems = paginatedItemsList[currentPageData]
    

    const getTotalNetValue = () => {
        let totalsum = 0
        productList.forEach((item) => {
            totalsum += item['totalNetValue']
        });
        return totalsum
    }

    const calculateTotalCategory = (total, amount) => {
        let percentage = (amount / total) * 100
        return Math.round(percentage); // Returns percentage as a string with 2 decimal places
    };

    const getSalesCategoryList = () => {
        let totalcountset = 0
   
        Object.entries(productData).map(([key, value]) => totalcountset += value['salesAmount'])
        let dataset = Object.entries(productData).map(([key, value]) => {
            return {
                item: key,
                orderCount: value['orderCount'],
                salesAmount: value['salesAmount'],
                percentage: calculateTotalCategory(totalcountset, value['salesAmount']),
                color: getColorBase(calculateTotalCategory(totalcountset, value['salesAmount']))
            };
        });
        return dataset
    }

    const getTotalSalesCount = () => {
        let totalCount = 0
        const numbers: unknown[] = Object.entries(mapCountryCode).map((key, value) => key[1]['count'])
        numbers.forEach((value) => {
            totalCount += value as number;
        });
        return totalCount

    }

    const getColorBase = (percentage) => {
        let defaultColor = "";
        if (percentage >= 80 && percentage <= 100) {
            defaultColor = "#005aff"; // Example color for 100-80 range
        } else if (percentage >= 50 && percentage < 80) {
            defaultColor = "#5a0dd5"; // Example color for 80-50 range
        } else if (percentage >= 20 && percentage < 50) {
            defaultColor = "#ff8c00"; // Example color for 40-20 range
        } else if (percentage >= 10 && percentage < 20) {
            defaultColor = "#02a6d2"; // Example color for 20-10 range
        } else {
            defaultColor = "#808080"; // Default or fallback color (e.g., for <10 or invalid inputs)
        }
        return defaultColor;
    };



    const columnDefsList = [
        { headerName: 'Products', field: 'product_id', minWidth: 220, felx: 1 },
        {
            headerName: `${prop.type === "Orders" ? 'Order' : 'Quotation'} count`, field: 'salesOrders', minWidth: 120, felx: 1,
            cellRenderer: (params) => {
                return (
                    <div>
                        {params?.data.salesOrders?.length}
                    </div>
                );
            }
        },
        { headerName: 'Quantity', field: 'totalInvoicedQuantity', minWidth: 120, felx: 1 },
        {
            headerName: `${prop.type === "Orders" ? 'Order' : 'Quotation'} value(EUR)`, field: 'totalNetValue', minWidth: 250, felx: 1,
            cellRenderer: (params) => {
                return (
                    <div>
                        {formatNumberWithCommas(params?.data.totalNetValue)}
                    </div>
                )
            }
        }
    ]
    return (
        <><div className={classes.gridMerchantOderOfferContainer}>
            <Card style={{ borderRadius: '10px', display: 'flex', padding: '20px', height: "600px", overflowY: 'scroll'}}>
                {merchantSelector.isProductsLoading ? (<div style={{ padding: '20px' }}><Skeleton height={20} width={300} /></div>) : (<div style={{ paddingTop: '15px' }}>
                    <div>
                        {tabName} value by category
                    </div>
                    {getSalesCategoryList().length === 0 && <div>
                        No data are available
                    </div>}
                    {getSalesCategoryList().map((item) => {
                        return (<><div style={{ marginTop: '18px' }}><div className={classes.catgeoryList}>
                            <div>
                                {item.item}
                            </div>
                            <div>
                                {formatNumberWithCommas(item.salesAmount)} EUR
                            </div>
                        </div><div className={classes.progressbar}>
                                <div style={{ width: `${item.percentage}%`, backgroundColor: item.color }}></div>
                            </div>
                        </div></>
                        )
                    })}
                </div>)}
            </Card>
            <Card style={{ borderRadius: '10px', display: 'flex', padding: '20px', height: "600px" }}>
                {merchantSelector.isProductsLoading ? (<div style={{ padding: '20px' }}><Skeleton height={20} width={500} /></div>) : (<><div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>
                        {tabName} value by products
                    </div>
                    <div>
                        <Chip size="small" style={{ backgroundColor: '#E4F0FF', border: '1px solid #8EC2FF', marginRight: '.5rem', borderRadius: '15px' }}>
                            <ChipLabel label={`Value (EUR) ${formatNumberWithCommas(getTotalNetValue())}`} />
                        </Chip>

                    </div>
                </div><div className={userStyles.borderBottomLine}></div>
                    {paginatedItems && <div className={userStyles.productListDataGrod}>
                        <DataGrid
                            // variant={"compact"}
                            suppressMovableColumns={false}
                            suppressColumnMoveAnimation={false}
                            suppressRowClickSelection={false}
                            isRowDrag={false}
                            wrapperProps={{
                                style: {
                                    width: "100%",
                                    height: "800px"
                                }
                            }}
                            sideBar
                            disableAutoloadStyles
                            suppressContextMenu
                            onGridReady={() => {
                                gridApi.current = gridApi;
                            }}
                            rowData={paginatedItems}
                            columnDefs={columnDefsList}
                            key={tabName}
                            defaultColDef={{
                                editable: false,
                                sortable: true,
                                flex: 0,
                                minWidth: 30,
                                filter: false,
                                resizable: false,
                            }}
                            overlayNoRowsTemplate='No data are available'
                            isInlineRowActions={false}
                            domLayout='autoHeight'
                            onFirstDataRendered={(params) => {
                                params.columnApi.autoSizeAllColumns();
                                // onFirstDataRendered(params);
                            }} /></div>}

                </>
                )}

                {!merchantSelector.isProductsLoading && pageSize < productList.length && <div>
                    <Pagination
                        disableProp={paginatedItemsList}
                        totalItems={productList.length}
                        type="product"
                    />
                </div>}

            </Card>
            <div>
                <Card style={{ borderRadius: '10px', display: 'flex', padding: '20px', height: "600px", marginRight: "45px", overflow: 'hidden' }}>
                    {merchantSelector.isProductsLoading ? (<div style={{ padding: '20px' }}><Skeleton height={20} width={500} /></div>) : (
                        barChart.length != 0 && <div>
                            <div className={classes.mapheader}>
                                <div className="card-details-rightsect">
                                    <div className={classes.mapcardcount}>{tabName} value by market</div>

                                </div>

                            </div>
                            <BarCharts data={barChart} />
                        </div>)}

                </Card>
            </div>
        </div>
            <Card style={{ borderRadius: '10px', display: 'flex', padding: '20px', height: "auto", marginTop: '20px', width: '99%' }}>
                {merchantSelector.isProductsLoading ? (<div style={{ padding: '20px' }}><Skeleton height={20} width={1000} /></div>) : (<><div>
                    <div className={classes.mapheader}>
                        <div className="card-details-rightsect">
                            <div className={classes.mapcardcount}>{tabName} across the world</div>
                            <div className={classes.cardlabel}>{getTotalSalesCount()} {prop.type}</div>
                        </div>
                        <div>
                            <div className={classes.buttongroup}>
                                <div className={classes.buttonMap + " " + classes.low}>
                                    <span className={classes.circle}></span>
                                    Low
                                </div>
                                <div className={classes.buttonMap + " " + classes.medium}>
                                    <span className={classes.circle}></span>
                                    Medium
                                </div>
                                <div className={classes.buttonMap + " " + classes.high}>
                                    <span className={classes.circle}></span>
                                    High
                                </div>
                            </div>
                            {/* <img src={MapLegend} /> */}
                        </div>
                    </div>
                    <div className={classes.mapContent}>
                        {/* <img src={mapimage} width={'100%'} /> */}
                        <MapContent mapCountryCode={mapCountryCode} tabType={prop.type} />

                    </div>
                </div>
                </>)}
            </Card>
            {prop.type === "Orders" && (<div>
                <Card style={{ borderRadius: '10px', display: 'flex', padding: '20px', height: "auto", marginTop: '20px', width: '99%' }}>
                    <OrderList />
                </Card>
            </div>)}
        </>

    )
}
export default OfferQuotationDetails;