
import React from "react";
import finerDetailsIcon from "../../../Assets/Icons/finer-details-avatar.svg";
import Tooltip from '@nokia-csf-uxr/ccfk/Tooltip';
import PropTypes from "prop-types";
import { Label } from "@nokia-csf-uxr/ccfk";


const FinerDetailsView = ({ finerDetails }) => {
    const disallowedColumns = finerDetails.disallow || [];
    const otherActions = Object.fromEntries(
        Object.entries(finerDetails).filter(([key]) => key !== 'disallow')
    );

    const finerDetailsTooltipContent = () => {
        return (
            <div style={{
                boxSizing: 'border-box', direction: 'ltr', wordBreak: 'break-word', whiteSpace: 'pre-line',
                width: '300px', padding: '1rem', borderRadius: '.5rem', backgroundColor: "#101620d9", color: '#ffffff'
            }} >
                <div style={{ display: 'flex', marginBottom: '1rem', gap: '1rem' }}>
                    {Object.entries(otherActions).map(([action, value]) => {
                        const displayAction = action === 'view' ? 'R' : action.charAt(0).toUpperCase();
                        return (
                            <div key={action}>
                                <div style={{ display: 'flex', gap: '1rem' }}>
                                    {`${displayAction} ${value}`}
                                </div>
                            </div>
                        );
                    })}
                </div>
             {(Object.keys(otherActions).length > 0 && disallowedColumns.length > 0) && <div style={{ borderBottom: '1px solid #ABAAAA' }}></div>}
              { disallowedColumns.length > 0 && <>
                <div style={{ display: 'flex' , marginTop:'1rem'}}>
                    <Label style={{  fontSize: '10px' }}>Disallowed columns: </Label>
                    <div>
                       {disallowedColumns.join(', ')}
                    </div>
                </div></>}  
            </div>
        );
    };

    return (
        <div style={{ height: '2rem' }}>
            <Tooltip
                closeOnReferenceHidden={false}
                placement="bottom"
                trigger="hover"
                tooltip={finerDetailsTooltipContent()}
            >
                <img src={finerDetailsIcon} alt="FD" />
            </Tooltip>
        </div>
    );
};

export default FinerDetailsView;

FinerDetailsView.propTypes = {
    finerDetails: PropTypes.object.isRequired,
};